import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import TextArea from "components/atoms/text-area/TextArea";
import ImageDropZone from "components/molecules/image-dropzone/ImageDropZone";
import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import { IconType } from "lib/helpers/constants/iconTypes";
import { usePartnerForm } from "lib/hooks/forms/usePartnerForm";
import { useCreatePartners } from "lib/hooks/mutations/Partners/useCreatePartners";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { IPartnerResponse } from "./CreatePartnerProcess";

export interface ICreateSourceProps {
  onConfirm: () => void;
  setCurrentStep: (value: number) => void;
  setResponse: Dispatch<SetStateAction<IPartnerResponse>>;
}

const CreatePartner: FC<ICreateSourceProps> = ({
  onConfirm,
  setCurrentStep,
  setResponse,
}) => {
  const [resetKey, setResetKey] = useState<number>(0);
  const { mutateAsync: createPartner, isLoading } = useCreatePartners();

  const onSubmit = (data: Record<string, any>) => {
    data.status = data.status ? 1 : 0;
    createPartner(data as Record<string, any>)
      .then((res: any) => {
        reset();
        setResponse(res);
        setCurrentStep(2);
        setResetKey((prevKey) => prevKey + 1);
      })
      .catch(() => {});
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = usePartnerForm({
    defaultValues: {
      name: "",
      description: "",
      phone: "",
      email: "",
      status: false,
      logo: null,
    },
  });

  const resetForm = () => {
    reset();
    setResetKey((prevKey) => prevKey + 1);
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col gap-3"
    >
      <ImageDropZone
        label="Logo"
        {...register("logo")}
        control={control}
        classes={{
          container: "max-w-[150px] !mr-auto",
          subContainer: "ml-0",
          errorClassName: "!left-[3px]",
          wrapperLabelClassName: "!w-[100px] !h-[100px]",
        }}
        acceptParameters="image/png, image/jpg, image/jpeg"
        key={resetKey}
      />
      <Input
        label="Name *"
        placeholder="Enter partner name"
        {...register("name")}
        error={errors?.name}
      />
      <Input
        label="Email"
        placeholder="Enter email"
        {...register("email")}
        error={errors?.email}
      />
      <Input
        label="Phone"
        placeholder="Enter phone"
        {...register("phone")}
        error={errors?.phone}
      />
      <TextArea
        placeholder="Write description .."
        label="Description"
        {...register("description")}
        error={errors?.description}
      />
      <ToggleSwitch
        label="Actived*"
        classes={{ label: "!flex flex-row-reverse gap-[15px]" }}
        {...register("status")}
        error={errors?.status}
      />
      <div className="flex gap-5 justify-end">
        <IconButton
          secondary={true}
          className={`min-w-[120px]`}
          type="button"
          onClick={resetForm}
        >
          Reset
        </IconButton>
        <IconButton
          disabled={isLoading ? true : false}
          className={`min-w-[120px]`}
          typeof="button"
          icon={<Icon iconType={IconType.PLUS_ICON} />}
        >
          {isLoading ? "Creating..." : "Create"}
        </IconButton>
      </div>
    </Form>
  );
};

export default CreatePartner;
