import { FC, useEffect, useState, useMemo } from "react";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Check3Icon from "components/atoms/icon/icon-type/Check3Icon";
import CheckIcon from "components/atoms/icon/icon-type/CheckIcon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Table from "components/molecules/table/Table";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useUploadAppointmentStoreFile } from "lib/hooks/mutations/Appointments/useUploadAppointmentStoreFile";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Column } from "react-table";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { Fragment } from "react/jsx-runtime";

export interface IProps {
  setStep: (value: number) => void;
  dataCols: any;
  onUpdateData: (updatedData: any[]) => void;
  themeColors: {
    primary: string;
  };
}

const StoreFields: FC<IProps> = ({
  setStep,
  dataCols,
  onUpdateData,
  themeColors,
}) => {
  const { handleSubmit } = useForm();
  const { formData } = useMultiStepFormContext<any>();
  const location = useLocation();
  const [_, setStoredAppointment] = useState<string[]>();

  const { mutateAsync: storeUploadedAppointment, isLoading } =
    useUploadAppointmentStoreFile();

  const [tableData, setTableData] = useState(formData.data || []);

  useEffect(() => {
    setTableData(formData.data);
  }, []);

  const handleRemove = (index: number) => {
    const newData = tableData.filter((_: any, i: number) => i !== index);
    setTableData(newData);
    setStoredAppointment(newData);
    onUpdateData(newData);
  };

  const actionsColumn = {
    Header: "Delete",
    accessor: "delete",
    Cell: ({ row }: any) => (
      <button
        type="button"
        onClick={() => handleRemove(row.index)}
        className="text-red-600 hover:text-red-800"
      >
        <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
      </button>
    ),
  };

  const columns = useMemo<Column<any>[]>(
    () => [actionsColumn, ...dataCols.filter((col: any) => col.isChecked)],
    [dataCols, tableData]
  ) as Column<object>[];

  const hasAdminPermission = usePermissions([P["Appointment Admin"]]);
  const hasManagerPermission = usePermissions([P["Appointment Menager"]]);
  const hasSalesmanPermission = usePermissions([P["Appointment Salesman"]]);

  const onSubmit = () => {
    let updatedData = null;
    if (
      (hasAdminPermission || hasManagerPermission) &&
      location.pathname === "/own-appointments"
    ) {
      updatedData = {
        own_appointments: 1,
        appointments: tableData,
        file: formData?.file,
      };
    } else if (
      hasSalesmanPermission &&
      !hasAdminPermission &&
      !hasManagerPermission &&
      location.pathname === "/appointments"
    ) {
      updatedData = {
        own_appointments: 1,
        appointments: tableData,
        file: formData?.file,
      };
    } else {
      updatedData = { appointments: tableData, file: formData?.file };
    }
    storeUploadedAppointment(updatedData).then(() => {
      setStep(4);
      setStoredAppointment([]);
    });
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col pl-4 pb-2"
    >
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5">
          Upload CSV
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999">
          <CheckIcon color={themeColors.primary} />
        </div>
      </div>
      <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-9 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
          Only adjust the fields you want to import
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999 ">
          <CheckIcon color={themeColors.primary} />
        </div>
      </div>
      <div className="pl-7 relative bg-transparent">
        <div className="pl-2 text-[14px] font-inter-medium pb-5 ">
          Remove the appointments here that you don't want to import
        </div>
        <div
          className="rounded-[20px] p-4 w-full"
          style={{
            backgroundColor: `${themeColors.primary}0D`,
          }}
        >
          <div className="overflow-auto horizontalOverflowScrollStyle w-full h-full px-5 ">
            <Fragment>
              <Table
                style={{
                  backgroundColor: `${themeColors.primary}0D`,
                }}
                classes={{ subContainer: "!p-0 !rounded-none" }}
                columns={columns}
                data={tableData || []}
                search={""}
                isColumnSorted={true}
              />
            </Fragment>
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <Icon iconType={IconType.ALERT_ICON} color={themeColors.primary} />
          <span className="font-inter-medium text-[13px] text-primaryTextColor pt-1">
            These appointments will not be assigned to any account.
          </span>
        </div>
        <div className="absolute top-[0] left-[-12px] z-9999 ">
          <Check3Icon color={themeColors.primary} />
        </div>
      </div>
      <div className="flex gap-5 justify-end pt-5">
        <IconButton
          icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
          className="w-full max-w-[150px]"
          disabled={isLoading}
        >
          {isLoading ? "Creating..." : "Continue"}
        </IconButton>
      </div>
      {isLoading && <LoadingScreen />}
    </Form>
  );
};

export default StoreFields;
