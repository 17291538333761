import Button from "components/atoms/button/Button";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import Icon from "components/atoms/icon/Icon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import Table from "components/molecules/table/Table";
import UpdatePendingsProcess from "components/organisms/pendings/update-pendings-process/UpdatePendingsProcess";
import { IconType } from "lib/helpers/constants/iconTypes";
import { usePending } from "lib/hooks/queries/Pendings/usePending";
import { FC, useState } from "react";
import { toast } from "react-toastify";

export interface IPendingsListCardProps {
  label: string;
  icon: IconType;
  color: string;
  setIsShowedPendingList: (value: boolean) => void;
  data: any;
  type?: string;
  isLoadingData: boolean;
}

const PendingsListCard: FC<IPendingsListCardProps> = ({
  color,
  icon,
  label,
  setIsShowedPendingList,
  data,
  isLoadingData,
  type
}) => {
  const [id, setId] = useState<number>();
  const {
    data: userData,
    isError,
    isLoading: isLoadingFetchingUser
  } = usePending(id);
  const [isUpdatePendingModalOpen, setIsUpdatePendingModalOpen] =
    useState<boolean>(false);
  const [currentUpdatePending, setCurrentUpdatePending] = useState<number>(1);

  const handleUpdatePending = (id: number) => {
    if (!isError) {
      setId(id);
      setIsUpdatePendingModalOpen(true);
    } else {
      toast.error(`Something went wrong`);
    }
  };

  const columns = [
    {
      Header: "Deadline",
      accessor: "deadline",
      Cell: ({ row: { original } }: any) => {
        return (
          <p
            className="text-[14px] font-inter-medium text-[#282D46] cursor-pointer"
            onClick={() => {
              handleUpdatePending(original?.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            {original?.deadline ?? "No Available"}
          </p>
        );
      }
    },
    {
      Header: "Pending Title",
      accessor: "title",
      Cell: ({ row: { original } }: any) => {
        return (
          <div
            className="flex gap-1 items-center cursor-pointer"
            onClick={() => {
              handleUpdatePending(original.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            <h1 className="text-[14px] font-inter-medium text-[#282D46]">
              {original?.costumer?.first_name} {original?.costumer?.last_name}
            </h1>
            <p className="text-[14px] font-inter-medium text-[#282D46]">
              {original?.title}
            </p>
          </div>
        );
      }
    },
    {
      Header: "Consultant",
      accessor: "from_user",
      Cell: ({ row: { original } }: any) => {
        return (
          <div
            className="flex gap-1 items-center cursor-pointer"
            onClick={() => {
              handleUpdatePending(original.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            <h1 className="text-[14px] font-inter-medium text-[#282D46]">
              {original?.costumer?.from_user}
            </h1>
          </div>
        );
      }
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row: { original } }: any) => {
        const status = original?.status ?? "Open";
        return (
          <p
            onClick={() => {
              handleUpdatePending(original.id);
              setIsUpdatePendingModalOpen(true);
            }}
            className={`min-w-[50px] cursor-pointer w-[max-content] h-[24px] p-3 text-[12px] font-inter-medium flex items-center justify-center rounded-[4px] capitalize ${
              status === "Done"
                ? "bg-[#2DE28B] bg-opacity-[10%] text-[#2DE28B]"
                : status === "Processing"
                ? "bg-[#50B8E7] bg-opacity-[10%] text-[#50B8E7]"
                : status === "Open"
                ? "bg-[#EFBE12] bg-opacity-[10%] text-[#EFBE12]"
                : status === "Pending"
                ? "bg-[#F88532] bg-opacity-[10%] text-[#F88532]"
                : ""
            }`}
          >
            {status}
          </p>
        );
      }
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }: any) => {
        return (
          <div
            className="flex items-center gap-[3px] ml-auto justify-end cursor-pointer"
            onClick={() => {
              handleUpdatePending(original.id);
              setIsUpdatePendingModalOpen(true);
            }}
          >
            <Button
              type="button"
              onClick={() => {
                handleUpdatePending(original.id);
              }}
            >
              <Icon iconType={IconType.SHOW_PASSWORD_ICON} />
            </Button>
            <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
          </div>
        );
      }
    }
  ];


  return (
    <div className="min-h-[375px] max-h-[375px] bg-white border-[#F2F4F7] border-[1px] rounded-[20px]">
      <>
        <div
          className="rounded-t-[20px]"
          style={{ backgroundColor: `${color}1d` }}
        >
          <div className="p-[25px] flex items-center justify-between">
            <div className="flex items-center gap-[10px]">
              <Icon iconType={icon} color={color} />
              <p className="font-inter-medium text-[#282D46] text-[14px]">
                {label}
              </p>
            </div>
            <div className="flex items-center ml-auto gap-[12px] justify-end">
              <p
                className="underline font-inter-regular text-[#6C737F] text-[14px] cursor-pointer"
                onClick={() => {
                  setIsShowedPendingList(true);
                }}
              >
                View all
              </p>
              <Icon iconType={IconType.OPTIONS_ICON} />
            </div>
          </div>
        </div>
        <div className="w-full p-[25px] relative">
          <NoDataBoundary
            condition={!!data && data.length > 0}
            fallback={
              <p className="text-[#7D8592] font-inter-regular mx-auto my-20 flex justify-center">
                There is no {label} in this section.
              </p>
            }
          >
            {isLoadingData && <LoadingScreen className="top-[40px]" />}
            <Table
              columns={columns}
              data={data ?? []}
              isColumnSorted={true}
              search={""}
              classes={{ container: "overflow-y-auto !max-h-[270px]" }}
            />
          </NoDataBoundary>
        </div>
      </>
      <ConditionalRender condition={isUpdatePendingModalOpen}>
        <UpdatePendingsProcess
          userData={userData}
          setCurrentUpdatePending={setCurrentUpdatePending}
          updateCurrentPending={currentUpdatePending}
          isLoadingFetchingUser={isLoadingFetchingUser}
          showToCommentsBackButton={true}
          showToCommentsConfirm={true}
          hocClasses={{
            modalClassName:
              currentUpdatePending === 1
                ? "sm:w-[600px] md:max-w-[600px] sm-md:max-w-[600px] lg:min-w-[973px] 2xl:w-[948px]"
                : currentUpdatePending === 2
                ? "sm:max-w-[467px]   md:min-w-[467px] lg:w-[467px]"
                : currentUpdatePending === 3
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px]   lg:min-w-[894px]"
                : currentUpdatePending === 4
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px] lg:min-w-[894px]"
                : currentUpdatePending === 5
                ? "sm:w-[600px] md:max-w-[600px]  sm-md:max-w-[600px] 2xl:min-w-[930px]"
                : ""
          }}
          hocCurrentStep={currentUpdatePending}
          hocToggleModal={() => setIsUpdatePendingModalOpen(false)}
          hocisOpen={isUpdatePendingModalOpen}
          setIsUpdatePendingModalOpen={setIsUpdatePendingModalOpen}
          setHocCurrentStep={setCurrentUpdatePending}
          clearData={true}
          hocTitle={
            currentUpdatePending === 1
              ? ""
              : currentUpdatePending === 2
              ? "Upload File"
              : ""
          }
        />
      </ConditionalRender>
    </div>
  );
};

export default PendingsListCard;
