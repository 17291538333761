import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./style/global.css";
import "./style/tailwind.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AppContextProviders from "./lib/context/AppContextProvider";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./lib/helpers/configs/queryClient";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { toasterConfig } from "lib/helpers/configs/toastifyConfigs";
import { ReactQueryDevtools } from 'react-query/devtools'
import '@react-pdf-viewer/core/lib/styles/index.css';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
      // <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppContextProviders>
          <ToastContainer {...toasterConfig} position="top-right" />
          <App />
        </AppContextProviders>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  // </StrictMode>
);
