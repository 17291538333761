export interface ILanguageData {
  value: string;
  label: string;
}

export const languageData: Array<ILanguageData> = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "fr",
    label: "French",
  },
  {
    value: "es",
    label: "Spanish",
  },
  {
    value: "it",
    label: "Italian",
  },
  {
    value: "pt",
    label: "Portuguese",
  },
  {
    value: "ta",
    label: "Tamil",
  },
  {
    value: "sq",
    label: "Albanian",
  },
  {
    value: "tr",
    label: "Turkish",
  },
  {
    value: "de",
    label: "German",
  },
  {
    value: "zh",
    label: "Chinese",
  },
  {
    value: "ja",
    label: "Japanese",
  },
  {
    value: "ko",
    label: "Korean",
  },
  {
    value: "ru",
    label: "Russian",
  },
  {
    value: "ar",
    label: "Arabic",
  },
  {
    value: "hi",
    label: "Hindi",
  },
  {
    value: "bn",
    label: "Bengali",
  },
  {
    value: "pa",
    label: "Punjabi",
  },
  {
    value: "jv",
    label: "Javanese",
  },
  {
    value: "ms",
    label: "Malay",
  },
  {
    value: "ur",
    label: "Urdu",
  },
  {
    value: "vi",
    label: "Vietnamese",
  },
  {
    value: "fa",
    label: "Persian",
  },
  {
    value: "gu",
    label: "Gujarati",
  },
  {
    value: "mr",
    label: "Marathi",
  },
  {
    value: "te",
    label: "Telugu",
  },
  {
    value: "kn",
    label: "Kannada",
  },
  {
    value: "ml",
    label: "Malayalam",
  },
  {
    value: "th",
    label: "Thai",
  },
  {
    value: "pl",
    label: "Polish",
  },
  {
    value: "uk",
    label: "Ukrainian",
  },
  {
    value: "ro",
    label: "Romanian",
  },
  {
    value: "hu",
    label: "Hungarian",
  },
  {
    value: "el",
    label: "Greek",
  },
  {
    value: "cs",
    label: "Czech",
  },
  {
    value: "sv",
    label: "Swedish",
  },
  {
    value: "fi",
    label: "Finnish",
  },
  {
    value: "no",
    label: "Norwegian",
  },
  {
    value: "da",
    label: "Danish",
  },
  {
    value: "he",
    label: "Hebrew",
  },
  {
    value: "bg",
    label: "Bulgarian",
  },
  {
    value: "hr",
    label: "Croatian",
  },
  {
    value: "sr",
    label: "Serbian",
  },
  {
    value: "sk",
    label: "Slovak",
  },
  {
    value: "sl",
    label: "Slovenian",
  },
  {
    value: "et",
    label: "Estonian",
  },
  {
    value: "lv",
    label: "Latvian",
  },
  {
    value: "lt",
    label: "Lithuanian",
  },
  {
    value: "ga",
    label: "Irish",
  },
  {
    value: "is",
    label: "Icelandic",
  },
  {
    value: "mt",
    label: "Maltese",
  },
  {
    value: "cy",
    label: "Welsh",
  },
  {
    value: "af",
    label: "Afrikaans",
  },
  {
    value: "sw",
    label: "Swahili",
  },
  {
    value: "am",
    label: "Amharic",
  },
  {
    value: "yo",
    label: "Yoruba",
  },
  {
    value: "ig",
    label: "Igbo",
  },
  {
    value: "zu",
    label: "Zulu",
  },
  {
    value: "xh",
    label: "Xhosa",
  },
  {
    value: "st",
    label: "Sesotho",
  },
  {
    value: "ne",
    label: "Nepali",
  },
  {
    value: "my",
    label: "Burmese",
  },
  {
    value: "km",
    label: "Khmer",
  },
  {
    value: "si",
    label: "Sinhala",
  },
  {
    value: "mn",
    label: "Mongolian",
  },
  {
    value: "lo",
    label: "Lao",
  },
  {
    value: "ps",
    label: "Pashto",
  },
  {
    value: "kk",
    label: "Kazakh",
  },
  {
    value: "tg",
    label: "Tajik",
  },
  {
    value: "ky",
    label: "Kyrgyz",
  },
  {
    value: "uz",
    label: "Uzbek",
  },
  {
    value: "tk",
    label: "Turkmen",
  },
  {
    value: "az",
    label: "Azerbaijani",
  },
  {
    value: "hy",
    label: "Armenian",
  },
  {
    value: "ka",
    label: "Georgian",
  },
  {
    value: "bs",
    label: "Bosnian",
  },
  {
    value: "mk",
    label: "Macedonian",
  },
  {
    value: "sh",
    label: "Serbo-Croatian",
  },
  {
    value: "be",
    label: "Belarusian",
  },
  {
    value: "tt",
    label: "Tatar",
  },
  {
    value: "ba",
    label: "Bashkir",
  },
  {
    value: "cv",
    label: "Chuvash",
  },
  {
    value: "cu",
    label: "Church Slavic",
  },
  {
    value: "os",
    label: "Ossetian",
  },
  {
    value: "ky",
    label: "Kyrgyz",
  },
];


