import { storeUploadedAppointment } from "api/Appointment";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { usePermissions } from "lib/hooks/shared/usePermissions";

export const useUploadAppointmentStoreFile = () => {
  const queryClient = useQueryClient();
    return useMutation(storeUploadedAppointment, {
        onSuccess: () => {
            queryClient.refetchQueries(['appointments', 'notAssigned']);
            toast.success('Appointment stored successfully')
        },
        onError: (error) => {
            console.error(error);
        }
    });
};