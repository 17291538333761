import Table from "components/molecules/table/Table";
import { FC, useMemo } from "react";
import { Column } from "react-table";
import { Fragment } from "react/jsx-runtime";

export interface ISourceListProps<T> {
  data: T;
  dataCols: any;
}

const SourceList: FC<ISourceListProps<any>> = ({ data, dataCols }) => {


  const columns = useMemo<Column<any>[]>(
    () =>

      dataCols.filter((cols: any) => cols.isChecked)

    , [dataCols]

  ) as Column<object>[];

  return (
    <Table columns={columns} data={data} search={""} isColumnSorted={true} />
  );
};

export default SourceList;
