import { useChangeLeadStatus } from "lib/hooks/mutations/Leads/useChangeLeadStatus";
import React, { FC, HTMLAttributes, useEffect, useState } from "react";

export interface ILeadStatusSelectProps<T>
  extends HTMLAttributes<HTMLSelectElement> {
  status: string;
  lead_id: number;
  confirm?: any;
  data: T;
}

const LeadStatusSelect: FC<ILeadStatusSelectProps<any>> = ({
  status,
  lead_id,
  confirm,
  data,
}) => {
  const [Status, setStatus] = useState<string>(status);

  const [Style, setStyle] = useState({ color: "", backgroundColor: "" });

  const { mutateAsync: updateLeadStatus } = useChangeLeadStatus();

  useEffect(() => {
    setStatus(status);
  }, [status]);

  useEffect(() => {
    switch (Status) {
      case "Unprocessed":
        setStyle({ color: "#DE92EB", backgroundColor: "#DE92EB08" });
        break;
      case "Not Reached":
        setStyle({ color: "#CC7272", backgroundColor: "#CC727208" });
        break;
      case "In Contact":
        setStyle({ color: "#EFBE12", backgroundColor: "#EFBE1208" });
        break;
      case "Appointment":
        setStyle({ color: "#70DDE4", backgroundColor: "#70DDE408" });
        break;
      case "New Customer":
        setStyle({ color: "#2DE28B", backgroundColor: "#2DE28B08" });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Status]);

  const ChangeStatus = (e: any) => {
    const data = { feedback_status: e.target.value, lead_id: lead_id };
    updateLeadStatus(data)
      .then(() => {
        confirm();
      })
      .catch(() => {});

    switch (e.target.value) {
      case "Unprocessed":
        setStatus("Unprocessed");
        break;
      case "Not Reached":
        setStatus("Not Reached");
        break;
      case "In Contact":
        setStatus("In Contact");
        break;
      case "Appointment":
        setStatus("Appointment");
        break;
      case "New Customer":
        setStatus("New Customer");
        break;
    }
  };

  return (
    <>
      {data.assigned_to === null ? (
        ""
      ) : (
        <select
          onChange={ChangeStatus}
          className="w-auto rounded-[8px] font-inter-light px-2 py-1 outline-none text-[14px] md:text-[16px]"
          value={Status}
          style={{ color: Style.color, backgroundColor: Style.backgroundColor }}
        >
          <option value={"Unprocessed"}>Unprocessed</option>
          <option value={"Not Reached"}>Not reached</option>
          <option value={"In Contact"}>In contact</option>
          <option value={"Appointment"}>Appointment</option>
          <option value={"New Customer"}>New customer</option>
        </select>
      )}
    </>
  );
};

export default LeadStatusSelect;
