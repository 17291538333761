import { getRealtimeNotifications } from "api/Notifications";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

export const useHasRealTimeNotifications = () => {
  const [hasRealTimeNotifications, setHasRealTimeNotifications] =
    useState<any>();
  const { isAuthenticated } = useAuthContext();

  const queryInfo = useQuery<any>(
    ["realtimeNotifications"],
    () => getRealtimeNotifications(),
    {
      onSuccess: (data) => {
        setHasRealTimeNotifications(data[0]);
      },
      onError: (error: any) => {
        console.error(error);
      },
      enabled: false,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (isAuthenticated) {
      queryInfo.refetch();
    }
  }, []);

  return { queryInfo, hasRealTimeNotifications, setHasRealTimeNotifications };
};
