import { ISideNavLinks } from "lib/helpers/constants/sideNavLinks";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { FC } from "react";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";

interface NavItemProps {
  item: ISideNavLinks;
  isToggled: boolean;
  isActiveChildren: boolean;
  icon: IconType;
}

const NavItem: FC<NavItemProps> = ({ item, isToggled, isActiveChildren,icon }) => {
  const hasPermission = usePermissions(item.permissions || []);
  const { themeColors } = useThemeContext();

  if (!hasPermission) {
    return null;
  }

  return (
    <Link
      to={item.path}
      className={`font-inter-medium text-[14px] 3xl:text-[15px]
        `}
    >
      <li
        key={`children-key-${item.id}`}
        className={` ${isToggled ? "" : "list-disc"}  ${
          isActiveChildren ? "text-[--theme]" : "text-[#7D8592]"
        }`}
      > 
        {isToggled ? (
          <Icon
            iconType={icon}
            className="py-[2px]"
            color={isActiveChildren ? themeColors.primary : ""}
          />
        ) : (
          `${item.label}`
        )}
      </li>
    </Link>
  );
};

export default NavItem;
