import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import ImageDropZone from "components/molecules/image-dropzone/ImageDropZone";
import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import { IconType } from "lib/helpers/constants/iconTypes";
import { usePartnerForm } from "lib/hooks/forms/usePartnerForm";
import { useUpdatePartner } from "lib/hooks/mutations/Partners/useUpdatePartner";
import { FC, useState } from "react";

export interface IUpdateSourceProps {
  onConfirm: () => void;
  data?: any;
  themeColors: {
    primary: string;
  };
}

const UpdatePartner: FC<IUpdateSourceProps> = ({
  onConfirm,
  data,
  themeColors,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
    setValue,
  } = usePartnerForm({
    defaultValues: {
      name: data?.name || "",
      description: data?.description || "",
      phone: data?.phone || "",
      email: data?.email || "",
      status: data?.status || "",
      logo: data?.logo || null,
    },
  });

  const { mutateAsync: updatePartner, isLoading } = useUpdatePartner(
    data?.id ?? ""
  );

  const onSubmit = (formData: any) => {
    const updatedData = {
      ...formData,
      id: data?.id,
    };

    updatePartner(updatedData)
      .then(() => {
        onConfirm();
      })
      .catch(() => {});
  };

  const [editLogo, setEditLogo] = useState(false);

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="mb-0 flex flex-col gap-6"
    >
      {editLogo ? (
        <div className="relative" style={{ width: "fit-content" }}>
          <ImageDropZone
            label="Logo*"
            {...register("logo")}
            name="logo"
            control={control}
            classes={{
              container: "max-w-[150px]",
              subContainer: "ml-0",
              wrapperLabelClassName: "!w-[100px] !h-[100px]",
            }}
            acceptParameters="image/png, image/jpg, image/jpeg"
          />
          <div
            className="absolute top-4 right-[3px] cursor-pointer"
            onClick={() => {
              setEditLogo(false);
              setValue("logo", null, { shouldValidate: true });
            }}
          >
            <Icon iconType={IconType.CLOSE_X_SM_ICON} />
          </div>
        </div>
      ) : (
        <div className="relative" style={{ width: "fit-content" }}>
          <Label>Partner logo*</Label>
          <img
            src={
              data?.logo?.length > 0
                ? `https://mainhub.insusales.com/api/partners/logo/${data.logo}`
                : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
            }
            className="object-cover rounded-[10px] w-[100px] h-[100px]"
            alt="img"
            loading="lazy"
          />
          <div
            className="absolute top-1 right-[-10px] cursor-pointer"
            onClick={() => {
              setEditLogo(true);
              setValue("logo", null, { shouldValidate: true });
            }}
          >
            <Icon iconType={IconType.EDIT_SOURCE_ICON} />
          </div>
        </div>
      )}

      <Input
        label="Name *"
        placeholder="Enter partner name"
        {...register("name")}
        error={errors?.name}
      />
      <Input
        label="Email"
        placeholder="Enter email"
        {...register("email")}
        error={errors?.email}
      />
      <Input
        label="Phone"
        placeholder="Enter phone"
        {...register("phone")}
        error={errors?.phone}
      />
      <TextArea
        placeholder="Write description .."
        label="Description"
        {...register("description")}
        error={errors?.description}
      />
      <ToggleSwitch
        label="Actived*"
        classes={{ label: "!flex flex-row-reverse gap-[15px]" }}
        {...register("status")}
        error={errors?.status}
      />
      <div className="flex gap-5 justify-end">
        <IconButton
          secondary={true}
          className={`min-w-[120px] !text-[${themeColors.primary}]`}
          type="button"
          onClick={() => {
            reset();
          }}
        >
          Reset
        </IconButton>
        <IconButton
          className={`min-w-[120px] bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          disabled={isLoading}
        >
          Update
        </IconButton>
      </div>
    </Form>
  );
};

export default UpdatePartner;
