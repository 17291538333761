import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { customStyles } from "lib/helpers/configs/customStyles";
import { IconType } from "lib/helpers/constants/iconTypes";
import withModal from "lib/helpers/hoc/withModal";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";

export interface ICreatePendingProps {
  currentPending: number;
}

const CreatePending: FC<ICreatePendingProps> = () => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  return (
    <Form className="flex flex-col gap-[15px]">
      <Input label="Title*" placeholder="Enter pending title" />
      <Controller
        control={control}
        name="new_date"
        rules={{ required: "Reminder of appointment is required" }}
        render={({
          field: { onChange, value }
        }: {
          field: { onChange: (date: string) => void; value: string };
        }) => (
          <PrimaryDatePicker
            label="Deadline *"
            startDate={value}
            wrapperClassName="!left-[2px]"
            setStartDate={(date: string) => onChange(date)}
          />
        )}
      />
      <div className="grid grid-cols-2 gap-[30px]">
        <div className="relative">
          <Label text="Pending category *" />
          <Controller
            name="insurance_type"
            control={control}
            render={({ field }) => (
              <Creatable<any, false>
                {...field}
                placeholder="Choose cateory"
                options={[
                  {
                    value: "Health Insurance",
                    label: "Health Insurance"
                  },
                  {
                    value: "Physical",
                    label: "Physical"
                  }
                ]}
                value={
                  field.value
                    ? { value: field.value, label: field.value }
                    : null
                }
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                isValidNewOption={() => false}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            )}
          />
        </div>
        <div className="relative">
          <Label text="Pending status *" />
          <Controller
            name="insurance_type"
            control={control}
            render={({ field }) => (
              <Creatable<any, false>
                {...field}
                placeholder="Choose status"
                options={[
                  {
                    value: "Health Insurance",
                    label: "Health Insurance"
                  },
                  {
                    value: "Physical",
                    label: "Physical"
                  }
                ]}
                value={
                  field.value
                    ? { value: field.value, label: field.value }
                    : null
                }
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                isValidNewOption={() => false}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            )}
          />
        </div>
      </div>
      <div className="relative">
        <Label text="Assign worker *" />
        <Controller
          name="insurance_type"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder="Choose worker"
              options={[
                {
                  value: "Health Insurance",
                  label: "Health Insurance"
                },
                {
                  value: "Physical",
                  label: "Physical"
                }
              ]}
              value={
                field.value ? { value: field.value, label: field.value } : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(newValue: any, actionMeta: any) => {
                if (actionMeta.action === "select-option" && newValue?.value) {
                  field.onChange(newValue.value);
                }
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>
      <TextArea label="Notes" placeholder="Write your note .." />
      <div className="flex justify-end gap-4 mt-[40px]">
        <IconButton
          type="button"
          secondary={true}
          className="w-full max-w-[150px] !bg-transparent"
        >
          Reset
        </IconButton>
        <IconButton
          type="submit"
          icon={<Icon iconType={IconType.INCRASE_ICON} color="white" />}
          className={`w-full max-w-[150px]  text-white !border-[--theme]`}
        >
          Create
        </IconButton>
      </div>
    </Form>
  );
};

export default withModal(CreatePending);
