import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";
import PortraitImage from 'assets/images/portrait.png'

export interface IAppointmentPDFCardViewerProps {
  img: string;
  fullname: string;
  host: string;
  location: string;
  date: string;
  time: string;
}

const AppointmentPDFCardViewer: FC<IAppointmentPDFCardViewerProps> = ({
  date,
  fullname,
  host,
  img,
  location,
  time
}) => {
  return (
    <div className="border-[1px] border-inputborder rounded-[8px] p-3">
      <div className="flex items-center justify-between">
        <div className="flex items-start  gap-[8px]">
        <img src={PortraitImage} alt="" />
        <h1 className="text-primaryTextColor font-inter-bold">
          {fullname}{" "}
          <p className="text-[#979797]  font-inter-regular text-[12px]">
            In-Person
          </p>
        </h1>
        </div>
        <div className="flex items-center gap-[7px]">
          <h1 className="text-[#979797] text-[12px] mr-[8px]">
            Hosted by{" "}
            <span className="text-primaryTextColor font-inter-bold">
              {host}
            </span>
          </h1>
          <span className="bg-[#F4F9FD] w-[24px] h-[24px] flex justify-center items-center rounded-[6px]">
            <Icon iconType={IconType.VIEW_ICON} />
          </span>
          <span className="bg-[#F4F9FD] w-[24px] h-[24px] flex justify-center items-center rounded-[6px]">
            <Icon iconType={IconType.CLOSE_ICON} className="p-1" />
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between mt-[26px]">
      <h1 className="text-[12px] text-[#979797] flex items-center gap-1">
      <Icon iconType={IconType.LOCATION_ICON}/>
      {location}
      </h1>
       <div className="flex items-center gap-[7px]">
        <h1 className="text-[12px] text-[#979797] font-inter-regular">{date}</h1>
        <h1 className="text-[12px] text-[#979797] font-inter-regular">{time}</h1>
       </div>
      </div>
    </div>
  );
};

export default AppointmentPDFCardViewer;
