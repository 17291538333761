import { FC, useState } from 'react'
import Form from '../../../atoms/form/Form'
import Input from '../../../atoms/input/Input'
import LoginTitle from '../../../atoms/title/LoginTitle'
import Paragraph from '../../../atoms/paragraph/Paragraph'
import Button from '../../../atoms/button/Button'
import BlueLink from '../../../atoms/link/BlueLink'
import PrimaryDatePicker from '../../../molecules/datepicker/PrimaryDatePicker'
import { useForgotPasswordForm } from '../../../../lib/hooks/forms/useForgotPasswordForm'
import { useForgotPassowrd } from '../../../../lib/hooks/mutations/ForgotPassword/useForgotPassowrd'
import ErrorState from 'components/atoms/error-state/ErrorState'

export interface IInitialForgotPasswordProps { }



const InitialForgotPassword: FC<IInitialForgotPasswordProps> = () => {


    const { mutate: forgotPassword, isError, isLoading } = useForgotPassowrd();


    const { register, formState: { errors }, handleSubmit, getValues } = useForgotPasswordForm();

    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const OnSubmit = () => {
        const data = getValues()
        Object.assign(data, { 'birthdate': startDate })

        forgotPassword(data)
    }
    return (
        <div className='flex flex-col gap-8'>
            <div>
                <div className='pb-2'>
                    <LoginTitle title='Forgot password' />
                </div>
                <Paragraph paragraph='Please enter your e-mail address to recover your password.' />
            </div>
            <Form onSubmit={handleSubmit(OnSubmit)}>
                <div className='flex flex-col gap-6'>
                    <Input
                        label='Email'
                        placeholder='My.email@finanu.ch'
                        type='email'
                        autoComplete='email'
                        {...register('email')}
                        error={errors.email}
                    />
                    <div>

                        <PrimaryDatePicker
                            label='Birthday'
                            name='birthdate'
                            setStartDate={setStartDate}
                            startDate={startDate}
                        />

                        <ErrorState error={isError} className='mt-5'>
                            Something went wrong
                        </ErrorState>
                    </div>
                    <div className='pt-5'>
                        <Button type='submit' className='bg-bluebutton text-white p-[15px] w-full rounded-[8px] disabled:opacity-50' disabled={isLoading}>
                        Login
                        </Button>


                    </div>
                    <div className='text-center'>
                        <span className='text-[14px] font-inter-regular'>No e-mail recived? </span><BlueLink to='/forgot-password' title='Send e-mail again.' />
                    </div>
                </div>
            </Form>

        </div>
    )
}

export default InitialForgotPassword