import React, { useState, useEffect } from "react";
import { Fragment } from "react/jsx-runtime";
interface PasswordStrengthMeterProps {
  password: string;
}
const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
}) => {
  const [strength, setStrength] = useState<number>(0);
  const calculateStrength = (password: string | undefined): number => {
    if (!password) {
      return 0; // Return a minimum strength value when password is undefined or empty
    }
    let score = 0;
    // Award points for each fulfilled criteria
    if (password.length >= 8) {
      score += 1; // Award more points for longer passwords
    }
    if (password.length >= 12) {
      score += 1; // Award more points for even longer passwords
    }
    if (/[A-Z]/.test(password)) {
      score += 1;
    }
    if (/[a-z]/.test(password)) {
      score += 1;
    }
    if (/\d/.test(password)) {
      score += 1;
    }
    // Increase score for special characters, if any
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
    if (specialChars.test(password)) {
      score += 1;
    }
    // Increase score if password has both numbers and special characters
    if (/\d/.test(password) && specialChars.test(password)) {
      score += 1;
    }
    // Scale the score to a percentage (0-100)
    return Math.min(Math.floor((score / 7) * 100), 100);
  };
  useEffect(() => {
    const calculatedStrength = calculateStrength(password);
    setStrength(calculatedStrength);
  }, [password]);
 const getStrengthColor = (index: number) => {
  const segmentStrength = (strength / 100) * 7;
  if (index < segmentStrength) {
    if (password.length >= 8 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return "bg-green-500 border-none"; // Green color when all conditions are met
    }
    if (password.length >= 8) {
      return "bg-yellow-500 border-none"; // Yellow color when only length is 8 or more
    }
    return "bg-red-500 border-none"; // Red color for other cases
  }
  return "bg-[#F4F9FD]";
};

const getStrengthTextColor = (index: number) => {
  const segmentStrength = (strength / 100) * 7;
  if (index < segmentStrength) {
    if (password.length >= 8 && /[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return "text-green-500"; // Green color when all conditions are met
    }
    if (password.length >= 8) {
      return "text-yellow-500"; // Yellow color when only length is 8 or more
    }
    return "text-red-500"; // Red color for other cases
  }
  return "text-gray-500";
};
  const getStrengthText = (index: number) => {
    const floorSegmentStrength = Math.floor(segmentStrength);
    if (index !== floorSegmentStrength) return null;
    if (index >= 5) return "Very Strong";
    if (index >= 4) return "Moderate";
    if (index >= 3) return "Fair";
    if (index >= 2) return "Weak";
    if (index >= 1) return "Very Weak";
    return null;
  };
  const segmentStrength = (strength / 100) * 7;
  return (
    <Fragment>
      <div className="grid grid-cols-[repeat(6,1fr)] gap-3 h-2 w-full relative">
        {[...Array(6)].map((_, index) => (
          <div
            key={`password-strength-elements-key-${index}`}
            className={`border-[0.25px] border-[#D0D5DD] rounded-xl ${getStrengthColor(
              index
            )}`}
          >
            {index < segmentStrength && (
              <span
                className={`mt-2  absolute whitespace-nowrap	 font-inter-medium uppercase text-[10px] ${getStrengthTextColor(
                  index
                )}`}
              >
                {getStrengthText(index)}
              </span>
            )}
          </div>
        ))}
      </div>
    </Fragment>
  );
};
export default PasswordStrengthMeter;
