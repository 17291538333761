import IconButton from "components/atoms/button/IconButton";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { useAllCampaigns } from "lib/hooks/queries/Campaign/useAllCampaigns";
import { useAllSources } from "lib/hooks/queries/Source/useAllSources";
import React, { Dispatch, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Label from "components/atoms/input/input-label/InputLabel";

export interface IFilterProps {
  source?: any;
  campaign?: any;
  status?: string;
  date_of_creation?: any;
}

const initialState: IFilterProps = {
  source: "",
  campaign: "",
  status: "",
  date_of_creation: undefined
};

export interface IFilterTableDropdownProps {
  setFilterData?: (args: any) => void;
  FilterData?: IFilterProps;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
}

const FilterTableDropdown: React.FC<IFilterTableDropdownProps> = ({
  setFilterData,
  FilterData,
  setIsOpen
}) => {
  const [DateOfCreation, setDateOfCreation] = useState<Date | null>(
    FilterData?.date_of_creation || null
  );
  const { control, reset } = useForm({
    defaultValues: FilterData || initialState
  });

  const [InsideData, setInsideData] = useState<IFilterProps>(
    FilterData || initialState
  );

  useEffect(() => {
    setInsideData((prevFormData: any) => ({
      ...prevFormData,
      date_of_creation: DateOfCreation
    }));
  }, [DateOfCreation]);

  const handleButtonClick = () => {
    if (setFilterData) {
      setFilterData(InsideData);
    }
    setIsOpen && setIsOpen(false);
  };

  const ResetData = () => {
    setInsideData(initialState);
    setDateOfCreation(null);
    reset(initialState);
  };

  const campaigns = useAllCampaigns();
  const sources = useAllSources();

  if (campaigns.isLoading || sources.isLoading)
    return (
      <div className="p-4">
        <LoadingScreen />
      </div>
    );

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Label text="Source" />
        <Controller
          name="source"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder="Choose Source"
              options={sources.data.sources.map((source: any) => ({
                label: source.name,
                value: source.id
              }))}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: sources.data.sources.find(
                        (source: any) => source.id === field.value
                      )?.name
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  source: selectedValue
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <div>
        <Label text="Campaign" />
        <Controller
          name="campaign"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder="Choose Campaign"
              options={campaigns.data.campaigns.map((campaign: any) => ({
                label: campaign.name,
                value: campaign.id
              }))}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: campaigns.data.campaigns.find(
                        (campaign: any) => campaign.id === field.value
                      )?.name
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  campaign: selectedValue
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>
      <div>
        <Label text="Status" />

        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder="Choose Status"
              options={[
                { label: "Unprocessed", value: "Unprocessed" },
                { label: "Not reached", value: "Not Reached" },
                { label: "In contact", value: "In Contact" },
                { label: "Appointment", value: "Appointment" },
                { label: "New customer", value: "New Customer" },
              ]}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: field.value
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  status: selectedValue
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>
      <PrimaryDatePicker
        label="Date of creation"
        setStartDate={setDateOfCreation}
        startDate={DateOfCreation}
        wrapperClassName="!left-[2px]"
      />

      <div className="pt-5">
        <div className="flex flex-row justify-end gap-3">
          <IconButton
            secondary={true}
            className="w-[110px]"
            onClick={ResetData}
          >
            Reset
          </IconButton>
          <IconButton className="w-[110px]" onClick={handleButtonClick}>
            Confirm
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default FilterTableDropdown;