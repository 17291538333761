import CustomSelect from 'components/atoms/custom-select/CustomSelect'
import Label from 'components/atoms/input/input-label/InputLabel'
import TextArea from 'components/atoms/text-area/TextArea'
import React from 'react'

const Step2ChoosePartner = () => {
    return (
        <div className='flex flex-col gap-5'>
            <div>
                <Label>
                    Partner name
                </Label>
                <CustomSelect
                    placeholder='Choose partner'
                />
            </div>
            <div>
                <Label>
                    Note
                </Label>
                <TextArea
                    placeholder='Write note...'
                />
            </div>
        </div>
    )
}

export default Step2ChoosePartner