import { ILeadCreate, PaginationParams } from "lib/types/leads.types";
import { apiRequest } from "./Api";

export const createNewLead = (data?: ILeadCreate) =>
  apiRequest<ILeadCreate, unknown>("POST", "leads/store", data);


export const getLeads = (params?: PaginationParams) =>
  apiRequest<any, any>("GET", "leads", null, undefined, params);

export const updateLead = (params: any) => {

  return apiRequest<FormData, any>("post", `leads/update`, params, undefined);
};

export const updateLeadStatus = (params: any) => {
  return apiRequest<FormData, any>("post", `leads/change-status`, params, undefined);
};

export const uploadLeadFiles = (params: any) => {
  return apiRequest<FormData, any>("post", `leads/document/upload`, params, undefined);
};

export const openDocument = (params: any) => {
  return apiRequest<FormData, any>("get", `leads/document/${params.link}`);
};

export const assignLeads = (data: any) => {
  return apiRequest<FormData, any>("post", `leads/assign`, data);
}

export const getActivityLog = (params: any) =>
  apiRequest<any, any>("GET", "leads/logs", null, undefined, params);

export const uploadLeadFileManually = (data: any) =>
  apiRequest<any, any>("POST", "leads/import/store-import-file", data, {
    "content-type": "multipart/form-data"
  });

export const dataOfCsv = (data: any) =>
  apiRequest<any, any>("POST", "leads/import/csv-data", data);

export const storeUploadedLeads = (data: any) =>
  apiRequest<FormData, any>("POST", "leads/import/store", data);

export const DeleteLeadDocument = (data: any) =>
  apiRequest<any, any>("DELETE", `leads/document/delete/${data.document_id}`, data);


export const exportLead = (data: any) => 
  apiRequest<FormData,any>('POST', 'leads/request/store',data)

export const getRequestsData =(params: any) => 
  apiRequest<any,any>('GET', 'leads/request',null, undefined, params)


export const requestFeedBack = (data: any) => 
  apiRequest<FormData,any>('POST','leads/request/feedback',data)


export const downloadFeedBack = (data: any) => 
  apiRequest<FormData,any>('POST',`leads/request/export-leads`, data, undefined, undefined, 'blob')


export const getActivityLogRequestData = (params: any) => 
  apiRequest<any,any>('GET',`leads/request/logs`,null,undefined,params)



export const getStatisticCardsData =(params: any) => 
  apiRequest<any,any>('GET', 'leads/statistic/info-card',null, undefined, params)


export const getLeadDocument = (params?: any) =>
  apiRequest<any, any>("GET", "leads/document", null, undefined, params);