import React, { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}
function CricleCloseIcon({ className, color }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="3.5"
        y="3.5"
        width="17.5"
        height="17.5"
        rx="8.75"
        stroke={color || "#D61616"}
      />
      <path
        d="M14.7944 10.0022L10.0024 14.7942"
        stroke={color || "#D61616"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.796 14.797L10 10"
        stroke={color || "#D61616"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default memo(CricleCloseIcon);
