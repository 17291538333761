import React, { useEffect, useRef, useState } from "react";
import IconButton from "components/atoms/button/IconButton";
import Label from "components/atoms/input/input-label/InputLabel";
import Form from "components/atoms/form/Form";
import { useRelatedCustomersForFilter } from "lib/hooks/queries/Coustomer/useRelatedCustomersForFilter";
import { IconType } from "lib/helpers/constants/iconTypes";
import Icon from "components/atoms/icon/Icon";
import { useUpdateCustomerFamilyMember } from "lib/hooks/mutations/Customers/useUpdateCustomerFamilyMember";
import { toast } from "react-toastify";

interface RelatedCustomerFormProps<T> {
  onAddCustomer: (customer: any) => void;
  setCurrentStep: (value: number) => void;
  data: T;
  releatedCustomerId: any;
}

const UpdateRelatedCustomerForm: React.FC<RelatedCustomerFormProps<any>> = ({
  onAddCustomer,
  setCurrentStep,
  data,
  releatedCustomerId,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const { data: relatedCustomers, refetch } = useRelatedCustomersForFilter({
    search_input: inputValue,
  });
  const { mutateAsync: updateFamilyMember, isLoading: isLoading } =
    useUpdateCustomerFamilyMember();

  useEffect(() => {
    if (relatedCustomers && Array.isArray(relatedCustomers)) {
      setAllUsers(relatedCustomers.flat());
    }
  }, [relatedCustomers]);

  useEffect(() => {
    filterUsers(inputValue);
  }, [inputValue, allUsers, selectedUser]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsFocused(true);
    if (e.target.value === "") {
      setSelectedUser(null);
      refetch();
    }
  };

  const filterUsers = (input: string) => {
    const notSelectedUsers = allUsers.filter(
      (user) => selectedUser?.id !== user.id
    );
    const filtered = notSelectedUsers.filter((user) =>
      `${user.first_name} ${user.last_name}`
        .toLowerCase()
        .includes(input.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && filteredUsers.length > 0) {
      e.preventDefault(); // Prevent form submission
      handleSelect(filteredUsers[0]);
    }
  };

  const handleSelect = (user: any) => {
    setSelectedUser(user);
    setInputValue(`${user.first_name} ${user.last_name} (${user.birthdate})`);
    setFilteredUsers([]);
    onAddCustomer(user);
    setIsFocused(false);
    inputRef.current?.blur();
  };

  const resetSelection = () => {
    setSelectedUser(null);
    setInputValue("");
    refetch();
  };

  const handleInputBlur = () => {
    // Delay hiding suggestions to allow click event to be processed
    setTimeout(() => {
      if (!suggestionsRef.current?.contains(document.activeElement)) {
        setIsFocused(false);
      }
    }, 200);
  };

  const onUpdate = () => {
    updateFamilyMember({
      costumer_id: data?.id,
      costumer_relations_id: releatedCustomerId?.[0]?.id || null,
    })
      .then(() => {
        setCurrentStep(1);
      })
      .catch(() => {});
  };

  return (
    <Form className="mt-[30px]">
      <Label className="!mb-[10px]">Related Customer Name</Label>
      <div className="flex flex-col p-[7px] lg:p-[10px] border rounded-[10px] relative">
        <Icon
          iconType={IconType.SEARCH_ICON}
          className="absolute left-[27px]"
        />
        <input
          className="outline-none font-inter-medium w-full h-[23px] indent-[50px]"
          placeholder="Enter customer name"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={handleInputBlur}
          ref={inputRef}
        />
        {isFocused && inputValue !== "" && (
          <div className="mt-2" ref={suggestionsRef}>
            {filteredUsers.map((user) => (
              <div
                key={user.id}
                className="py-1 indent-[25px] pb-[14px] font-inter-medium text-[16px] text-[#282D46] leading-[24px] cursor-pointer"
                onClick={() => handleSelect(user)}
              >
                {`${user.first_name} ${user.last_name} (${user.birthdate})`}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex items-center gap-5 justify-end mb-[20px] mt-[45px]">
        <IconButton
          type="button"
          secondary
          className="w-[120px]"
          onClick={resetSelection}
        >
          Reset
        </IconButton>
        <IconButton
          disabled={isLoading}
          onClick={onUpdate}
          type="button"
          className="w-[120px]"
        >
          {isLoading ? "Updating..." : "Add"}
        </IconButton>
      </div>
    </Form>
  );
};

export default UpdateRelatedCustomerForm;
