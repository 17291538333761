import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { IconType } from "lib/helpers/constants/iconTypes";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ActivityLogItem from "components/molecules/activity-log-item/ActivityLogItem";
import TextArea from "components/atoms/text-area/TextArea";
import { useLeadsForm } from "lib/hooks/forms/useLeadsForm";
import CounterControl from "components/molecules/counter-control-append/CounterControlAppend";
import Form from "components/atoms/form/Form";
import { useUpdateLeads } from "lib/hooks/mutations/Leads/useUpdateLead";
import Button from "components/atoms/button/Button";
import UploadLeadFilesTab from "./UploadLeadFilesTab";
import { useLeadsActivityLog } from "lib/hooks/queries/Leads/useLeadsActivityLog";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import ToggleSwitch from "components/molecules/toggle-switch/ToggleSwitch";
import LeadStatusSelect from "components/atoms/lead-status-select/LeadStatusSelect";
import ErrorState from "components/atoms/error-state/ErrorState";
import withModal from "lib/helpers/hoc/withModal";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useLeadDocument } from "lib/hooks/queries/Leads/useLeadDocument";
import { languageData } from "lib/helpers/constants/languageData";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";
import {
  fieldTypesOptions,
  options,
} from "lib/helpers/constants/optionsOfExtraFieldToLeads";

interface ActivityLogData<T> {
  data: T;
}

export interface IUpdateLeadProps<T> {
  onConfirm: () => void;
  lead: T;
  setFormCurrentStep: Dispatch<SetStateAction<number>>;
  formCurrentStep: number;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
  hasChanges: boolean;
}

const UpdateLead: FC<IUpdateLeadProps<any>> = ({
  onConfirm,
  lead,
  formCurrentStep,
  setFormCurrentStep,
  setHasChanges,
  hasChanges,
}) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [LeadInfo, setLeadInfo] = useState(lead.info);
  const {
    data: activityLogData,
    refetch: refetchLogs,
    isLoading: isLoadingLogs,
  } = useLeadsActivityLog(
    {
      lead_id: lead.id,
    },
    {
      enabled: activeTab === 3 ? true : false,
    }
  );
  const [localAddNewSection, setAddNewLocalSection] = useState<boolean>();
  const [state, setState] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { themeColors } = useThemeContext();
  const {
    data: leadsDocumentsData,
    refetch: refetchDocuments,
    isLoading: isLoadingGettingDocuments,
  } = useLeadDocument(
    {
      lead_id: lead.id,
    },
    {
      enabled: activeTab === 2 ? true : false,
    }
  );

  const {
    register: extraFieldRegister,
    handleSubmit: handleSubmitNested,
    reset: resetExtraFields,
    formState: { errors: extraFieldsErrors },
    control: extraFieldControl,
  } = useForm();

  let extraFields: { [key: string]: any } = {};

  useEffect(() => {
    setLeadInfo(lead.info);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead.info, lead.id, lead.feedback_status]);

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
  } = useLeadsForm({
    values: {
      persons: lead.info.persons,
      birthdate: lead.info.birthdate,
      code: " ",
      phone: lead.info.phone,
      extra_fields: lead.extra_fields,
      language: lead.info.language,
    },
  });

  const { mutate: updateLead, isLoading: isUpdateLoading } = useUpdateLeads(
    lead.id ?? ""
  );

  const handleUpdateLead = (data: any) => {
    let extraFields: { [key: string]: any } = {};

    const allExtraFields = [...state, ...lead.extra_fields];

    allExtraFields.forEach((item: any) => {
      extraFields[item?.field_name || item?.data?.field_name] = {
        value: data[item?.field_name || item?.data?.field_name],
        default_text: item?.default_text || item?.data?.default_text,
        required: item?.required || item?.data?.required ? 1 : 0,
        section: item?.section || item?.data?.section,
        field_type: item?.field_type || item?.data?.field_type,
      };
      delete data[item?.field_name || item?.data?.field_name];
    });

    const extraFieldsArray = Object.keys(extraFields).map((key) => ({
      section: extraFields[key].section,
      field_name: key,
      field_value: extraFields[key].value,
      default_text: extraFields[key].default_text,
      required: extraFields[key].required,
      field_type: extraFields[key].field_type,
    }));

    const updatedFormData = {
      lead_id: lead.id,
      ...data,
      extra_fields: extraFieldsArray,
    };
    delete (updatedFormData as { [key: string]: string }).code;

    onConfirm();
    updateLead(updatedFormData);
    setHasChanges(false);
    setAddNewLocalSection(false);
    resetExtraFields({
      section: "",
      field_name: "",
      field_type: "",
      default_text: "",
      required: false,
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setLeadInfo((prevFormData: any) => ({
      ...prevFormData,
      [name]: value.trim(),
    }));
    setHasChanges(true);
  };

  const onSubmitExtraFields = (data: any) => {
    const isDuplicateInState = state.some(
      (item: any) => item.data.field_name === data.field_name
    );
    const isDuplicateInLead = lead?.extra_fields?.some(
      (field: any) => field.field_name === data.field_name
    );

    if (isDuplicateInState || isDuplicateInLead) {
      setErrorMessage("Duplicate field name is not allowed");
      return;
    }

    setFormCurrentStep(1);
    setState((prevState: any) => [...prevState, { data }]);
    resetExtraFields();
    resetExtraFields({
      section: "",
      field_name: "",
      field_type: "",
      default_text: "",
      required: false,
    });
    setAddNewLocalSection(false);
  };

  const combinedFields = [
    ...(lead?.extra_fields || []),
    ...state.map((item: any) => item.data),
  ];

  const groupedFields: { [section: string]: any[] } = {};

  combinedFields.forEach((field) => {
    extraFields[field.field_name] = {
      value: watch(field.field_name),
      default_text: field.default_text,
    };

    if (!groupedFields[field.section]) {
      groupedFields[field.section] = [];
    }
    groupedFields[field.section].push(field);
  });

  return (
    <div>
      <ConditionalRender condition={formCurrentStep === 1}>
        <div className="flex flex-col lg:flex-row gap-10">
          <p className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]">
            {lead.info.first_name + " " + lead.info.last_name}
            <LeadStatusSelect
              lead_id={lead.id}
              status={lead.feedback_status}
              data={lead}
              confirm={onConfirm}
            />
          </p>
          <div className="flex-none">
            <div className="w-full lg:w-[250px] rounded-[10px] border border-[#A2A1A833] sticky top-0">
              <div
                onClick={() => {
                  setActiveTab(1);
                }}
                className={`px-6 py-4 rounded-t-[10px] flex gap-3 items-center ${
                  activeTab === 1
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                }`}
              >
                <Icon
                  iconType={IconType.PROFILE_ICON}
                  color={activeTab === 1 ? "#fff" : undefined}
                />
                <span>Lead Information</span>
              </div>
              <div
                onClick={() => {
                  setActiveTab(2);
                  refetchDocuments();
                }}
                className={`px-6 py-4 flex gap-3 items-center ${
                  activeTab === 2
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                }`}
              >
                <Icon
                  iconType={IconType.DOCUMENT_ICON}
                  color={activeTab === 2 ? "#fff" : undefined}
                />

                <span>Documents</span>
              </div>
              <div
                onClick={() => {
                  setActiveTab(3);
                  refetchLogs();
                }}
                className={`px-6 py-4 rounded-b-[10px] flex gap-3 items-center ${
                  activeTab === 3
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                }`}
              >
                <Icon
                  iconType={IconType.ACTIVITY_LOG_ICON}
                  color={activeTab === 3 ? "#fff" : undefined}
                />

                <span>Activity Log</span>
              </div>
            </div>
          </div>
          <div className="flex-1 ">
            <div>
              <ConditionalRender condition={activeTab === 1}>
                <Form
                  className="flex flex-col gap-5 mb-0"
                  onSubmit={handleSubmit(handleUpdateLead)}
                >
                  <InputsFolder title="Personal information">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                      <Input
                        label="First Name"
                        placeholder="First Name"
                        {...register("first_name")}
                        error={errors?.first_name}
                        value={LeadInfo.first_name}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <Input
                        label="Last Name"
                        placeholder="Last Name"
                        {...register("last_name")}
                        error={errors?.last_name}
                        value={LeadInfo.last_name}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <Input
                        label="Nationality"
                        placeholder="Nationality"
                        {...register("nationality")}
                        error={errors?.nationality}
                        value={LeadInfo.nationality}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <Controller
                        control={control}
                        name="birthdate"
                        rules={{ required: "Date of birth is required" }}
                        render={({ field: { onChange, value } }) => (
                          <PrimaryDatePicker
                            label="Date Of Birth"
                            startDate={value}
                            {...register("birthdate")}
                            setStartDate={(date: string) => onChange(date)}
                            error={errors.birthdate}
                          />
                        )}
                      />
                      <Input
                        label="Current Health Insurance"
                        placeholder="Current Health Insurance"
                        {...register("health_insurance")}
                        name="health_insurance"
                        error={errors?.health_insurance}
                        value={LeadInfo.health_insurance}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <div>
                        <Label>Persons in Household</Label>
                        <div className="w-full">
                          <Controller
                            control={control}
                            name="persons"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <CounterControl
                                count={value}
                                className="!mt-0"
                                classes={{
                                  container:
                                    "w-full bg-white !border-inputborder",
                                }}
                                onIncrement={() =>
                                  onChange(parseInt(value) + 1)
                                }
                                onDecrement={() =>
                                  onChange(
                                    parseInt(value) >= 1
                                      ? parseInt(value) - 1
                                      : parseInt(value)
                                  )
                                }
                              />
                            )}
                          />
                        </div>
                      </div>
                      {state.map((item: any) => {
                        if (item?.data?.section === "Personal information") {
                          extraFields[item?.data?.field_name] = {
                            value: watch(item?.data?.field_name),
                          };
                          return (
                            <Input
                              label={item?.data?.field_name}
                              type={
                                item?.data?.field_type === "text"
                                  ? "text"
                                  : "number"
                              }
                              required={item?.data?.required ? true : false}
                              {...register(item?.data?.field_name, {
                                required: item?.data?.required
                                  ? `${item?.data?.field_name} is required`
                                  : false,
                              })}
                              error={errors?.[item?.data?.field_name]}
                            />
                          );
                        }
                      })}
                      {lead?.extra_fields?.map((field: any) => {
                        if (field.section === "Personal information") {
                          return (
                            <Input
                              label={field.field_name}
                              type={
                                field.field_type === "text" ? "text" : "number"
                              }
                              required={field?.required ? true : false}
                              {...register(field.field_name, {
                                required: field.required
                                  ? `${field.field_name} is required`
                                  : false,
                                value: field?.field_value ?? "",
                              })}
                              error={errors?.[field.field_name]}
                            />
                          );
                        }
                      })}
                    </div>
                  </InputsFolder>
                  <InputsFolder title="Contact information">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                      <div className="relative">
                        <Label text="Language" />
                        <Controller
                          name="language"
                          control={control}
                          rules={{ required: "Language is required" }}
                          render={({ field }) => (
                            <Creatable<any, false>
                              {...field}
                              options={
                                languageData?.map?.((language) => ({
                                  value: language.value,
                                  label: language.label,
                                })) || []
                              }
                              defaultValue={
                                lead?.info?.language
                                  ? {
                                      value: lead.info.language,
                                      label: lead.info.language,
                                    }
                                  : null
                              }
                              defaultInputValue={lead?.info?.language || ""}
                              value={
                                field.value
                                  ? { value: field.value, label: field.value }
                                  : null
                              }
                              className="cursor-pointer font-inter-regular react-select-container"
                              classNamePrefix="react-select"
                              onChange={(newValue: any, actionMeta: any) => {
                                if (
                                  actionMeta.action === "select-option" &&
                                  newValue?.value
                                ) {
                                  field.onChange(newValue.value);
                                } else if (actionMeta.action === "clear") {
                                  field.onChange("");
                                }
                              }}
                              styles={customStyles}
                              isValidNewOption={() => false}
                              menuPosition={"fixed"}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        {errors.language?.message && (
                          <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                            <>{errors.language?.message}</>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-3">
                        <div className="flex-1">
                          <Input
                            placeholder="Enter phone number"
                            {...register("phone")}
                            error={errors.phone}
                            name="phone"
                            onChange={(e) => handleInputChange(e)}
                            label="Phone"
                          />
                        </div>
                        <div className="flex-none pt-[35px]">
                          <Icon iconType={IconType.PHONE_ICON} />
                        </div>
                      </div>
                      <Input
                        placeholder="Enter email"
                        label="Email"
                        {...register("email")}
                        error={errors?.email}
                        value={LeadInfo.email}
                        onChange={(e) => handleInputChange(e)}
                      />
                      {state.map((item: any) => {
                        if (item?.data?.section === "Contact information") {
                          extraFields[item?.data?.field_name] = {
                            value: watch(item?.data?.field_name),
                            default_text: item?.data?.default_text,
                          };
                          return (
                            <Input
                              label={item?.data?.field_name}
                              type={
                                item?.data?.field_type === "text"
                                  ? "text"
                                  : "number"
                              }
                              required={item?.data?.required ? true : false}
                              {...register(item?.data?.field_name)}
                              error={errors?.[item?.data?.field_name]}
                            />
                          );
                        }
                        return null;
                      })}
                      {lead?.extra_fields?.map((field: any) => {
                        if (field.section === "Contact information") {
                          return (
                            <Input
                              label={field.field_name}
                              type={
                                field.field_type === "text" ? "text" : "number"
                              }
                              {...register(field.field_name, {
                                required: field.required
                                  ? `${field.field_name} is required`
                                  : false,
                                value: field?.field_value ?? "",
                              })}
                              required={field?.required ? true : false}
                              error={errors?.[field.field_name]}
                            />
                          );
                        }
                      })}
                    </div>
                  </InputsFolder>
                  <InputsFolder title="Address details">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pb-2">
                      <div className="flex flex-col gap-5">
                        <Input
                          placeholder="Enter street"
                          label="Street"
                          classes={{ icon: "!bg-white right-[8px]" }}
                          icon={IconType.LOCATION_ICON}
                          {...register("address")}
                          error={errors?.address}
                          value={LeadInfo.address}
                          name="address"
                          onChange={(e) => handleInputChange(e)}
                        />
                        <Input
                          placeholder="Enter Nr"
                          label="Nr"
                          {...register("nr")}
                          error={errors?.nr}
                          value={LeadInfo.nr}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <Input
                          placeholder="Enter PLZ"
                          label="PLZ"
                          {...register("postal_code")}
                          error={errors?.postal_code}
                          value={LeadInfo.postal_code}
                          name="postal_code"
                          onChange={(e) => handleInputChange(e)}
                        />
                        <Input
                          placeholder="Enter City"
                          label="City"
                          {...register("city")}
                          error={errors?.city}
                          value={LeadInfo.city}
                          name="city"
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                      <div>
                        <iframe
                          className="border-inputborder border rounded-[8px]"
                          title="map"
                          src={`https://maps.google.com/maps?q='+${LeadInfo.postal_code} ${LeadInfo.address} ${LeadInfo.city}  ${LeadInfo.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                          style={{ width: "100%", height: "100%" }}
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                      {state.map((item: any) => {
                        if (item?.data?.section === "Address details") {
                          extraFields[item?.data?.field_name] = {
                            value: watch(item?.data?.field_name),
                            default_text: item?.data?.default_text,
                          };
                          return (
                            <Input
                              label={item?.data?.field_name}
                              type={
                                item?.data?.field_type === "text"
                                  ? "text"
                                  : "number"
                              }
                              required={item?.data?.required ? true : false}
                              {...register(item?.data?.field_name)}
                              error={errors?.[item?.data?.field_name]}
                            />
                          );
                        }
                        return null;
                      })}
                      {lead?.extra_fields?.map((field: any) => {
                        if (field.section === "Address details") {
                          return (
                            <Input
                              label={field.field_name}
                              type={
                                field.field_type === "text" ? "text" : "number"
                              }
                              {...register(field.field_name, {
                                required: field.required
                                  ? `${field.field_name} is required`
                                  : false,
                                value: field?.field_value ?? "",
                              })}
                              required={field?.required ? true : false}
                              error={errors?.[field.field_name]}
                            />
                          );
                        }
                      })}
                    </div>
                  </InputsFolder>
                  <InputsFolder title="Related details">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                      <div>
                        <Label>Note</Label>
                        <TextArea
                          {...register("notes")}
                          rows={4}
                          className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                          placeholder="Write note"
                          error={errors?.notes}
                          value={LeadInfo.notes}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                      {state.map((item: any) => {
                        if (item?.data?.section === "Related details") {
                          extraFields[item?.data?.field_name] = {
                            value: watch(item?.data?.field_name),
                            default_text: item?.data?.default_text,
                          };
                          return (
                            <Input
                              label={item?.data?.field_name}
                              type={
                                item?.data?.field_type === "text"
                                  ? "text"
                                  : "number"
                              }
                              required={item?.data.required ? true : false}
                              {...register(item?.data?.field_name)}
                              error={errors?.[item?.data?.field_name]}
                            />
                          );
                        }
                        return null;
                      })}
                      {lead?.extra_fields?.map((field: any) => {
                        if (field.section === "Related details") {
                          return (
                            <Input
                              label={field.field_name}
                              type={
                                field.field_type === "text" ? "text" : "number"
                              }
                              {...register(field.field_name, {
                                required: field.required
                                  ? `${field.field_name} is required`
                                  : false,
                                value: field?.field_value ?? "",
                              })}
                              required={field?.required ? true : false}
                              error={errors?.[field.field_name]}
                            />
                          );
                        }
                      })}
                    </div>
                  </InputsFolder>
                  {Object.entries(groupedFields).map(([section, fields]) =>
                    section !== "Personal information" &&
                    section !== "Contact information" &&
                    section !== "Address details" &&
                    section !== "Related details" ? (
                      <InputsFolder key={section} title={section}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                          {fields.map((field) => (
                            <Input
                              key={field.field_name}
                              label={field.field_name}
                              type={
                                field.field_type === "text" ? "text" : "number"
                              }
                              {...register(field.field_name, {
                                required: field.required
                                  ? `${field.field_name} is required`
                                  : false,
                                value: field.field_value ?? "",
                              })}
                              error={errors?.[field.field_name]}
                            />
                          ))}
                        </div>
                      </InputsFolder>
                    ) : null
                  )}
                  <div className="flex justify-end gap-[26px]">
                    <Button
                      type="button"
                      onClick={() => {
                        setFormCurrentStep(2);
                      }}
                      className={`bg-transparent flex items-center gap-2 font-inter-medium text-[14px] text-[--theme]`}
                    >
                      <Icon
                        iconType={IconType.PLUS_ICON}
                        color={themeColors.primary}
                      />
                      Add Field
                    </Button>
                    <IconButton disabled={isUpdateLoading ? true : false}>
                      Update
                    </IconButton>
                  </div>
                </Form>
              </ConditionalRender>

              <ConditionalRender condition={activeTab === 2}>
                <UploadLeadFilesTab
                  onConfirm={onConfirm}
                  lead={lead}
                  data={leadsDocumentsData}
                  isLoadingGettingDocuments={isLoadingGettingDocuments}
                />
              </ConditionalRender>

              <ConditionalRender condition={activeTab === 3}>
                <ActivityLogItem
                  data={
                    (activityLogData as { data?: any })
                      ?.data as ActivityLogData<any>
                  }
                  isLoading={isLoadingLogs}
                  showReview={false}
                  href="leads"
                />
              </ConditionalRender>
            </div>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={formCurrentStep === 2}>
        <Form onSubmit={handleSubmitNested(onSubmitExtraFields)}>
          <Button
            onClick={() => setFormCurrentStep(1)}
            type="button"
            className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
          >
            <Icon
              iconType={IconType.BACK_BUTTON_MODAL}
              className="!w-[30px] !h-[30px]"
              color={themeColors.primary}
            />
            Create New Field
          </Button>
          {localAddNewSection ? (
            <Input
              {...extraFieldRegister("section")}
              label="Section Name"
              className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
              placeholder="Write note"
              error={errors?.section}
            />
          ) : (
            <>
              <div className="relative">
                <Label text="Section" />
                <Controller
                  name="section"
                  control={extraFieldControl}
                  render={({ field }) => (
                    <Creatable<any, false>
                      {...field}
                      placeholder="Choose a  section"
                      options={options}
                      value={
                        options.find(
                          (option) => option.value === field.value
                        ) || ""
                      }
                      className="cursor-pointer font-inter-regular react-select-container"
                      classNamePrefix="react-select"
                      onChange={(newValue: any, actionMeta: any) => {
                        if (
                          actionMeta.action === "select-option" &&
                          newValue?.value
                        ) {
                          field.onChange(newValue.value);
                        }
                      }}
                      styles={customStyles}
                      isValidNewOption={() => false}
                      menuPosition={"fixed"}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
              </div>
            </>
          )}
          <Button
            onClick={() => setAddNewLocalSection(true)}
            type="button"
            className={`bg-transparent flex items-center gap-2 font-inter-medium text-[14px] text-[--theme] mt-5 ml-auto`}
          >
            <Icon iconType={IconType.PLUS_ICON} color={themeColors.primary} />
            Add new section
          </Button>
          <div className="flex flex-col gap-[15px]">
            <Input
              {...extraFieldRegister("field_name", {
                required: "Field Name is required",
              })}
              label="Field Name*"
              className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
              placeholder="Write note"
              error={extraFieldsErrors?.field_name}
            />
            <div className="relative">
              <Label text="Field Type*" />
              <Controller
                name="field_type"
                control={extraFieldControl}
                rules={{ required: "Field Type is required" }}
                render={({ field }) => (
                  <Creatable<any, false>
                    {...field}
                    placeholder="Choose Field Type"
                    options={fieldTypesOptions}
                    value={
                      fieldTypesOptions.find(
                        (option) => option.value === field.value
                      ) || ""
                    }
                    className="cursor-pointer font-inter-regular react-select-container"
                    classNamePrefix="react-select"
                    onChange={(newValue: any, actionMeta: any) => {
                      if (
                        actionMeta.action === "select-option" &&
                        newValue?.value
                      ) {
                        field.onChange(newValue.value);
                      }
                    }}
                    styles={customStyles}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                  />
                )}
              />
              {extraFieldsErrors.field_type?.message && (
                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                  <>{extraFieldsErrors.field_type?.message}</>
                </div>
              )}
            </div>
            <Input
              {...extraFieldRegister("default_text")}
              label="Default Text"
              className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
              placeholder="Write note"
              error={extraFieldsErrors?.default_text}
            />
            <div className="flex items-center gap-5">
              <Label text="Required *" />
              <ToggleSwitch {...extraFieldRegister("required")} />
            </div>
            <div className="flex justify-end gap-4 mt-[40px]">
              <IconButton
                type="button"
                onClick={() => {
                  resetExtraFields({
                    section: "",
                    field_name: "",
                    field_type: "",
                    default_text: "",
                    required: false,
                  });
                  setAddNewLocalSection(false);
                }}
                secondary={true}
                className="w-full max-w-[150px]"
              >
                Reset
              </IconButton>
              <IconButton
                type="submit"
                icon={<Icon iconType={IconType.INCRASE_ICON} color="white" />}
                className={`w-full max-w-[150px]  text-white border-[--theme]`}
              >
                Create
              </IconButton>
            </div>
          </div>
          <ErrorState error={errorMessage}>{errorMessage}</ErrorState>
        </Form>
      </ConditionalRender>
      <ConditionalRender condition={formCurrentStep === 3}>
        <div className="w-full">
          <p className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]">
            Are you sure?
          </p>
          <p className="font-inter-medium text-[#282D46] text-[20px] mt-[10px] max-w-[475px]">
            Are you sure you want to delete all modifications you have achieved?
          </p>
          <div className="flex justify-end gap-5 mt-[53px]">
            <IconButton
              onClick={() => {
                setFormCurrentStep(1);
                onConfirm();
                setHasChanges(false);
              }}
              secondary={true}
              type="button"
              className="w-full max-w-[150px]"
            >
              Leave
            </IconButton>
            <IconButton
              onClick={() => setFormCurrentStep(1)}
              type="button"
              className="w-full max-w-[150px] text-white"
            >
              Stay
            </IconButton>
          </div>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default withModal(UpdateLead);
