import React, { FunctionComponent, useEffect, memo, CSSProperties } from "react";
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
  Row,
} from "react-table";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { Fragment } from "react/jsx-runtime";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";

type TypeTableClass = {
  container: string;
  table: string;
  subContainer: string;
};

export interface ITable<T extends object> {
  columns: any;
  data: T[];
  search: string;
  isColumnSorted: boolean | undefined;
  classes?: Partial<TypeTableClass>;
  expanded?: boolean;
  style?: CSSProperties | undefined;
}

const Table: FunctionComponent<ITable<object>> = ({
  columns,
  data,
  isColumnSorted,
  style,
  search,
  classes = { container: "", table: "" ,subContainer: ""},
  expanded
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 500, globalFilter: "" }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded, // Add the useExpanded hook here
    usePagination,
    useRowSelect
  );
  const {themeColors} = useThemeContext();
  useEffect(() => {
    setGlobalFilter(search);
  }, [search, setGlobalFilter]);

  const tableRef = React.useRef<any>();

  useEffect(() => {
    if (tableRef.current.clientHeight > 699) {
      tableRef.current.classList.add("verticalOverflowScrollStyle");
      tableRef.current.classList.add("horizontalOverflowScrollStyle");
    }
  }, [tableRef]);

  return (
    <div className={`bg-white ${classes.container}`}>
      <div
        ref={tableRef}
        className={`${classes.subContainer} sm:rounded-lg  md:w-[calc(auto-_330px)]  max-h-[700px] lg:max-h-[800px] horizontalOverflowScrollStyle verticalOverflowScrollStyle`}
      >
        <table
          {...getTableProps()}
          style={style}
          className={`w-full text-sm text-left text-gray-500 dark:text-gray-400 ${classes.table}`}
        >
          <thead className="border-b-[1px] border-[#A2A1A8] border-opacity-20">
            {headerGroups?.map((headerGroup,index) => {
              const { key, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={index} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key, ...restColumnProps } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );
                    return (
                      <th
                        key={key}
                        {...restColumnProps}
                        className="px-2 py-3 font-inter-regular font-[400] text-[#6C737F]"
                      >
                        <div className="flex items-center gap-2 w-fit">
                          {column.render("Header")}
                          {typeof column.Header === "string" &&
                            column.Header.length > 0 &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon iconType={IconType.DOWN_ARROW_ICON} />
                              ) : (
                                <Icon iconType={IconType.UP_ARROW_ICON} />
                              )
                            ) : (
                              <Icon iconType={IconType.DOWN_ARROW_ICON} />
                            ))}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row) => {
              prepareRow(row as Row<object>);
              const { key, ...restRowProps } = row.getRowProps();
              return (
                <Fragment key={key}>
                  <tr
                    {...restRowProps}
                    style={row.isExpanded ? {
                      backgroundColor: `${themeColors.primary}0D`
                    }: {}}
                  >
                    {row?.cells?.map((cell) => {
                      const { key, ...restCellProps } = cell.getCellProps();
                      return (
                        <td
                          key={key}
                          {...(restCellProps as any)}
                          className="px-2 py-4 font-inter-medium text-primaryTextColor "
                        >
                          {cell?.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                      <Fragment>
                        {columns.map((column: any) => {
                          if (column.SubCell as any) {
                            return column.SubCell({ row });
                          }
                          return null;
                        })}
                      </Fragment>
                  ) : null}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(Table);
