import { FC, useState } from "react";
import { createCampaignTableHeaders } from "lib/helpers/constants/columns/createCampaignTableHeaders";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Step1 from "./upload-lead-steps/Step1";
import Step2 from "./upload-lead-steps/Step2";
import Step3 from "./upload-lead-steps/Step3";
import Step4 from "./upload-lead-steps/Step4";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import withModal from "lib/helpers/hoc/withModal";
import ExtraFields from "./upload-lead-steps/ExtraFields";

export interface IUploadLeadProps {
  onConfirm: () => void;
  isAssignLeadsActive: boolean;
  themeColors: {
    primary: string;
  };
  setFormCurrentStep: any;
  currentStep: number;
}

const UploadLead: FC<IUploadLeadProps> = ({
  onConfirm,
  isAssignLeadsActive,
  themeColors,
  currentStep,
  setFormCurrentStep
}) => {
  const handleFormValuesChange = (step: number, values: any) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  };
  const [state,setState] = useState<any>([]);

  const [FormValues, setFormValues] = useState<Array<string>>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cardsOrder, setCardsOrder] = useState(createCampaignTableHeaders);

  const handleUpdateData = (updatedData: any[]) => {
    setFormValues(updatedData);
  };

  return (
    <div className="mb-0 flex flex-col gap-7">
      <ConditionalRender condition={currentStep >= 2 && currentStep !== 4 && currentStep < 5}>
        <button
          type="button"
          className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
          onClick={() => {
            setFormCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
          }}
        >
          <Icon
            className={currentStep >= 2 && currentStep < 6 ? "block" : "hidden"}
            iconType={IconType.BACK_BUTTON_MODAL}
            color={themeColors.primary}
          />
        </button>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 1}>
        <Step1
          themeColors={themeColors}
          setStep={setFormCurrentStep}
          handleFormValuesChange={handleFormValuesChange}
          formValues={FormValues}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <Step2 themeColors={themeColors} setStep={setFormCurrentStep} state={state} setState={setState}/>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
        <Step3
          setDataCols={setCardsOrder}
          themeColors={themeColors}
          setStep={setFormCurrentStep}
          onUpdateData={handleUpdateData}
          dataCols={cardsOrder}
          isAssignLeadsActive={isAssignLeadsActive}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 4}>
        <Step4
          themeColors={themeColors}
          onConfirm={onConfirm}
          setCurrentStep={setFormCurrentStep}
        />
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 5}>
        <ExtraFields
          themeColors={themeColors}
          setFormCurrentStep={setFormCurrentStep}
          state={state}
          setState={setState}
        />
      </ConditionalRender>
    </div>
  );
};

export default withModal(UploadLead);
