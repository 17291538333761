import { createContext, useContext } from "react";

export interface IAuthContext {
  isAuthenticated?: boolean;
  isLoading: boolean;
  setIsLoading ?: any;
  setIsAuthenticated?: any;
  user: any;
  setUser?: any
}

export const DefaultAuthContext: IAuthContext = {
  isAuthenticated: false,
  isLoading: false,
  user: null,
};

export const AuthContext = createContext<IAuthContext>(DefaultAuthContext);
export const useAuthContext = () => useContext(AuthContext);