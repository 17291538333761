import { FunctionComponent } from "react";

type IEmptyEntityClasses = {
  childrenclass?: string;
  conatinerclass?: string;
};

export interface IEmptyEntity {
  name?: string;
  children?: React.ReactNode;
  classes?: Partial<IEmptyEntityClasses>;
}

const EmptyEntity: FunctionComponent<IEmptyEntity> = ({
  name,
  classes = { childrenclass: "" , conatinerclass: ''},
  children,
}) => {
  if (children) {
    return (
      <p
        className={`text-[14px] text-[#D0D5DD] font-inter-regular ${classes.childrenclass}`}
      >
        {children}
      </p>
    );
  }
  return (
    <div className={`text-center my-20 text-xl ${classes.conatinerclass}`}>
      <p className="font-inter-regular text-primaryTextColor">Oops!</p>
      <p className="font-inter-regular text-primaryTextColor">
        We couldn't find any {name}.
      </p>
      <p className="font-inter-regular text-primaryTextColor">
        Please check back later or add a new {name}.
      </p>
    </div>
  );
};

export default EmptyEntity;
