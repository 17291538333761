import { Link } from "react-router-dom";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { ISideNavLinks } from "lib/helpers/constants/sideNavLinks";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { hasChildrenWithPermissions } from "lib/hooks/shared/hasChildrenPermissions";

interface SideNavItemProps {
  item: ISideNavLinks;
  isActive: boolean;
  isToggled: boolean;
  showDropDown: string | null;
  handleDropdown: (event: React.MouseEvent, id: string) => void;
  handleActive: () => void;
  renderDropDownMenu: (item: ISideNavLinks) => JSX.Element | null;
  themeColors: {
    primary: string;
  };
}

const SideNavItem: React.FC<SideNavItemProps> = ({
  themeColors,
  item,
  isToggled,
  isActive,
  showDropDown,
  handleDropdown,
  handleActive,
  renderDropDownMenu
}) => {
  const childrenWithPermissions = hasChildrenWithPermissions(item.children);

  const hasPermission = usePermissions(item.permissions || []);

  if (!hasPermission) {
    return null;
  }

  return (
    <div
      key={`side-nav-key-${item.id}`}
      className={`flex flex-center flex-wrap ${
        isToggled ? "ps-1" : "ps-2"
      } transition-all duration-200 ease-in-out`}
    >
      <div className="flex-1 relative">
        <Link
          className={`flex items-center ${
            childrenWithPermissions
              ? `${isToggled ? "px-3 gap-none" : "px-3 gap-[16px]"} `
              : `px-3 ${isToggled ? " " : "gap-[16px]"}`
          } py-2 3xl:py-2.5 ${isActive && "rounded-xl"} `}
          style={
            isActive ? { backgroundColor: `${themeColors.primary}0D` } : {}
          }
          to={item.path}
        >
          <Icon
            iconType={item.icon as IconType}
            color={isActive ? themeColors.primary : ""}
            className={`flex-none ${isToggled && "w-[20px] h-[20px] mx-auto"}`}
          />
          <h1
            className={`text-[#7D8592] ${
              isToggled && "hidden"
            } font-inter-medium text-[14px] 3xl:text-[15px] ${
              isActive && "text-[--theme]"
            }`}
            onClick={handleActive}
          >
            {item.label}
          </h1>
          {childrenWithPermissions && (
            <p
              onClick={(event) => handleDropdown(event, item.id)}
              className={`ml-auto pr-2 py-2 absolute  ${
                isToggled ? "right-[-5px]" : "right-[8px]"
              }`}
            >
              {childrenWithPermissions &&
                (showDropDown === item.id ? (
                  <Icon
                    iconType={IconType.UP_ARROW_ICON}
                    color={isActive ? themeColors.primary : ""}
                    className={`${isToggled && "w-[10px] h-[10px]"}`}
                  />
                ) : (
                  <Icon
                    iconType={IconType.DOWN_ARROW_ICON}
                    color={isActive ? themeColors.primary : undefined}
                    className={`${isToggled && "w-[10px] h-[10px]"}`}
                  />
                ))}
            </p>
          )}
        </Link>
      </div>

      <div>
        <div
          className={`rounded-full w-[4px] h-full bg-[--theme] mr-[-5px] ${
            !isActive && "hidden"
          }`}
        ></div>
      </div>
      <div className="w-full">{renderDropDownMenu(item)}</div>
    </div>
  );
};

export default SideNavItem;
