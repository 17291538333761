import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  LegacyRef,
  forwardRef,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import { getMonth, getYear, format, parse } from "date-fns";
import Label from "../../atoms/input/input-label/InputLabel";
import Button from "components/atoms/button/Button";
import ErrorMessage from "components/atoms/error-message/ErrorMessage";
import { DeepMap, FieldError } from "react-hook-form";
import ReactDatePicker from "react-datepicker";

export interface IPrimaryDatePickerProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  startDate: any;
  setStartDate: any;
  className?: string;
  isDisabled?: boolean;
  wrapperClassName?: string;
  iconClassName?: string;
  error?: DeepMap<any, FieldError>;
}

const PrimaryDatePicker: ForwardRefRenderFunction<
  HTMLInputElement,
  IPrimaryDatePickerProps
> = (
  {
    label,
    startDate,
    setStartDate,
    isDisabled,
    className,
    wrapperClassName,
    iconClassName,
    error,
    ...rest
  },
  ref
) => {
  const formatDateForDisplay = (date: Date | null) => {
    if (!date) return '';
    return format(date, 'dd/MM/yyyy');
  };

  const formatDateForBackend = (date: Date | null) => {
    if (!date) return '';
    return format(date, 'yyyy-MM-dd');
  };

  const parseDateFn = (dateStr: string) => {
    return parse(dateStr, 'yyyy-MM-dd', new Date());
  };

  const years = Array.from({ length: 125 }, (_, i) => i + 1900);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="relative">
      {label && <Label text={label} />}
      <DatePicker
        disabled={isDisabled}
        className={`${className} datepicker`}
        popperClassName={`custom-class ${wrapperClassName}`}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 1.5L0.999999 6L5.5 10.5"
                  stroke="#282D46"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>

            <select
              className="font-inter-bold outline-0 datePickerSelect"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="font-inter-bold outline-0 datePickerSelect"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(parseInt(value))}
            >
              {years.map((option: any) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <Button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.5L6 6L1.5 10.5"
                  stroke="#282D46"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </div>
        )}
        showIcon
        disabledKeyboardNavigation
        calendarIconClassname={iconClassName}
        toggleCalendarOnIconClick
        icon={<Icon iconType={IconType.DATE_PICKER_ICON} />}
        onChange={(date) => setStartDate(formatDateForBackend(date))}
        onSelect={(date) => setStartDate(formatDateForBackend(date))}
        selected={startDate ? parseDateFn(startDate) : null}
        dateFormat="dd/MM/yyyy"
        placeholderText="Day / Month / Year"
        autoComplete="off"
        ref={ref as LegacyRef<ReactDatePicker<undefined>>}
      />
      <ErrorMessage error={error} />
    </div>
  );
};

export default forwardRef(PrimaryDatePicker);
