import React, { memo } from "react";

interface Props {
  className?: string;
  color?: string;
}
function CheckSignupIcon({ }: Props) {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3638 0.878458C13.7264 1.24109 13.7523 1.81293 13.4415 2.20545L13.3638 2.29267L6.29274 9.36374C5.93011 9.72637 5.35827 9.75227 4.96575 9.44145L4.87853 9.36374L0.635888 5.1211C0.245364 4.73057 0.245363 4.09741 0.635888 3.70689C0.998517 3.34426 1.57036 3.31835 1.96288 3.62918L2.0501 3.70689L5.58586 7.24156L11.9496 0.878458C12.3122 0.515829 12.8841 0.489927 13.2766 0.800752L13.3638 0.878458Z"
        fill="#50B8E7"
      />
    </svg>
  );
}

export default memo(CheckSignupIcon);
