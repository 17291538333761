import { updateSource } from "api/Sources";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdateSource = (id?: string,
    options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();
    return useMutation(updateSource, {
        onSuccess: () => {
            toast.success("Source updated successfully!");
            queryClient.invalidateQueries('sources')
        },
        onError: (err:any) => {
            if(err?.response?.data?.error == 'Unauthenticated'){
                toast.error('Unauthenticated')
            }else if(err?.response?.data?.message){
                toast.warning(err?.response?.data?.message)
            } else {
                toast.error("An unknown error occurred");
            }
        },
        ...options
    })
}