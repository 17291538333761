

import { FC, HTMLAttributes } from "react";

export interface IDashboardBoxTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    title: string;
}

const DashboardBoxTitle: FC<IDashboardBoxTitleProps> = ({ title, ...rest }) => {
    return (
        <div className={`font-inter-semibold color-primaryTextColor sm:text-[17px] md:text-[17px] lg:text-[24px] ${rest.className}`}>{title}</div>
    )
}

export default DashboardBoxTitle