import Headline from "components/atoms/headline/Headline";
import { FC, useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import CustomersList from "./customers-list/CustomersList";
import CreateCustomerProcces from "./create-customer/CreateCustomerProcces";
import { useCoustomers } from "lib/hooks/queries/Coustomer/useCoustomers";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import Button from "components/atoms/button/Button";
import CostumersCard from "./costumers-card/CostumersCard";
import CustomersGridView from "./customers-grid-view/CustomersGridView";
import FilterCostumers from "./dropdowns/FilterCustomers";
import UpdateCustomerProcess from "./update-customer-process/UpdateCustomerProcess";
import { useStatusStyle } from "lib/hooks/shared/getStatusColorCustomer";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import ExportCustomersProcess from "./export-customers/ExportCustomersProcess";
import UploadCustomerProcess from "./upload-customers-process/UploadCustomersProcess";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";

export interface ICustomersListProps {}

export interface IFilterProps {
  filter_search: string;
  filter_status: string;
  filter_product: string;
  filter_company: string;
  filter_commission: string;
  filter_mandated: string;
  filter_created_at: string;
}

const CustomersView: FC<ICustomersListProps> = () => {
  const [currentModalStep, setCurrentModalStep] = useState<number>(1);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [updateModalCurrentStep, setUpdateModalCurrentStep] =
    useState<number>(1);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const hasPermissions = usePermissions([
    P["Costumer Admin"],
    P["Costumer Menager"]
  ]);
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [ActiveView, setActiveView] = useState<string>("listView");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<Record<string, any>>();
  const { confirm } = useModalContext();
  const { themeColors } = useThemeContext();

  const [filterData, setFilterData] = useState<IFilterProps>({
    filter_search: "",
    filter_status: "",
    filter_product: "",
    filter_company: "",
    filter_commission: "",
    filter_mandated: "",
    filter_created_at: ""
  });
  const {
    currentPage,
    data,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage,
    hasNextPage,
    hasPreviousPage,
    isLoading
  } = useCoustomers(
    filterData.filter_search,
    filterData.filter_status,
    filterData.filter_product,
    filterData.filter_company,
    filterData.filter_commission,
    filterData.filter_mandated,
    filterData.filter_created_at
  );
  const statusStyles: Record<string, string> = {
    "Open (Consultant)": "bg-[#FFC107] bg-opacity-[10%] text-[#FFC107]",
    "Open (Office)": "bg-[#FFC107] bg-opacity-[10%] text-[#FFC107]",
    Submitted: "bg-[#9F9F9F] bg-opacity-[10%] text-[#9F9F9F]",
    Accepted: "bg-[#64C764] bg-opacity-[10%] text-[#64C764]",
    Provisioned: "bg-[#198754] bg-opacity-[10%] text-[#198754]",
    Rejected: "bg-[#DC3545] bg-opacity-[10%] text-[#DC3545]",
    Retreat: "bg-[#9E0707] bg-opacity-[10%] text-[#9E0707]",
    Canceled: "bg-[#E91D1D] bg-opacity-[10%] text-[#E91D1D]",
    Quality: "bg-[#50B8E7] bg-opacity-[10%] text-[#50B8E7]"
  };
  const handleCellClick = (row: any) => {
    setUpdateData(row.original);
    setIsUpdateModalOpen(true);
  };

  const columns = [
    {
      id: 1,
      Header: "ID",
      accessor: "id",
      isChecked: true,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row.original.id}
        </div>
      )
    },
    {
      id: 2,
      Header: "First Name",
      accessor: "first_name",
      isChecked: true,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row.original.first_name}
        </div>
      )
    },
    {
      id: 3,
      Header: "Last Name",
      accessor: "last_name",
      isChecked: true,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row.original.last_name}
        </div>
      )
    },
    {
      id: 4,
      Header: "Consultant",
      accessor: "from_user",
      isChecked: true,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row.original.from_user}
        </div>
      )
    },
    {
      id: 5,
      Header: "Mandated",
      accessor: "mandated",
      isChecked: true,
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row.original.mandated}
        </div>
      )
    },
    {
      id: 6,
      Header: "Company",
      accessor: "company",
      Cell: ({ row }: any) => (
        <div onClick={() => handleCellClick(row)} className="cursor-pointer">
          {row.original?.products?.[0]?.company_name ?? "N/A"}
        </div>
      ),
      isChecked: true
    },
    {
      id: 7,
      Header: "Products",
      accessor: "products",
      Cell: ({ row }: any) => {
        const products = row?.original?.products || [];
        const productCount = products.length - 1;

        return (
          <div
            className="relative cursor-pointer"
            onClick={() => handleCellClick(row)}
          >
            {productCount >= 1 && (
              <Button
                className={`circle absolute md:right-0 2xl:right-[35px] top-[-2px] flex items-center justify-center  bg-[--theme] text-white w-[23px] font-inter-bold h-[23px] rounded-full text-[13px]`}
                onClick={(e) => {
                  e.stopPropagation();
                  row.toggleRowExpanded();
                }}
              >
                +{productCount}
              </Button>
            )}
            {products[0]?.name ?? "No Products"}
          </div>
        );
      },
      SubCell: ({ row }: any) => {
        function findColumnById(id: number) {
          return columns.find((column) => column.id === id);
        }

        const ID = findColumnById(1);
        const FirstName = findColumnById(2);
        const LastName = findColumnById(3);
        const Consultant = findColumnById(4);
        const Mandated = findColumnById(5);
        const Company = findColumnById(6);
        const Products = findColumnById(7);
        const Provision = findColumnById(8);
        const Status = findColumnById(9);
        const Actions = findColumnById(10);

        const products = row?.original?.products || [];
        if (products.length === 0) {
          return <div>No Products</div>;
        }

        return (
          <>
            {products.map((product: any, index: number) => {
              if (index === 0) return null;

              return (
                <tr
                  style={
                    row.isExpanded
                      ? {
                          backgroundColor: `${themeColors.primary}0D`
                        }
                      : {}
                  }
                >
                  {ID?.isChecked && (
                    <td className="px-2 py-3 font-inter-medium text-primaryTextColor"></td>
                  )}
                  {FirstName?.isChecked && (
                    <td className="px-2 py-3 font-inter-medium text-primaryTextColor"></td>
                  )}
                  {LastName?.isChecked && (
                    <td className="px-2 py-3 font-inter-medium text-primaryTextColor"></td>
                  )}
                  {Consultant?.isChecked && (
                    <td className="px-2 py-3  font-inter-medium text-primaryTextColor"></td>
                  )}
                  {Mandated?.isChecked && (
                    <td className="px-2  py-3 font-inter-medium text-primaryTextColor"></td>
                  )}
                  {Company?.isChecked && (
                    <td className="px-2 py-3 font-inter-medium text-primaryTextColor">
                      {product.company_name}
                    </td>
                  )}
                  {Products?.isChecked && (
                    <td className="px-2 py-3  font-inter-medium text-primaryTextColor">
                      {product.name}
                    </td>
                  )}
                  {Provision?.isChecked && (
                    <td className="px-2 py-3 font-inter-medium text-primaryTextColor">
                      {product.commission || "Pending"}
                    </td>
                  )}
                  {Status?.isChecked && (
                    <td
                      className={`mx-2 mx-2 mt-3  font-inter-medium  capitalize min-w-[50px] w-[max-content] h-[24px] p-3  text-[12px] font-inter-medium flex items-center justify-center rounded-[4px] capitalize ${
                        statusStyles[product.status] ||
                        "bg-gray-100 text-gray-500"
                      }`}
                    >
                      {product.status || "open"}
                    </td>
                  )}
                  {Actions?.isChecked && (
                    <td className="px-2 py-3 font-inter-medium text-primaryTextColor"></td>
                  )}
                </tr>
              );
            })}
          </>
        );
      },
      isChecked: true
    },
    {
      id: 8,
      Header: "Provision",
      accessor: "provision",
      Cell: ({ row }: any) => (
        <div
          className={`${
            row.original?.products?.[0]?.commission ? "text-[#037241]" : ""
          } cursor-pointer`}
          onClick={() => handleCellClick(row)}
        >
          {row.original?.products?.[0]?.commission
            ? row.original?.products?.[0]?.commission + "CHF"
            : "Pending"}
        </div>
      ),
      isChecked: true
    },
    {
      id: 9,
      Header: "Status",
      accessor: "status",
      Cell: ({ row }: any) => {
        const status =
          row.original?.products?.[0]?.status ?? "Open (Consultant)";
        const statusStyle = useStatusStyle(status);
        const products = row?.original?.products || [];
        const productCount = products.length - 1;

        return (
          <div className="relative cursor-pointer">
            {productCount >= 1 && (
              <Button
                className={` absolute  right-[-20px] 2xl:right-[50px] top-[-2px] flex items-center justify-center text-[--theme] w-[23px] font-inter-bold h-[23px] rounded-full text-[13px]`}
                onClick={(e) => {
                  e.stopPropagation();
                  row.toggleRowExpanded();
                }}
              >
                +{productCount} 
              </Button>
            )}
            <p
              style={statusStyle}
              className="min-w-[50px] w-[max-content] h-[24px] p-3  text-[12px] font-inter-medium flex items-center justify-center rounded-[4px] capitalize cursor-pointer"
              onClick={() => handleCellClick(row)}
            >
              {status}
            </p>
          </div>
        );
      },
      isChecked: true
    },
    {
      id: 10,
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }: any) => {
        return (
          <Button
            onClick={() => {
              setIsUpdateModalOpen(true);
              setUpdateData(original);
            }}
          >
            <Icon iconType={IconType.OPTIONS_ICON} />
          </Button>
        );
      },
      isChecked: true
    }
  ];

  const [cardsOrder, setCardsOrder] = useState(columns);

  const openCreateModal = () => {
    setIsOpen(true);
  };

  const exportCustomers = async () => {
    try {
      await confirm({
        title: "",
        classes: {
          modal: "md:min-w-[650px] md-max-w-full lg:max-w-[650px] w-full"
        },
        showCloseButton: true,
        showBackButton: true,
        customComponent: (props) => {
          return (
            <ExportCustomersProcess
              onCancel={props.onCancel}
              onConfirm={props.onConfirm}
              themeColors={themeColors}
            />
          );
        }
      });
    } catch (err) {
      return null;
    }
  };

  const [isUploadModalOpen, setisUploadModalOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col items-center justify-between  pb-6 md:flex-row  md:pb-none">
        <div className="flex items-center justify-between ">
          <Headline title="Customers" />
        </div>
        <ConditionalRender condition={hasPermissions}>
          <IconButton
            onClick={exportCustomers}
            icon={<Icon color="#fff" iconType={IconType.EXPORT_ICON} />}
          >
            Export Customers
          </IconButton>
        </ConditionalRender>
      </div>
      <CostumersCard />
      <DashboardBoxLayout className="mt-[26px]">
        <div className="flex flex-col gap-3 items-center justify-between mb-5 md:flex-row ">
          <div className="flex-1">
            <DashboardBoxTitle
              title={`Customers (${
                data?.pages?.[0]?.data?.data?.length ?? ""
              })`}
            />
          </div>

          <IconButton
            onClick={() => setisUploadModalOpen(true)}
            icon={<Icon color="#fff" iconType={IconType.UPLOAD_FILE_ICON} />}
          >
            Upload Customers
          </IconButton>
          <IconButton
            icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
            onClick={openCreateModal}
          >
            New Customer
          </IconButton>
        </div>

        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder="Search"
              value={filterData?.filter_search ?? ""}
              onChange={(e) =>
                setFilterData((prevFormData: any) => ({
                  ...prevFormData,
                  filter_search: e.target.value
                }))
              }
              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex-none">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px] "
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                {configureTableOpen && (
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title="Configure Table"
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                )}
              </div>
            </div>
            <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title="Filter"
                    className="sm:!w-[350px]"
                  >
                    <FilterCostumers
                      setFilterData={setFilterData}
                      FilterData={filterData}
                      themeColors={themeColors}
                      setIsOpen={setFilterTableOpen}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <ViewChangeButtonGroup
                setSelectedTab={setActiveView}
                ActiveTab={ActiveView}
              />
            </div>
          </div>
        </div>
        <ConditionalRender condition={ActiveView === "kundenView"}>
          <NoDataBoundary
            condition={data?.pages?.[0]?.data?.data?.length > 0}
            fallback={<EmptyEntity name="Customers" />}
          >
            <CustomersGridView
              data={data?.pages?.[0]?.data?.data ?? []}
              setIsUpdateModalOpen={setIsUpdateModalOpen}
            />
          </NoDataBoundary>
        </ConditionalRender>
        <ConditionalRender condition={ActiveView !== "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Customers" />}
            >
              <CustomersList
                key={currentPage}
                data={data?.pages[0]?.data?.data ?? []}
                dataCols={cardsOrder}
              />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>
      <ConditionalRender condition={!!data && ActiveView !== "kundenView"}>
        <PaginationControls
          itemsPerPage={itemsPerPage}
          handleItemsPerPageChange={(value: number) =>
            handleItemsPerPageChange(value)
          }
          from={data?.pages[0]?.data?.from}
          to={data?.pages[0]?.data?.to}
          total={data?.pages[0]?.data?.total}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </ConditionalRender>
      {/* Modal Part */}
      <ConditionalRender condition={isOpen}>
        <CreateCustomerProcces
          themeColors={themeColors}
          hocTitle={
            currentModalStep === 1
              ? "New Customer"
              : currentModalStep === 2
              ? "Add New Product"
              : currentModalStep === 3
              ? "Add New Relation"
              : ""
          }
          setHocCurrentStep={setCurrentModalStep}
          hocCurrentStep={currentModalStep}
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${
              currentModalStep === 1
                ? "md:min-w-[750px] lg:min-w-[1000px] xl:min-w-[1200px] 2xl:min-w-[1200px]"
                : currentModalStep === 2
                ? "!w-[476px]"
                : currentModalStep === 3
                ? "sm:max-w-[430px] md:min-w-[430px] lg:min-w-[430px] xl:min-w-[430px] 2xl:min-w-[430px]"
                : ""
            }`}`
          }}
          hocToggleModal={() => setIsOpen(false)}
          hocisOpen={isOpen}
          setCurrentFormStep={setCurrentModalStep}
          currentStep={currentModalStep}
          onConfirm={() => setIsOpen(false)}
        />
      </ConditionalRender>
      <ConditionalRender condition={isUpdateModalOpen}>
        <UpdateCustomerProcess
          setIsModalOpen={setIsUpdateModalOpen}
          themeColors={themeColors}
          hocTitle={`
            ${
              updateModalCurrentStep === 1
                ? `${updateData?.first_name} ${updateData?.last_name}`
                : updateModalCurrentStep === 2
                ? `Add New Product`
                : updateModalCurrentStep === 3
                ? `Upload file`
                : updateModalCurrentStep === 4
                ? ""
                : updateModalCurrentStep === 8
                ? "Add New Relation"
                : ""
            }
            `}
          data={updateData}
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${
              updateModalCurrentStep === 1
                ? "md:min-w-[750px] lg:min-w-[1000px] xl:min-w-[1200px] 2xl:min-w-[1200px]"
                : updateModalCurrentStep === 2
                ? "!w-[476px]"
                : updateModalCurrentStep === 3
                ? "sm:max-w-[467px]  md:min-w-[467px] lg:w-[467px]"
                : updateModalCurrentStep === 4
                ? "md:min-w-[750px]  lg:w-[973px]"
                : updateModalCurrentStep === 5
                ? "md:min-w-[750px] lg:w-[973px]"
                : updateModalCurrentStep === 6
                ? "md:min-w-[676px] md:w-[676px] lg:w-[676px] xl:w-[676px] 2xl:min-w-[676px]"
                : updateModalCurrentStep === 7
                ? "md:min-w-[676px] md:w-[676px] lg:w-[676px] xl:w-[676px] 2xl:min-w-[676px]"
                : updateModalCurrentStep === 8
                ? "sm:max-w-[430px] md:min-w-[430px] lg:min-w-[430px] xl:min-w-[430px] 2xl:min-w-[430px]"
                : ""
            }`}`
          }}
          hocToggleModal={() => setIsUpdateModalOpen(false)}
          onConfirm={() => setIsUpdateModalOpen(false)}
          hocisOpen={isUpdateModalOpen}
          setHocCurrentStep={setUpdateModalCurrentStep}
          hocCurrentStep={updateModalCurrentStep}
          setCurrentFormStep={setUpdateModalCurrentStep}
          currentStep={updateModalCurrentStep}
        />
      </ConditionalRender>
      <ConditionalRender condition={isUploadModalOpen}>
        <UploadCustomerProcess
          clearData={true}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          hocClasses={{
            modalClassName:
              currentStep === 1
                ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                : currentStep === 2
                ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
                : currentStep === 4
                ? "w-full md:max-w-[700px]  lg:min-w-[762px] max-w-[762px]"
                : "w-full md:md:max-w-[700px] lg:min-w-[1008px] max-w-[1008px]",
            titleClassName: currentStep === 1 ? "" : "ml-[35px]"
          }}
          onConfirm={() => {
            setisUploadModalOpen(false);
          }}
          hocTitle="Upload Customers"
          themeColors={themeColors}
          hocCurrentStep={currentStep}
          hocToggleModal={
            isUploadModalOpen
              ? () => {
                  setisUploadModalOpen(false);
                }
              : () => {
                  setisUploadModalOpen(true);
                }
          }
          hocisOpen={isUploadModalOpen}
          setHocCurrentStep={setCurrentStep}
          hocHasChanges={false}
        />
      </ConditionalRender>
    </>
  );
};

export default CustomersView;
