import React, { FC } from "react";
import { IModulesResponse } from "../../../../lib/types/permission.module.types";

export interface IModuleData {
  id: string;
  name: string;
  price: string;
  description: string;
}
export interface ICurrentPlanSummaryCardProps {
  selectedModuleIds?: string[];
  data?: IModulesResponse;
}
const CurrentPlanSummaryCard: FC<ICurrentPlanSummaryCardProps> = ({
  selectedModuleIds,
  data,
}) => {
  if (!data || !selectedModuleIds) {
    return null;
  }
  // Calculate the total price of selected modules
  const totalPrice = selectedModuleIds.reduce((acc, moduleId) => {
    const selectedModule = data.modules.find(
      (module) => module.id === moduleId
    );
    const price = selectedModule ? parseFloat(selectedModule.price) : 0;
    return acc + price;
  }, 0);
  return (
    <div className="h-fit max-h-auto rounded-[12px] border-[1px] border-[#DCDCDC]">
      <div className="bg-[#50B8E7] p-[30px] rounded-tl-[12px] rounded-tr-[12px]">
        <h1 className="font-inter-bold text-white text-[21px]">
          Current Plan Summary
        </h1>
        <p className="text-white text-[14px] mt-[3px]">
          Payment Per User Monthly
        </p>
        <div className="flex mt-[10px]">
          <p className="text-white text-[16px] font-inter-regular">CHF</p>
          <p className="font-inter-bold text-white text-[34px]">
            {totalPrice}
            <span className="text-[13px] font-inter-regular">
              / {selectedModuleIds.length} modules selected
            </span>
          </p>
        </div>
      </div>
      <div className="p-[30px] pt-[16px] pb-[25px]">
        <details open>
          <summary className="flex !m-0 text-[16px] font-inter-semibold text-primaryTextColor">
            Modules ({selectedModuleIds.length})
          </summary>
          {selectedModuleIds.length > 0 ? (
            selectedModuleIds.map((item) => {
              const selectedModule = data.modules.find(
                (module) => module.id === item
              );
              return (
                <div
                  key={`selected-module-key-${item}`}
                  className="flex items-center justify-between my-[10px]"
                >
                  <span className="text-[14px] text-primaryTextColor font-inter-regular">
                    {selectedModule?.name}
                  </span>
                  <span className="text-[14px] text-primaryTextColor font-inter-semibold">
                    {selectedModule?.price}CHF
                    <span className="font-inter-regular">/users</span>
                  </span>
                </div>
              );
            })
          ) : (
            <span className="text-[14px] text-primaryTextColor">
              You still didn’t choose any module yet.
            </span>
          )}
        </details>
        {selectedModuleIds.length > 0 && (
          <div className="flex items-center justify-between  pt-[16px] border-t-[1px] border-[#DCDCDC]">
            <h1 className="font-inter-semibold text-primaryTextColor">
              Total Per User
            </h1>
            <div className="font-inter-semibold text-primaryTextColor">
              {totalPrice}
              CHF<span className="font-inter-regular">/users</span>
            </div>
          </div>
        )}
        {/* <div className="flex items-center gap-2 mt-[48px]">
          <Input type="checkbox" />
          <p className="text-[14px] text-primaryTextColor font-inter-regular">
            I accept all <span className="underline">Terms & Conditions</span>
          </p>
        </div>
        <Button
          type="submit"
          className="text-[#50B8E7] border-[1px] border-[#DCDCDC] w-full rounded-[8px] h-[48px] mt-[20px]"
        >
          Continue to checkout
        </Button> */}
      </div>
    </div>
  );
};
export default CurrentPlanSummaryCard;

//need's refactor
