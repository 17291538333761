import { DeleteSourceById } from "api/Sources";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useDeleteSource = () => {
    const queryClient = useQueryClient();
    return useMutation(DeleteSourceById, {
        onSuccess: () => {
            queryClient.invalidateQueries('sources');
            toast.success("Source Deleted Successfully");
        },
        onError: (err:any) => {
            if(err?.response?.data?.error == 'Unauthenticated'){
                toast.error('Unauthenticated')
            }else if(err?.response?.data?.message){
                toast.warning(err?.response?.data?.message)
            } else {
                toast.error("An unknown error occurred");
            }
        }
    });
};
