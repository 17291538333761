import React, { Suspense, useRef } from "react";
import ErrorBoundary from "./components/atoms/error-boundary/ErrorBoundary";
import { useAuthContext } from "./lib/context/AuthContextContext/AuthContext";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes/AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./routes/UnauthenticatedRoutes/UnauthenticatedRoutes";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";

function App() {
  const { isAuthenticated, isLoading } = useAuthContext();

  if (isLoading) {
    return <LoadingScreen />;
  }

 
  return (
    <ErrorBoundary >
      <Suspense fallback={<LoadingScreen />}>
        {isAuthenticated ? <AuthenticatedRoutes /> : <UnAuthenticatedRoutes />}
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
