import Button from "components/atoms/button/Button";
import { useRequestFeedBackForExport } from "lib/hooks/mutations/Leads/useRequestFeedBackForExport";
import { FC } from "react";

export interface ISecurityCardProps {
  type: string;
  created_at: any;
  image: string;
  from_user: string;
  id: string;
}

const SecurityCard: FC<ISecurityCardProps> = ({
  created_at,
  from_user,
  image,
  type,
  id,
}) => {
  const { mutate: requestForFeedback } = useRequestFeedBackForExport();

  const date = new Date(created_at);
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

  const formattedTime = `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;

  return (
    <div className="flex flex-col gap-[13px] border-[#F2F4F7] border-[1px] p-[16px] rounded-[20px]">
      <h1 className="text-primaryTextColor text-[14px] font-inter-medium capitalize  overflow-hidden overflow-ellipsis whitespace-nowrap w-[700px]">
        Asked for permission to <b>{type ?? "Not Available"}</b> Leads
      </h1>
      <p className="text-[#707683]  font-inter-regular text-[11px] flex items-center gap-[7px]">
        <span className="opacity-50">Date:</span>
        <span className="text-[#707683]">
          {formattedDate ?? "Not Available"} at{" "}
          {formattedTime ?? "Not Available"}
        </span>
      </p>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[9px]">
          <img
            src="https://t3.ftcdn.net/jpg/02/22/85/16/360_F_222851624_jfoMGbJxwRi5AWGdPgXKSABMnzCQo9RN.jpg"
            alt="security"
            className="w-10 h-10 object-cover rounded-full"
          />
          <p className="text-[#707683]  font-inter-regular text-[13px]">
            {from_user ?? "Not Available"}
          </p>
        </div>
        <div className="flex items-center gap-[13px]">
          <Button
            onClick={() =>
              requestForFeedback({
                request_id: id,
                feedback: "decline",
              })
            }
            className="text-[#979797] p-3 bg-[#979797] bg-opacity-10 min-w-[100px] font-inter-medium rounded-[8px] text-[11px]"
          >
            Decline request
          </Button>
          <Button
            onClick={() =>
              requestForFeedback({
                request_id: id,
                feedback: "accept",
              })
            }
            className="text-white p-3 bg-[#66BB6A]  min-w-[100px] font-inter-medium text-[11px] rounded-[8px]"
          >
            Accept request
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SecurityCard;
