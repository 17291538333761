import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import React, { FC } from "react";

export interface IPartnersGridCardProps {
  logo: string;
  name: string;
  email: string;
  phone: string;
  toggleRender: () => JSX.Element
}

const PartnersGridCard: FC<IPartnersGridCardProps> = ({
  email,
  logo,
  name,
  phone,
  toggleRender
}) => {
  const { themeColors } = useThemeContext();

  return (
    <div className="w-[242px] border-[1px] rounded-[10px] border-[#F2F4F7] p-[8px]">
      <div
        style={{
          backgroundColor: `${themeColors.primary}0D`
        }}
        className="p-2 relative rounded-[20px] h-[186px] flex flex-col items-center justify-center relative"
      >
        <img
          src={`https://mainhub.insusales.com/api/partners/logo/${logo}`}
          className="w-[50px] h-[50px] object-cover rounded-full border-[--theme] border-[1px]"
          alt="img"
        />
        {toggleRender()}
        <h1 className="font-inter-medium text-[#282D46] mt-[14px]">{name ?? 'Not available'}</h1>
        <p className="text-[14px] text-[#282D46] mt-[7px] font-inter-medium">
          {email ?? 'Not available'}
        </p>
        <p className="text-[14px] text-[#282D46] mt-[7px] font-inter-medium">
          {phone ?? 'Not available'}
        </p>
      </div>
      <div className="flex items-center gap-[25px] justify-center text-center mx-auto pt-[35px] pb-[25px]">
        <div>
          <h1 className="text-[#282D46] text-[26px] font-inter-bold">325</h1>
          <p className="text-[#7D8592] font-inter-regular">Leads</p>
        </div>
        <div>
          <h1 className="text-[#282D46] text-[26px] font-inter-bold">654</h1>
          <p className="text-[#7D8592] font-inter-regular">Appointments</p>
        </div>
      </div>
    </div>
  );
};

export default PartnersGridCard;
