import { FC, LabelHTMLAttributes } from "react";

export interface ILabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text?: string;
  htmlFor?: string;
}

const Label: FC<ILabelProps> = ({ text, htmlFor, ...rest }) => {
  return (
    <label
      htmlFor={htmlFor}
      {...rest}
      className={`block text-[#282D46] text-[14px] mb-[8px] font-inter-medium ${rest.className}`}
    >
      {text} {rest.children}
    </label>
  );
};

export default Label;