import { FunctionComponent } from "react";
import Button from "../../atoms/button/Button";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";

interface IDynamicDropdown {
    children: React.ReactElement
    title?: string,
    showCloseButton?: boolean,
    setIsOpen: (args1: boolean) => void
    className?: string
    containerClassName?: string;
}

const DynamicDropdown: FunctionComponent<IDynamicDropdown> = ({ title, showCloseButton, setIsOpen, children, className, containerClassName }) => {

    return (
        <div className={`absolute top-20 right-0 lg:top-15 z-[9] ${containerClassName}`}>
            <div className="fixed w-screen h-screen top-0 left-0 z-9" onClick={() => { setIsOpen(false) }}>
            </div>
            <div
                className={`bg-white  rounded-[24px] text-left overflow-hidden mb-5 shadow-xl transform transition-all align-middle  min-w-[230px] sm:w-full ${className}`}
            >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 md:p-[25px] verticalOverflowScrollStyle">
                    {
                        (title || showCloseButton) && (
                            <div className="flex gap-3 justify-between items-center pb-5">

                                {title && (
                                    <h3
                                        className="text-base sm:text-sm font-inter-bold  text-[#282D46] capitalize"

                                    >
                                        {title}
                                    </h3>
                                )}

                                {(showCloseButton) &&
                                    <Button type="button" className={`flex justify-center items-center bg-[#F4F9FD] w-[44px] h-[44px] rounded-[14px]`}
                                        onClick={() => { setIsOpen(false) }}><Icon iconType={IconType.CLOSE_ICON} />
                                    </Button>
                                }
                            </div>
                        )
                    }

                    <div className="max-h-[calc(100vh_-_13rem)] verticalOverflowScrollStyle">
                        {children}
                    </div>
                </div>

            </div>
        </div>
    );
};
export default DynamicDropdown