import Button from "components/atoms/button/Button";
import CustomSelect from "components/atoms/custom-select/CustomSelect";
import Icon from "components/atoms/icon/Icon";
import { useSidebarContext } from "lib/context/SidebarContext/SidebarContext";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";

interface IPaginationControlsProps {
  from: number;
  to: number;
  total: number;
  hasNextPage: boolean | undefined;
  hasPreviousPage: boolean | undefined;
  handleNextPage: (hasNextPage: boolean) => void;
  handlePreviousPage: () => void;
  itemsPerPage: number;
  handleItemsPerPageChange: (value: number) => void;
}

const PaginationControls: FC<IPaginationControlsProps> = ({
  from,
  to,
  total,
  hasNextPage,
  hasPreviousPage,
  handleNextPage,
  handlePreviousPage,
  itemsPerPage,
  handleItemsPerPageChange,
}) => {
  const { themeColors } = useThemeContext();
  const { isToggled } = useSidebarContext();
  return (
    <div
      className={`flex flex-col items-center justify-end mt-[20px] gap-[10px] pr-[5px] lg:gap-[23px] md:flex-row ${
        isToggled ? "md:w-[calc(100vw-_330px)]" : "w-full"
      }`}
    >
      <div className="flex items-center justify-end gap-[10px] lg:gap-[23px]">
        <div className="flex items-center gap-[10px] lg:gap-[23px]">
          <h1 className="text-primaryTextColor font-inter-regular">
            Show per page:
          </h1>
          <CustomSelect
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
            className="!border-[#F2F4F7] !w-[60px] !indent-0	text-center"
            placeholder={""}
            customOptions={[
              { value: 10, label: "10" },
              { value: 50, label: "50" },
              { value: 100, label: "100" },
              { value: 200, label: "200" },
              { value: 500, label: "500" },
            ]}
          />
        </div>
      </div>
      <div className="bg-white border-[1px] border-[#F2F4F7] rounded-[8px] w-full flex max-w-[255px] items-center justify-center gap-[10px]  h-[40px] 2xl:h-[48px]">
        <h1 className="text-primaryTextColor font-inter-medium text-[14px]">
          Showing {from}-{to} of {total}
        </h1>
        <Button
          onClick={handlePreviousPage}
          className={
            hasPreviousPage ? "cursor-pointer" : "disabled cursor-not-allowed"
          }
        >
          <Icon
            color={hasPreviousPage ? themeColors.primary : "#C9CCD1"}
            iconType={IconType.LEFT_ARROW_ICON}
          />
        </Button>
        <Button
          onClick={() => handleNextPage(!!hasNextPage)}
          className={
            hasNextPage ? "cursor-pointer" : "disabled cursor-not-allowed"
          }
        >
          <Icon
            iconType={IconType.RIGHT_ARROW_ICON}
            color={hasNextPage ? themeColors.primary : "#C9CCD1"}
          />
        </Button>
      </div>
    </div>
  );
};

export default PaginationControls;
