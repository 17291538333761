import { useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { getLeads } from "api/Leads";
import { ILead, PaginationParams } from "lib/types/leads.types";
import { usePagination } from "lib/hooks/shared/usePagination";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { usePermissions } from "lib/hooks/shared/usePermissions";


export const useLeads = (isAssignLeadsActive?: boolean, search?: string, source?: number, campaign?: number, status?: string, date_of_creation?: string) => {
 const hasAdminPermissions = usePermissions([P["Leads Admin"]]);
  const hasManagerPermissions = usePermissions([P["Leads Menager"]]);
  
  const {
    currentPage,
    handleItemsPerPageChange,
    handleNextPage, 
    handlePreviousPage,
    itemsPerPage
  } = usePagination(1, 10);


  const { data, isLoading, hasNextPage, hasPreviousPage } = useInfiniteQuery<Array<ILead> | any>(
    ["leads", currentPage, itemsPerPage, search, source, campaign, status, date_of_creation, isAssignLeadsActive],
    ({ pageParam = currentPage }) => {
      return getLeads({ page: pageParam, number_per_page: itemsPerPage, search: search, source: source, campaign: campaign, status: status, date_of_creation: date_of_creation,   owne_leads: hasAdminPermissions || hasManagerPermissions ? isAssignLeadsActive : false
      } as PaginationParams);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data?.next_page_url
          ? new URL(lastPage.data.next_page_url).searchParams.get("page")
          : false;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPage = allPages[allPages.length - 1];
        const prevPageUrl = currentPage?.data?.prev_page_url;
        return prevPageUrl
          ? new URL(prevPageUrl).searchParams.get("page")
          : false;
      },
      onError: () => {
        toast.error(`Something went wrong`);
      }
    }
  );

  return {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    itemsPerPage,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPageChange
  };
};
