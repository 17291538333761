import { createCampaign } from "api/Campaign";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import { useMutation } from "react-query";

export const useCreateCampaign = () => {
  const { updateFormData } = useMultiStepFormContext();
  return useMutation(createCampaign, {
    onSuccess: (res) => {
      updateFormData(res);
      
    },
    onError: (error) => {
      console.error(error);
    }
  });
};
