import React, { FC } from 'react'
import Icon from '../../atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { boolean } from 'yup';


export interface IUploadedFileProps {
    title: string;
    icon: IconType;
    size: string;
    updatedate?: string;
    link?: string;
    isEditable?: boolean;
    dropdown?: React.ReactElement;
    linkName?: string;
    deleteIcon?: React.ReactElement;
}


const UploadedFile: FC<IUploadedFileProps> = ({
    title,
    icon,
    size,
    updatedate,
    link,
    isEditable = true,
    dropdown,
    deleteIcon,
    linkName
}) => {


    const openPreviewFile = () => {
        window.open(
            `https://mainhub.insusales.com/api/${linkName}/document/${link}`
        )
    }

    const [IsDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false)
    return (
        <div className='bg-white border borber-border px-4 lg:px-6 py-4 rounded-[8px]'>
            <div className="flex flex-row gap-5 lg:gap-10 items-center flex-wrap justify-between">
                <div className="flex-none">
                    {link ?
                        <div onClick={openPreviewFile} className='cursor-pointer'>
                            <Icon iconType={icon} color="#50B8E7" />
                        </div>
                        :
                        <div>
                            <Icon iconType={icon} color="#50B8E7" />
                        </div>
                    }

                </div>
                <div className="flex-1">

                    {link ?
                        <div onClick={openPreviewFile} className='cursor-pointer'>
                            <div className='font-inter-medium text-sm text-primaryTextColor'>

                                <span>{title}</span>
                            </div>
                        </div>
                        :
                        <div className='font-inter-medium text-sm text-primaryTextColor'>

                            <span>{title}</span>
                        </div>
                    }
                    <div className='text-sm font-inter-regular text-secondaryTextColor'>
                        <span>{size}</span>
                    </div>
                </div>
                {
                    updatedate && (
                        <div className="flex-none">
                            <span className='text-xs font-inter-medium text-secondaryTextColor'>Created at {updatedate}</span>
                        </div>
                    )
                }
                {
                    (isEditable && deleteIcon) && (
                        <div className="flex-none relative">
                              {deleteIcon}             
                        </div>
                    )
                }

                {/* {
                    (isEditable && dropdown) && (
                        <div className="flex-none relative">
                            <button type='button' className='' onClick={() => { setIsDropdownOpen(true) }}>
                                <Icon iconType={IconType.TOOLTIP_THREE_DOTS} className='!w[5px] !h-[15px]' color='#6C737F' />
                            </button>
                            <ConditionalRender condition={IsDropdownOpen}>
                                <DynamicDropdown setIsOpen={setIsDropdownOpen} showCloseButton title='Delete File'>
                                    {dropdown}
                                </DynamicDropdown>
                            </ConditionalRender>
                        </div>
                    )
                } */}

            </div>
        </div >
    )
}

export default UploadedFile