import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Card from "components/molecules/card/Card";
import { ICustomerGridCard } from "components/molecules/card/customer-grid-card/CustomerGridCard";
import { CardType } from "lib/helpers/constants/cardTypes";
import { Dispatch, FC, SetStateAction, useState } from "react";
import UpdateCustomerProcess from "../update-customer-process/UpdateCustomerProcess";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import Button from "components/atoms/button/Button";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";

export interface ICustomersGridViewProps<T> {
  data: T[];
  setIsUpdateModalOpen: Dispatch<SetStateAction<boolean>>;
}

const CustomersGridView: FC<ICustomersGridViewProps<any>> = ({
  data,
  setIsUpdateModalOpen
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<Record<string, any>>();
  const [currentStep, setCurrentFormStep] = useState<number>(1);
  const { themeColors } = useThemeContext();

  return (
    <>
      <div className="grid  md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2  2xl:grid-cols-3 gap-4">
        {data.map((item) => {
          return (
            <div>
              <Card<ICustomerGridCard<string[]>>
                key={`${item.id}`}
                {...item}
                cardType={CardType.CUSTOMER_GRID_CARD}
                props={{
                  data: item,
                  setUpdateData: setUpdateData,
                  setIsModalOpen: setIsUpdateModalOpen,
                  renderButton: () => (
                    <Button
                      onClick={() => {
                        setUpdateData(item);
                        setIsModalOpen(true);
                      }}
                    >
                      <Icon iconType={IconType.OPTIONS_ICON} />
                    </Button>
                  )
                }}
              />
            </div>
          );
        })}
      </div>
      <ConditionalRender condition={isModalOpen}>
        <UpdateCustomerProcess
          setIsModalOpen={setIsModalOpen}
          themeColors={themeColors}
          hocTitle={`
            ${
              currentStep === 1
                ? `${updateData?.first_name} ${updateData?.last_name}`
                : currentStep === 2
                ? `Add New Product`
                : currentStep === 3
                ? `Upload file`
                : currentStep === 4
                ? "Application"
                : currentStep === 8
                ? "Add New Relation"
                : ""
            }
            `}
          data={updateData ?? ""}
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${
              currentStep === 1
                ? "md:min-w-[750px] lg:min-w-[1000px] xl:min-w-[1200px] 2xl:min-w-[1200px]"
                : currentStep === 2
                ? "sm:max-w-[467px]  md:min-w-[467px] lg:w-[467px]"
                : currentStep === 3
                ? "sm:max-w-[467px]  md:min-w-[467px] lg:w-[467px]"
                : currentStep === 4
                ? "md:min-w-[750px]  lg:w-[973px]"
                : currentStep === 5
                ? "md:min-w-[750px] lg:w-[973px]"
                : currentStep === 6
                ? "md:min-w-[676px] md:w-[676px] lg:w-[676px] xl:w-[676px] 2xl:min-w-[676px]"
                : currentStep === 7
                ? "md:min-w-[676px] md:w-[676px] lg:w-[676px] xl:w-[676px] 2xl:min-w-[676px]"
                : currentStep === 8
                ? "sm:max-w-[430px] md:min-w-[430px] lg:min-w-[430px] xl:min-w-[430px] 2xl:min-w-[430px]"
                : ""
            }`}`
          }}
          hocToggleModal={() => setIsModalOpen(false)}
          onConfirm={() => setIsModalOpen(false)}
          hocisOpen={isModalOpen}
          setHocCurrentStep={setCurrentFormStep}
          hocCurrentStep={currentStep}
          setCurrentFormStep={setCurrentFormStep}
          currentStep={currentStep}
        />
      </ConditionalRender>
    </>
  );
};

export default CustomersGridView;
