import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import Icon from "components/atoms/icon/Icon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";

export interface IActivityLogItemProps<T> {
  data?: T;
  isLoading?: boolean;
  showReview: boolean;
  href?: string;
}

const ActivityLogItem: FC<IActivityLogItemProps<any>> = ({
  data,
  isLoading,
  showReview,
  href,
}) => {
  if (isLoading) return <LoadingScreen />;

  if (!data || !data.length)
    return (
      <EmptyEntity
        name="Logs"
        classes={{ conatinerclass: "!ml-auto !mr-auto" }}
      />
    );

  return (
    <div className="border-l-2 border-dashed border-l-border ms-5 lg:ms-0">
      <div className="flex flex-col gap-4 md:gap-8 ">
        {data?.map?.((log: any, index: any) => {
          if (!log || !log.updated_at) {
            return null;
          }
          return (
            <div key={index} className="pb-1">
              {/* show the date for the latest change */}
              {index === 0 && (
                <div
                  style={{ width: "fit-content" }}
                  className="ml-[-20px] mb-5 pb-1 pt-2 px-2 bg-[#F2F4F7] rounded-[16px] font-inter-medium text-xs text-primaryTextColor"
                >
                  {log?.updated_at?.substring(0, 10)}
                </div>
              )}

              {/* show the next date that is different from the first date */}
              {index > 0 &&
                log.updated_at.substring(0, 10) !==
                  data[index - 1].updated_at.substring(0, 10) && (
                  <div
                    style={{ width: "fit-content" }}
                    className="ml-[-20px] mb-5 pb-1 pt-2 px-2 bg-[#F2F4F7] rounded-[16px] font-inter-medium text-xs text-primaryTextColor"
                  >
                    {log.updated_at.substring(0, 10)}
                  </div>
                )}

              <ConditionalRender
                condition={log.type === "Document Deleted" && log?.changes}
              >
                <div className="flex ml-[-8px] items-center">
                  <div
                    className={`size-[14px] border border-[--theme] rounded-full bg-white flex-none`}
                  />
                  <div className="w-[5px] md:w-[40px] h-[1px] border-t-2 border-dashed border-t-border flex-none" />
                  <div
                    className="bg-white rounded-[8px] border border-border p-4"
                    style={{ boxShadow: "0px 1px 4.6px 0px #0000001F" }}
                  >
                    <div className="flex flex-row gap-2 md:gap-4 items-center">
                      <div className="bg-border size-[30px] md:size-[50px] rounded-full flex-none " />
                      <div className="text-primaryTextColor text-sm font-inter-regular ">
                        <span className="font-inter-semibold ">
                          {log.edited_from}{" "}
                        </span>
                        {log?.description}
                        <span className="font-inter-semibold text-[--theme]">
                          {" "}
                          {log?.changes && log?.changes.split("%")[0]}
                        </span>
                      </div>
                      <div>
                        <span className="text-secondaryTextColor font-inter-medium text-sm">
                          {log.updated_at.substring(11, 16)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ConditionalRender>
              <ConditionalRender
                condition={log?.type === "Document Added" && log?.changes}
              >
                <div className="flex ml-[-8px] items-center">
                  <div
                    className={`size-[14px] border border-[--theme] rounded-full bg-white flex-none `}
                  />
                  <div className="w-[5px] md:w-[40px] h-[1px] border-t-2 border-dashed border-t-border flex-none" />
                  <div
                    className="bg-white rounded-[8px] border border-border p-4"
                    style={{ boxShadow: "0px 1px 4.6px 0px #0000001F" }}
                  >
                    <div className="flex flex-row gap-2 md:gap-4 items-center">
                      <div className="bg-border size-[30px] md:size-[50px] rounded-full flex-none " />
                      <div
                        className={`text-primaryTextColor text-sm font-inter-regular`}
                      >
                        <span className="font-inter-semibold ">
                          {log.edited_from}{" "}
                        </span>
                        {log.description}{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://mainhub.insusales.com/api/${href}/document/${
                            log?.changes && log?.changes.split("%")[1]
                          }`}
                          className={`font-inter-semibold text-[--theme]`}
                        >
                          {log?.changes && log?.changes.split("%")[0]}
                        </a>
                      </div>
                      <div>
                        <span className="text-secondaryTextColor font-inter-medium text-sm">
                          {log.updated_at.substring(11, 16)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ConditionalRender>

              <ConditionalRender
                condition={
                  log?.type === "Lead Updated" ||
                  log.type === "Appointment Updated" ||
                  log.type === "Costumer Updated" ||
                  log.type === "Product Updated" ||
                  log.type === "Appointment Switched" ||
                  log.type === "Relation Updated" ||
                  log.type === "Extra Field"
                }
              >
                <div className="flex ml-[-8px] items-center">
                  <div
                    className={`size-[14px] border border-[--theme] rounded-full bg-white flex-none `}
                  />
                  <div className="w-[5px] md:w-[40px] h-[1px] border-t-2 border-dashed border-t-border flex-none" />
                  <div
                    className="bg-white rounded-[8px] border border-border p-4"
                    style={{ boxShadow: "0px 1px 4.6px 0px #0000001F" }}
                  >
                    <div className="flex flex-row gap-2 md:gap-4 items-center">
                      <div className="bg-border size-[30px] md:size-[50px] rounded-full flex-none " />
                      <div className="text-primaryTextColor text-sm font-inter-regular ">
                        <span className="font-inter-semibold ">
                          {log.edited_from}{" "}
                        </span>
                        {log.description}
                        <span className="font-inter-semibold break-all">
                          {" "}
                          {log?.changes && log?.changes.split("to")[0]}{" "}
                        </span>
                        to
                        <span className="font-inter-semibold break-all">
                          {" "}
                          {log?.changes && log?.changes.split("to")[1]}
                        </span>
                      </div>
                      <div>
                        <span className="text-secondaryTextColor font-inter-medium text-sm">
                          {log.updated_at.substring(11, 16)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ConditionalRender>

              <ConditionalRender
                condition={log?.type === "Product Of Costumer" || log.type === "Address Added"}
              >
                <div className="flex ml-[-8px] items-center">
                  <div
                    className={`size-[14px] border border-[--theme] rounded-full bg-white flex-none `}
                  />
                  <div className="w-[5px] md:w-[40px] h-[1px] border-t-2 border-dashed border-t-border flex-none" />
                  <div
                    className="bg-white rounded-[8px] border border-border p-4"
                    style={{ boxShadow: "0px 1px 4.6px 0px #0000001F" }}
                  >
                    <div className="flex flex-row gap-2 md:gap-4 items-center">
                      <div className="bg-border size-[30px] md:size-[50px] rounded-full flex-none " />
                      <div className="text-primaryTextColor text-sm font-inter-regular ">
                        <span className="font-inter-semibold ">
                          {log.edited_from}{" "}
                        </span>
                        {log.description}
                        <span className="font-inter-semibold break-all">
                          {" "}
                          {log?.changes && log?.changes}{" "}
                        </span>
                      </div>
                      <div>
                        <span className="text-secondaryTextColor font-inter-medium text-sm">
                          {log.updated_at.substring(11, 16)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ConditionalRender>

              <ConditionalRender
                condition={
                  log?.type === "Lead Assigned" ||
                  log?.type === "Appointment Assigned" ||
                  log?.type === "Appointment Un Assigned"
                }
              >
                <div className="flex ml-[-8px] items-center">
                  <div
                    className={`size-[14px] border-[1px] border-[--theme] rounded-full bg-white flex-none`}
                  />
                  <div className="w-[5px] md:w-[40px] h-[1px] border-t-2 border-dashed border-t-border flex-none" />
                  <div
                    className="bg-white rounded-[8px] border border-border p-4"
                    style={{ boxShadow: "0px 1px 4.6px 0px #0000001F" }}
                  >
                    <div className="flex flex-row gap-2 md:gap-4 items-center">
                      <div className="bg-border size-[30px] md:size-[50px] rounded-full flex-none " />
                      <div className="text-primaryTextColor text-sm font-inter-regular capitalize">
                        <span className="font-inter-semibold ">
                          {log.edited_from}{" "}
                        </span>
                        {log.description}
                        <span className="font-inter-semibold break-all">
                          {" "}
                          {log?.changes && log?.changes.split("to")[0]}{" "}
                        </span>
                        {log?.type === "Appointment Un Assigned"
                          ? "from"
                          : "to"}
                        <span className="font-inter-semibold break-all">
                          {" "}
                          {log?.changes && log?.changes.split("to")[1]}
                        </span>
                      </div>
                      <div>
                        <span className="text-secondaryTextColor font-inter-medium text-sm">
                          {log.updated_at.substring(11, 16)}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ConditionalRender>
              {!log.type && (
                <div className="flex ml-[-8px] items-center">
                  <div
                    className={`size-[14px] border border-[--theme] rounded-full bg-white flex-none `}
                  />
                  <div className="w-[5px] md:w-[40px] h-[1px] border-t-2 border-dashed border-t-border flex-none" />
                  <div
                    className="bg-white rounded-[8px] border border-border p-4"
                    style={{ boxShadow: "0px 1px 4.6px 0px #0000001F" }}
                  >
                    <div className="flex flex-row gap-2 md:gap-4 items-center">
                      <div className="bg-border size-[30px] md:size-[50px] rounded-full flex-none " />
                      <div className="text-primaryTextColor text-sm font-inter-regular">
                        <span className="font-inter-semibold ">
                          {log.edited_from}{" "}
                        </span>
                        <span>changed the status to </span>
                        <span className="font-inter-semibold ">
                          {log.feedback_status}{" "}
                        </span>
                        {log.feedback_status == "Won" && (
                          <>
                            <div>
                              <span>Won Klients: </span>
                              <span className="font-inter-semibold ">
                                {log.won_klients}{" "}
                              </span>
                            </div>
                          </>
                        )}
                        {log.feedback_status == "Lost" && (
                          <>
                            <div>
                              <span>Reason: </span>
                              <span className="font-inter-semibold ">
                                {log.other_reason}{" "}
                              </span>
                            </div>
                          </>
                        )}
                        {log.feedback_status == "Follow Up" && (
                          <>
                            <div>
                              <span>New Data: </span>
                              <span className="font-inter-semibold ">
                                {log.new_date}
                                {" - "}
                                {log.new_time}{" "}
                              </span>
                            </div>
                          </>
                        )}
                        {log.feedback_status == "Pending" && (
                          <>
                            <div>
                              <span>Old Data: </span>
                              <span className="font-inter-semibold ">
                                {log.new_date}
                                {" - "}
                                {log.new_time}{" "}
                              </span>
                            </div>
                          </>
                        )}
                        {log.note != null && (
                          <>
                            <div>
                              <span>Note: </span>
                              <span className="font-inter-semibold ">
                                {log.note}{" "}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      <div>
                        <div>
                          <ConditionalRender condition={showReview}>
                            {Array.from({ length: 5 }, (_, index) =>
                              index < log.rating_stars ? (
                                <div className="inline-block">
                                  <Icon
                                    iconType={IconType.RATE_STAR_ICON}
                                    color="#faca15"
                                  />
                                </div>
                              ) : (
                                <div className="inline-block">
                                  <Icon iconType={IconType.RATE_STAR_ICON} />
                                </div>
                              )
                            )}
                          </ConditionalRender>
                        </div>
                        <div className="flex justify-end">
                          <span className="text-secondaryTextColor font-inter-medium text-sm">
                            {log.updated_at.substring(11, 16)}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActivityLogItem;
