import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import Headline from "components/atoms/headline/Headline";
import Icon from "components/atoms/icon/Icon";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import { IconType } from "lib/helpers/constants/iconTypes";
import { ReactInstance, RefObject, useMemo, useRef, useState } from "react";
import EmployeeProfileList from "./employee-profile-list/EmployeeProfileList";
import { Column } from "react-table";
import EmployeeJobIcon from "components/atoms/icon/icon-type/EmployeeJobIcon";
import EmployeeEmailIcon from "components/atoms/icon/icon-type/EmployeeEmailIcon";
import Label from "components/atoms/input/input-label/InputLabel";
import Input from "components/atoms/input/Input";
import { ShowPasswordIcon } from "components/atoms/icon/export";
import { useEmployeSelfProfile } from "lib/hooks/queries/Employee/useEmployeSelfProfile";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useSidebarContext } from "lib/context/SidebarContext/SidebarContext";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import { useReactToPrint } from "react-to-print";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import ErrorState from "components/atoms/error-state/ErrorState";
import useMediaQuery from "lib/hooks/shared/useMediaQuery";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";

const EmployeeProfileView = () => {
  const [selectedTab, _] = useState<number>(0);
  const [selectedTabSwitch, setSelectedTabSwitch] = useState<number>(0);
  const [fileName, setFileName] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const { id } = useParams();
  const { data, isLoading, isError } = useEmployeSelfProfile(id);
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate();
  const { themeColors } = useThemeContext();
  const { isToggled } = useSidebarContext();
  const { confirm } = useModalContext();
  const componentRef = useRef<ReactInstance | null>(null);
  const token: any = localStorage.getItem("token");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (isError) {
    navigate("/hr");
  }

  const dummyData = useMemo(
    () => [
      {
        date: "Date",
        checkin: "09:28 AM",
        checkout: "07:00 PM",
        break: "00:30 Min",
        workinghours: "09:02 Hrs",
        joiningdate: "16/08/2013",
        status: "Active",
      },
      {
        date: "Date",
        checkin: "09:28 AM",
        checkout: "07:00 PM",
        break: "00:30 Min",
        workinghours: "09:02 Hrs",
        joiningdate: "16/08/2013",
        status: "Active",
      },
    ],
    []
  );

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Check In",
        accessor: "checkin",
      },
      {
        Header: "Check out",
        accessor: "checkout",
      },
      {
        Header: "Break",
        accessor: "break",
      },
      {
        Header: "Working Hours",
        accessor: "workinghours",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => (
          <div className="bg-[#2CC95B] bg-opacity-[10%] text-[#2CC95B] max-w-[66px] flex justify-center items-center p-1 rounded-[4px]">
            {original.status}
          </div>
        ),
      },
    ],
    []
  ) as Column<any>[];

  const pollDiagramData = [
    { name: "Casual leave", percentage: 63 },
    { name: "Sick leave", percentage: 52 },
    { name: "Maternity leave", percentage: 32 },
    { name: "late attendance", percentage: 58 },
  ];

  const WorkprogressDiagram = [
    { name: "Mon", percentage: 63 },
    { name: "Tues", percentage: 52 },
    { name: "Wed", percentage: 32 },
    { name: "Thurs", percentage: 58 },
    { name: "Fri", percentage: 46 },
    { name: "Sat", percentage: 80 },
    { name: "Sun", percentage: 55 },
  ];
  const showProfessionalInfo = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1450px)"
  );

  const gridResponse = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1450px)"
  );

  const renderTabs = () => {
    switch (activeTab) {
      case 0:
        return (
          <>
            <div>
              {selectedTab === 0 && (
                <>
                  <TabsSwitch
                    ActiveTab={selectedTabSwitch}
                    setSelectedTab={(id) => setSelectedTabSwitch(id)}
                    classes={{
                      iconAndTextWrapperClass:
                        "text-[13px] lg:text-[14px] xl:text-[16px] flex items-center",
                      text: "!pl-1 !pb-[8px]",
                      unActiveTabClass: "!pl-1 !pb-[8px] w-full",
                      tabsClass:
                        "flex flex-col  justify-between w-full lg:flex-row  lg:whitespace-nowrap",
                      subContainerClass:
                        "flex flex-col lg:flex-row justify-between lg:items-center",
                    }}
                    tabs={[
                      {
                        id: 0,
                        name: showProfessionalInfo
                          ? "Personal Info"
                          : "Personal Information",
                        Icon: (
                          <Icon
                            iconType={IconType.PROFILE_ICON}
                            className="w-[18px] xl:w-[25px]"
                            color={
                              selectedTabSwitch === 0 ? themeColors.primary : ""
                            }
                          />
                        ),
                      },
                      {
                        id: 1,
                        name: showProfessionalInfo
                          ? "Professional Info"
                          : "Professional Information",
                        Icon: (
                          <Icon
                            className="w-[18px] xl:w-[25px]"
                            iconType={IconType.PROFESIONAL_INFORMATION_ICON}
                            color={
                              selectedTabSwitch === 1 ? themeColors.primary : ""
                            }
                          />
                        ),
                      },
                      {
                        id: 2,
                        name: "Documents",
                        Icon: (
                          <Icon
                            className="w-[18px] xl:w-[25px]"
                            iconType={IconType.DOCUMENT_ICON}
                            color={
                              selectedTabSwitch === 2 ? themeColors.primary : ""
                            }
                          />
                        ),
                      },
                      {
                        id: 3,
                        name: showProfessionalInfo
                          ? "Access"
                          : "Account Access",
                        Icon: (
                          <Icon
                            className="w-[18px] xl:w-[25px]"
                            iconType={IconType.ACCOUNT_ACCESS_ICON}
                            color={
                              selectedTabSwitch === 3 ? themeColors.primary : ""
                            }
                          />
                        ),
                      },
                    ]}
                  />
                  {selectedTabSwitch === 0 && (
                    <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
                      <Input
                        label="First Name"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={data?.data?.name ?? "Not Available"}
                        disabled={true}
                      />
                      <Input
                        label="Last Name"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={data?.data?.last_name ?? "Not Available"}
                        disabled={true}
                      />
                      <Input
                        label="Mobile Number"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        disabled={true}
                        placeholder={
                          data?.data?.user_information?.phone ?? "Not Available"
                        }
                      />
                      <Input
                        label="Email Address"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={data?.data?.email ?? "Not Available"}
                        disabled={true}
                      />
                      <Input
                        label="Date of Birth"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.birthdate ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Marital Status"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.marital_status ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Gender"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.gender ?? "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Nationality"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.nationality ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Address"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.address ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="City"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.city ?? "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="State"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.state ?? "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Zip Code"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information.zip_code ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                    </div>
                  )}
                  {selectedTabSwitch === 1 && (
                    <div className="grid grid-cols-2 gap-5">
                      <Input
                        label="Employee ID"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={data?.data?.id ?? "Not Available"}
                        disabled={true}
                      />
                      <Input
                        label="User Name"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={data?.data?.name ?? "Not Available"}
                        disabled={true}
                      />
                      <Input
                        label="Employee Type"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.employee_type ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Email Address"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={data?.data?.email ?? "Not Available"}
                        disabled={true}
                      />
                      <Input
                        label="Department"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.department ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Designation"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.position ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Working Days"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.working_days ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Joining Date"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.working_days ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Office Location"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.office_location ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                    </div>
                  )}
                  {selectedTabSwitch === 2 && (
                    <div className="grid grid-cols-2 gap-[20px]">
                      <div className="flex flex-row w-full items-center border-[1px] rounded-[15px] justify-between p-4">
                        <div className="text-[#282D46] text-[16px] font-inter-regular leading-[24px]">
                          Appointment Letter.pdf
                        </div>
                        <div className="flex flex-row items-center gap-3">
                          <Button
                           disabled={
                            !data?.data?.user_information
                              ?.appointment_letter_file
                          }
                            onClick={() => {
                              setLink(
                                data?.data?.user_information
                                  ?.appointment_letter_file
                              );
                              showDocument(
                                data?.data?.user_information
                                  ?.appointment_letter_file
                              );
                            }}
                          >
                            <ShowPasswordIcon color="#282D46" />
                          </Button>
                          <Button
                            type="button"
                            disabled={
                              !data?.data?.user_information
                                ?.appointment_letter_file
                            }
                            onClick={() => {
                              fetch(
                                `https://mainhub.insusales.com/api/hr/show-user-document/${data?.data?.user_information?.appointment_letter_file}`
                              )
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const a = document.createElement("a");
                                  a.style.display = "none";
                                  a.href = url;
                                  a.download = fileName ?? "";
                                  document.body.appendChild(a);
                                  a.click();
                                  window.URL.revokeObjectURL(url);
                                  document.body.removeChild(a);
                                })
                                .catch((error) => console.error(error));
                            }}
                          >
                            <Icon iconType={IconType.PROFILE_DOWNLOAD_ICON} />
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-row w-full items-center border-[1px] rounded-[15px] justify-between p-4">
                        <div className="text-[#282D46] text-[16px] font-inter-regular leading-[24px]">
                          Salary Slip.pdf
                        </div>
                        <div className="flex flex-row items-center gap-3">
                          <Button
                           disabled={
                            !data?.data?.user_information?.salary_slips_file
                          }
                            onClick={() => {
                              setLink(
                                data?.data?.user_information?.salary_slips_file
                              );
                              showDocument(
                                data?.data?.user_information?.salary_slips_file
                              );
                            }}
                          >
                            <ShowPasswordIcon color="#282D46" />
                          </Button>
                          <Button
                            disabled={
                              !data?.data?.user_information?.salary_slips_file
                            }
                            onClick={() => {
                              fetch(
                                `https://mainhub.insusales.com/api/hr/show-user-document/${data?.data?.user_information?.salary_slips_file}`
                              )
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const a = document.createElement("a");
                                  a.style.display = "none";
                                  a.href = url;
                                  a.download = fileName ?? "";
                                  document.body.appendChild(a);
                                  a.click();
                                  window.URL.revokeObjectURL(url);
                                  document.body.removeChild(a);
                                })
                                .catch((error) => console.error(error));
                            }}
                          >
                            <Icon iconType={IconType.PROFILE_DOWNLOAD_ICON} />
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-row w-full items-center border-[1px] rounded-[15px] justify-between p-4">
                        <div className="text-[#282D46] text-[16px] font-inter-regular leading-[24px]">
                          Reliving Letter.pdf
                        </div>
                        <div className="flex flex-row items-center gap-3">
                          <Button
                            disabled={
                              !data?.data?.user_information
                                ?.reliving_letter_file
                            }
                            onClick={() => {
                              setLink(
                                data?.data?.user_information
                                  ?.reliving_letter_file
                              );
                              showDocument(
                                data?.data?.user_information
                                  ?.reliving_letter_file
                              );
                            }}
                          >
                            <ShowPasswordIcon color="#282D46" />
                          </Button>
                          <Button
                            disabled={
                              !data?.data?.user_information
                                ?.reliving_letter_file
                            }
                            onClick={() => {
                              fetch(
                                `https://mainhub.insusales.com/api/hr/show-user-document/${data?.data?.user_information?.reliving_letter_file}`
                              )
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const a = document.createElement("a");
                                  a.style.display = "none";
                                  a.href = url;
                                  a.download = fileName ?? "";
                                  document.body.appendChild(a);
                                  a.click();
                                  window.URL.revokeObjectURL(url);
                                  document.body.removeChild(a);
                                })
                                .catch((error) => console.error(error));
                            }}
                          >
                            <Icon iconType={IconType.PROFILE_DOWNLOAD_ICON} />
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-row w-full items-center border-[1px] rounded-[15px] justify-between p-4">
                        <div className="text-[#282D46] text-[16px] font-inter-regular leading-[24px]">
                          Experience Letter.pdf
                        </div>
                        <div className="flex flex-row items-center gap-3">
                          <Button
                          disabled={
                            !data?.data?.user_information
                              ?.experience_letter_file
                          }
                            onClick={() => {
                              setLink(
                                data?.data?.user_information
                                  ?.experience_letter_file
                              );
                              showDocument(
                                data?.data?.user_information
                                  ?.experience_letter_file
                              );
                            }}
                          >
                            <ShowPasswordIcon color="#282D46" />
                          </Button>
                          <Button
                            disabled={
                              !data?.data?.user_information
                                ?.experience_letter_file
                            }
                            onClick={() => {
                              fetch(
                                `https://mainhub.insusales.com/api/hr/show-user-document/${data?.data?.user_information?.reliving_letexperience_letter_fileter_file}`
                              )
                                .then((response) => response.blob())
                                .then((blob) => {
                                  const url = window.URL.createObjectURL(blob);
                                  const a = document.createElement("a");
                                  a.style.display = "none";
                                  a.href = url;
                                  a.download = fileName ?? "";
                                  document.body.appendChild(a);
                                  a.click();
                                  window.URL.revokeObjectURL(url);
                                  document.body.removeChild(a);
                                })
                                .catch((error) => console.error(error));
                            }}
                          >
                            <Icon iconType={IconType.PROFILE_DOWNLOAD_ICON} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedTabSwitch === 3 && (
                    <div className="grid grid-cols-2 gap-5">
                      <Input
                        label="Email Address"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={data?.data?.email ?? "Not Available"}
                        disabled={true}
                      />
                      <Input
                        label="Strato ID"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.strato_id ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Skype ID"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        placeholder={
                          data?.data?.user_information?.skype_id ??
                          "Not Available"
                        }
                        disabled={true}
                      />
                      <Input
                        label="Github ID"
                        classes={{
                          label:
                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px]",
                        }}
                        placeholder={
                          data?.data?.user_information?.github_id ??
                          "Not Available"
                        }
                        className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none indent-0 !pl-0 disabled:bg-transparent"
                        disabled={true}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        );
      case 1:
        return (
          <>
            <EmployeeProfileList columns={columns} data={dummyData} />
          </>
        );
      case 2:
        return (
          <>
            <EmployeeProfileList columns={columns} data={dummyData} />
          </>
        );
      case 3:
        return (
          <>
            <EmployeeProfileList columns={columns} data={dummyData} />
          </>
        );
      case 4:
        return (
          <>
            <EmployeeProfileList columns={columns} data={dummyData} />
          </>
        );
      default:
        return null;
    }
  };

  
  const {user} = useAuthContext();
  
  

  if (isLoading) {
    return <LoadingScreen />;
  }

  const showDocument = async (file: string) => {
    const isImage = (file: string) => {
      const ext = file?.split(".")?.pop()?.toLowerCase();
      return ext === "jpg" || ext === "jpeg";
    };
    try {
      await confirm({
        title: "Application",
        classes: {
          modal: "md:min-w-[750px] lg:w-[973px]",
        },
        showCloseButton: true,
        showBackButton: true,
        customComponent: () => {
          return (
            <div>
              <div
                ref={componentRef as RefObject<HTMLDivElement>}
                style={{ height: "100%" }}
              >
                {isImage(file) ? (
                  <img
                    src={`https://mainhub.insusales.com/api/hr/show-user-document/${file}`}
                    alt="img"
                    className="h-full w-full object-cover"
                    loading="lazy"
                  />
                ) : (
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
                  >
                    <Viewer
                      renderError={(error) => (
                        <>
                          <ErrorState error={error}>
                            Something went wrong. Please try again later.
                          </ErrorState>
                        </>
                      )}
                      httpHeaders={{
                        Authorization: `Bearer ${token}`,
                      }}
                      renderLoader={() => (
                        <div className="min-h-[500px]">
                          <LoadingScreen />
                        </div>
                      )}
                      fileUrl={`https://mainhub.insusales.com/api/hr/show-user-document/${file}`}
                    />
                  </Worker>
                )}
              </div>
              <div className="flex justify-end gap-[26px] mt-5">
                {isImage(file) ? (
                  ""
                ) : (
                  <IconButton
                    onClick={() => handlePrint()}
                    type="button"
                    secondary={true}
                    icon={
                      <Icon
                        iconType={IconType.PRINT_ICON}
                        color={themeColors.primary}
                      />
                    }
                  >
                    Print
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    fetch(
                      `https://mainhub.insusales.com/api/hr/show-user-document/${file}`
                    )
                      .then((response) => response.blob())
                      .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = fileName ?? "";
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                      })
                      .catch((error) => console.error(error));
                  }}
                  type="button"
                  icon={<Icon iconType={IconType.DOWNLOAD_ICON} />}
                >
                  Download
                </IconButton>
              </div>
            </div>
          );
        },
      });
    } catch (err) {
      return null;
    }
  };


  return (
    <div>
      <Headline title="Employees Management" />
      <div className="flex items-center justify-between">
        <p className="text-[#7D8592] text-[25px] font-inter-regular">
          Employee profile details
        </p>
        <IconButton
          icon={<Icon iconType={IconType.EDIT_ICON} color="white" />}
          onClick={() => navigate(`/hr/update-employee/${id ? id : user?.id}`)}
        >
          Update Profile
        </IconButton>
      </div>
      <div
        className={`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5 mb-5 mt-5`}
      >
        <div>
          <div className="bg-white px-[10px] 2xl:px-[20px] py-5 min-h-[150px] border-[1px] border-[#F2F4F7] md:gap-[5px]  2xl:gap-[30px] rounded-[20px] flex items-center">
            <div className="border-[2px] border-[--theme] rounded-full border-r-[#667085] border-t-[#667085]">
              <img
                src={
                  data?.data?.user_information?.profil_picture
                    ? `https://mainhub.insusales.com/api/profile-picture/${data.data.user_information.profil_picture}`
                    : "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
                className="object-cover rounded-full w-[70px] h-[70px] 2xl:w-[80px] 2xl:h-[80px] p-[6px]"
                alt="Profile"
                loading="lazy"
              />
            </div>
            <div className=" flex flex-col gap-3">
              <div>
                <h1 className="text-primaryTextColor font-inter-semibold text-[20px] flex items-center gap-1">
                  {data?.data?.name ?? "Not Available"}
                  <span>{data?.data?.last_name}</span>
                </h1>
              </div>
              <div className="flex flex-row gap-2">
                <EmployeeJobIcon />
                <p className="text-primaryTextColor font-inter-regular">
                  {data?.data?.user_information?.position ?? "Not Available"}
                </p>
              </div>
              <div className="flex flex-row gap-2">
                <EmployeeEmailIcon />
                <p className="text-primaryTextColor font-inter-regular">
                  {data?.data?.email ?? "Not Available"}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white mt-[15px] px-[20px] py-5 border-[1px] min-h-[150px] border-[#F2F4F7] rounded-[20px]">
            <div className="flex items-center justify-between">
              <h1 className="text-primaryTextColor font-inter-semibold text-[20px]">
                Notices
              </h1>
              <Button className="bg-transparent border-none flex items-center gap-2   text-[--theme] font-inter-bold">
                Mehr sehen
                <Icon
                  iconType={IconType.RIGHT_ARROW_ICON}
                  color={themeColors.primary}
                />
              </Button>
            </div>
            <p className="text-[#D61616] mt-5 font-inter-bold text-[14px] flex items-center gap-[11px]">
              <Icon iconType={IconType.ALERT_ICON} color="#D61616" />
              Missing resume file.
            </p>
            <p className="text-[#D61616] mt-[13px] font-inter-bold text-[14px] flex items-center gap-[11px]">
              <Icon iconType={IconType.ALERT_ICON} color="#D61616" />
              Sick leave request pending.
            </p>{" "}
          </div>
        </div>
        <div className="bg-white h-[315px] flex flex-col justify-center px-[25px] border-[1px] border-[#F2F4F7] rounded-[20px]">
          <div>
            <Label className="text-[16.7px] font-inter-bold leading-[20px]">
              Leave Credits
            </Label>
          </div>
          <div className="flex flex-col items-center ">
            {pollDiagramData.map((poll, index) => (
              <div key={index} className="flex flex-col w-full py-[10px]">
                <div className="flex flex-row items-center justify-between pb-[5px]">
                  <div className="text-[14px] font-inter-normal leading-[20px]">
                    {poll.name}
                  </div>
                  <div className="text-[14px] font-inter-normal leading-[20px]">
                    {poll.percentage}%
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: `${themeColors.primary}0D`,
                  }}
                  className="relative w-full h-[8px] rounded-[10px]"
                >
                  <div
                    className="absolute bg-[--theme] top-[0] left-[0] bottom-[0] rounded-[10px]"
                    style={{ width: `${poll.percentage}%` }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white h-[315px] flex flex-col justify-center p-[25px]  border-[1px] border-[#F2F4F7] rounded-[20px]">
          <div>
            <Label className="text-[16.7px] font-inter-bold leading-[20px] relative bottom-[18px]">
              Work progress
            </Label>
          </div>
          <div className="flex flex-row justify-center items-center h-[174px]">
            {WorkprogressDiagram.map((poll, index) => (
              <div
                key={index}
                className="flex flex-col items-center h-full px-[4px] 2xl:px-[10px] pt-[5px]  mx-[5px]"
              >
                <div
                  style={{
                    backgroundColor: `${themeColors.primary}0D`,
                  }}
                  className="relative w-[6.29px]  h-full rounded-[50px]"
                >
                  <div
                    className="absolute w-[10.48px] bg-[--theme] bottom-[0] right-[-2px] rounded-[50px]"
                    style={{ height: `${poll.percentage}%` }}
                  ></div>
                </div>
                <div className="pt-[7px] text-[14px] font-inter-normal leading-[12.1px]">
                  {poll.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <DashboardBoxLayout className="p-[20px] xl:p-[31px]">
        <div
          className={`grid grid-cols-1  lg:grid-cols-[200px_1fr] lg:gap-[10px]  xl:grid-cols-[242px_1fr]  xl:gap-[35px] items-start ${
            gridResponse ? "grid-cols-1" : ""
          }`}
        >
          <div>
            <div className="w-full xl:w-[242px] rounded-[10px] border border-[#A2A1A833] white-space-nowrap ">
              <div
                onClick={() => {
                  setActiveTab(0);
                }}
                className={`px-6 py-4   rounded-t-[10px] flex gap-3 items-center ${
                  activeTab === 0
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                }`}
              >
                <Icon
                  iconType={IconType.PROFILE_ICON}
                  color={activeTab === 0 ? "#fff" : "#282D46"}
                />
                <span className="text-[14px] xl:text-[16px]">Profile</span>
              </div>
              <div
                onClick={() => {
                  setActiveTab(2);
                }}
                className={`px-6 py-4 flex gap-3 items-center ${
                  activeTab === 2
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                }`}
              >
                <Icon
                  iconType={IconType.ATTENDANCE_ICON}
                  color={activeTab === 2 ? "#fff" : undefined}
                />

                <span className="text-[14px] xl:text-[16px]">Attendance</span>
              </div>
              <div
                onClick={() => {
                  setActiveTab(3);
                }}
                className={`px-6 py-4 flex gap-3 items-center ${
                  activeTab === 3
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                }`}
              >
                <Icon
                  iconType={IconType.ACTIVITY_LOG_ICON}
                  color={activeTab === 3 ? "#fff" : undefined}
                />

                <span className="text-[14px] xl:text-[16px]">Projects</span>
              </div>
              <div
                onClick={() => {
                  setActiveTab(4);
                }}
                className={`px-6 py-4 rounded-b-[10px] flex gap-3 items-center ${
                  activeTab === 4
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                }`}
              >
                <Icon
                  iconType={IconType.LEAVE_ICON}
                  color={activeTab === 4 ? "#fff" : undefined}
                />

                <span className="text-[14px] xl:text-[16px]">Leave</span>
              </div>
            </div>
          </div>
          {renderTabs()}
        </div>
      </DashboardBoxLayout>
    </div>
  );
};

export default EmployeeProfileView;
