import React from "react";

type TTabsSwitchClassses = {
  container: string;
  text: string;
  activeTabClass: string;
  tabsClass: string;
  subContainerClass: string;
  iconAndTextWrapperClass: string;
  unActiveTabClass: string;
};

interface Tab {
  id: number;
  name: string;
  Icon?: JSX.Element;
}

interface TabsSwitchProps {
  children?: JSX.Element;
  tabs: Tab[];
  setSelectedTab: (id: number) => void;
  ActiveTab?: number;
  classes?: Partial<TTabsSwitchClassses>;
}

const TabsSwitch: React.FC<TabsSwitchProps> = ({
  tabs,
  setSelectedTab,
  ActiveTab,
  children,
  classes = {
    container: "",
    text: "",
    activeTabClass: "",
    tabsClass: "",
    subContainerClass: "",
    iconAndTextWrapperClass: "",
  },
}) => {
  return (
    <div
      className={`text-sm font-medium text-center text-gray-500 border-[#A2A1A833] mb-5 border-b ${classes.container}`}
    >
      <div
        className={`flex flex-col lg:flex-row justify-between lg:items-center  ${classes.subContainerClass}`}
      >
        <ul className={`flex flex-wrap -mb-px ${classes.tabsClass}`}>
          {tabs.map((tab) => (
            <li key={tab.id}>
              <div
                onClick={() => {
                  setSelectedTab(tab.id);
                }}
                className={`inline-block px-2 sm:px-3 xl:px-4 pb-2 sm:pb-4 xl:pb-5 pt-4 border-b-2 text-sm xl:text-base ${
                  classes.unActiveTabClass
                } ${
                  ActiveTab === tab.id
                    ? `text-[--theme] border-[--theme] font-inter-bold ${classes.activeTabClass} ${classes.text}`
                    : "border-transparent cursor-pointer font-inter-regular text-primaryTextColor"
                }`}
              >
                <span className={classes.iconAndTextWrapperClass}>
                  {tab.Icon && <span className="mr-2">{tab.Icon}</span>}
                  {tab.name}
                </span>
              </div>
            </li>
          ))}
        </ul>
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default TabsSwitch;
