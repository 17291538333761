import OverviewView from "components/organisms/overview-view/OverviewView";
import { FC } from "react";

interface IOverviewProps {}

const Overview: FC<IOverviewProps> = ({}) => {
  return <OverviewView />;
};

export default Overview;
