import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { FC, useState } from "react";
import CreatePartner from "./CreatePartner";
import withModal from "lib/helpers/hoc/withModal";
import IconButton from "components/atoms/button/IconButton";
import { useNavigate } from "react-router-dom";

export interface IPartnerResponse {
    name: string;
    logo: string
  }

export interface ICreatePartnerProcessProps {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  onConfirm: () => void;
}

const CreatePartnerProcess: FC<ICreatePartnerProcessProps> = ({
  currentStep,
  onConfirm,
  setCurrentStep
}) => {
  const [response, setResponse] = useState<IPartnerResponse>({ name: '' , logo: ''});
  return (
    <div>
      <ConditionalRender condition={currentStep === 1}>
        <CreatePartner onConfirm={onConfirm} setCurrentStep={setCurrentStep}  setResponse={setResponse}/>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <div className="flex justify-center items-center flex-col mt-[30px]">
        <img src={`https://mainhub.insusales.com/api/partners/logo/${response.logo}`} loading="lazy" className="rounded-full w-[165px] h-[165px] mb-[44px] object-cover" alt="" />
          <h1 className="font-inter-semibold text-[22px] mb-[22px]">Congratulations !</h1>
          <p className="text-[#282D46] font-inter-medium mx-auto text-[18px] w-full text-center">Partner {response?.name} have been created successfully.</p>
          <IconButton
            onClick={() => {
                onConfirm();
                setCurrentStep(1);
            }}
            className={`min-w-[333px] mt-[53px] mb-[45px]`}
            type="button"
          >
            Got To Partners
          </IconButton>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default withModal(CreatePartnerProcess);
