import Button from "components/atoms/button/Button";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import Tooltip from "../tooltip/Tooltip";
import CreateUserGroupForm from "../forms/create-user-group-form/CreateUserGroupForm";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
export interface IUser {
  grouproles: any;
  id: number;
  roles: string;
  workers: Array<string[]>;
  notes: string;
}
export interface IUsersGroup {
  selectedUser: { id: number } | null;
  state: Array<IUser>;
  setState: Dispatch<SetStateAction<IUser[]>>;
  setSelectedUser?: Dispatch<SetStateAction<{ id: number } | null>>;
}
const UsersGroup: FC<IUsersGroup> = ({
  setSelectedUser,
  selectedUser,
  setState,
  state,
}) => {
  const {
    confirm,
    formData,
    handleOpenTooltipbyIndex,
    openTooltipIndex,
    handleCloseTooltipbyIndex,
  } = useSignupCustomHooks({
    initalStep: undefined,
  });
  const [selectedWorkersByRole, setSelectedWorkersByRole] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editingUser, setEditingUser] = useState<IUser | null>(null);
  const handleClickModal = async (userToEdit: any) => {
    try {
      await confirm({
        title: userToEdit ? "Rename role" : "Create a new role",
        showCloseButton: true,
        clearData: false,
        clearSignupUserGroupData: true,
        clearSignupUserGroupFunction() {
          setSelectedWorkersByRole({});
        },
        customComponent: (props) => {
          return (
            <CreateUserGroupForm
              formData={formData}
              onConfirm={props.onConfirm}
              setState={setState}
              selectedWorkersByRole={selectedWorkersByRole}
              setSelectedWorkersByRole={setSelectedWorkersByRole}
              state={state}
              setSelectedUser={setSelectedUser as any}
              editingUser={userToEdit}
            />
          );
        },
      });
    } catch (err) {
      return null;
    }
  };
  const handleDelete = (id: number) => {
    const deletedUser = state.find((user) => user.id === id);
    setState((prevState) => prevState.filter((user) => user.id !== id));
    if (deletedUser) {
      const updatedSelectedWorkersByRole = { ...selectedWorkersByRole };
      deletedUser.workers.forEach((worker: any) => {
        Object.keys(updatedSelectedWorkersByRole).forEach((role) => {
          updatedSelectedWorkersByRole[role].delete(worker.name);
        });
      });
      setSelectedWorkersByRole(updatedSelectedWorkersByRole);
    }
    if (selectedUser?.id === id) {
      setSelectedUser && setSelectedUser(null);
    }
  };
  const handleEdit = (userId: number) => {
    const userToEdit = state.find((user) => user.id === userId);
    if (userToEdit) {
      setEditingUser(userToEdit);
      handleClickModal(userToEdit);
    }
  };
  return (
    <div className="border-[1px] border-[#D0D5DD] rounded-[20px] overflow-visible">
      <div className="flex items-center justify-between p-[20px]  border-[#D0D5DD] border-b-[1px]  pb-[22px]">
        <h1 className="text-primaryTextColor font-inter-regular text-[14px]">
          User Groups
        </h1>
        <Button
          type="button"
          onClick={() => handleClickModal(null)}
          className="bg-bluebutton rounded-full cursor-pointer w-[20px] h-[20px] flex justify-center items-center"
        >
          <Icon iconType={IconType.INCRASE_ICON} color="white" />
        </Button>
      </div>
      <Fragment>
        {state?.map((item: IUser, index: number) => {
          return (
            <div
              key={`group-users-key-${item.id}`}
              className={`relative ${
                index === state.length - 1
                  ? ""
                  : "border-b-[1px] border-b-[#D0D5DD]"
              } ${
                selectedUser && selectedUser.id === item.id
                  ? "border-l-[6px] bg-[#F5FAFD] border-[#50B8E7]"
                  : ""
              }`}
            >
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  className="p-[22px] text-[14px] font-inter-regular text-primaryTextColor w-full mr-auto flex"
                  onClick={() =>
                    setSelectedUser && setSelectedUser({ id: item.id } as any)
                  }
                >
                  {item.roles}
                </Button>
                <div className="flex items-center gap-3 p-[22px] text-[14px] font-inter-regular text-primaryTextColor">
                  {item.workers.length}
                  <Button
                    type="button"
                    onClick={() => handleOpenTooltipbyIndex(index)}
                  >
                    <Icon iconType={IconType.OPTIONS_ICON} />
                  </Button>
                </div>
              </div>
              <Tooltip
                isOpen={openTooltipIndex === index}
                className="absolute w-[164px] z-[1] shadow-custom py-[9px] px-[6px] top-[57px] right-[25px] bg-white flex flex-col text-[14px] gap-1 font-inter-medium rounded-[12px]"
                content={
                  <Fragment>
                    <div
                      className="fixed w-full h-full top-0 left-0 z-[-3]"
                      onClick={() => handleCloseTooltipbyIndex()}
                    ></div>
                    <Button
                      type="button"
                      onClick={() => {
                        handleEdit(item.id);
                        handleCloseTooltipbyIndex();
                      }}
                      className="flex items-center gap-5 p-2 text-primaryTextColor"
                    >
                      <Icon iconType={IconType.EDIT_ICON} />
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        handleDelete(item.id);
                        handleCloseTooltipbyIndex();
                      }}
                      type="button"
                      className="flex items-center gap-5 bg-[#FFF0F0] text-[#D61616] p-2 rounded-[12px]"
                    >
                      <Icon iconType={IconType.DELETE_ICON} />
                      Delete
                    </Button>
                  </Fragment>
                }
              />
            </div>
          );
        })}
        {state?.length === 0 && (
          <EmptyEntity classes={{ childrenclass: "p-[35px]" }}>
            Create your first role
          </EmptyEntity>
        )}
      </Fragment>
    </div>
  );
};
export default UsersGroup;
