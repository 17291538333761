import { FC, useEffect, useState } from "react";
import Checkbox from "components/atoms/checkbox/Checkbox";
import Form from "components/atoms/form/Form";
import Label from "components/atoms/input/input-label/InputLabel";
import {
  Controller,
  FieldArrayPath,
  useFieldArray,
  useForm,
} from "react-hook-form";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Button from "components/atoms/button/Button";
import Input from "components/atoms/input/Input";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { useRequestSecurity } from "lib/hooks/queries/Coustomer/useRequestSecurity";
import AutoCompletInput from "components/atoms/input/autocomplet-input/AutoCompletInput";
import { useExportCustomer } from "lib/hooks/mutations/Customers/useExportCustomer";
import { useDownloadFeedback } from "lib/hooks/queries/Coustomer/useDownloadFeedback";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";

export interface IExportLeadsProps {
  onConfirm: () => void;
  themeColors: {
    primary: string;
  };
}

const ExportCustomers: FC<IExportLeadsProps> = ({ onConfirm, themeColors }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { mutateAsync: exportCustomerData, isLoading } = useExportCustomer();
  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { data } = useRequestSecurity(
    "",
    "export",
    ["accept", "decline"],
    undefined,
    undefined
  );
  const { mutateAsync: getUsersByPermission } = useUsersByPermission();
  const [fetchedUsers, setFetchedUsers] = useState<
    Array<{ id: any; name: any }>
  >([]);
  const [selectedWorkers, setSelectedWorkers] = useState<
    Array<{ id: any; name: any }>
  >([]);

  const { mutate: downloadFeedback } = useDownloadFeedback();
  const { data: partnersData } = usePartners();
  const [inputKey, setInputKey] = useState(0);
  const [fetchedPartners, setFetchedPartners] = useState<
    Array<{ id: any; name: any }>
  >([]);
  const [selectedPartners, setSelectedPartners] = useState<
    Array<{ id: any; name: any }>
  >([]);
  const [partnerInputKey, setPartnerInputKey] = useState(0);

  const {
    fields: workers,
    append: appendWorker,
    remove: removeWorker,
  } = useFieldArray<any>({
    control,
    name: "workers" as FieldArrayPath<any>,
  });

  const {
    fields: partners,
    append: appendPartner,
    remove: removePartner,
  } = useFieldArray<any>({
    control,
    name: "partners" as FieldArrayPath<any>,
  });

  useEffect(() => {
    getUsersByPermission({
      permissions: [P["Costumer Salesman"]],
    })
      .then((res) => {
        setFetchedUsers(res.users);
      })
      .catch(() => {});

    setFetchedPartners(partnersData?.partners || []);
  }, [partnersData]);

  const onSubmit = (data: any) => {
    const selectedFields = Object.keys(data).filter(
      (key) =>
        data[key] === key &&
        [
          "first_name",
          "last_name",
          "birthdate",
          "phone",
          "address",
          "city",
          "nr",
          "postal_code",
          "nationality",
          "name",
          "creation_date",
          "company_name",
          "status",
          "commission",
          "commission_date",
        ].includes(key)
    );

    const selectedStatuses = Object.keys(data).filter(
      (key) =>
        data[key] === key &&
        [
          "submitted",
          "quality",
          "recorded",
          "rejected",
          "retreat",
          "provisioned",
          "canceled",
        ].includes(key)
    );

    const workerIds = selectedWorkers.map((worker) => worker.id);
    const partnerNames = selectedPartners.map((partner) => partner.name);

    const formattedData = {
      type: "export",
      name: data.request_name,
      data: [
        {
          fields: selectedFields,
          from: data.from,
          to: data.to,
          statuses: selectedStatuses,
          workers: workerIds || [],
          companys: partnerNames || [],
        },
      ],
    };
    exportCustomerData(formattedData).then(() => {
      onConfirm();
    }).catch(() => {});
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const worker = fetchedUsers?.find(
        (user: any) => user.name === (event.target as HTMLInputElement).value
      );
      if (worker) {
        appendWorker(worker);
        setSelectedWorkers((prevWorkers) => [...prevWorkers, worker]);
        setFetchedUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== worker.id)
        );
        setInputKey((prevKey) => prevKey + 1);
      }
    }
  };

  const handleRemoveWorker = (index: number) => {
    const workerToRemove = workers[index];
    removeWorker(index);
    setSelectedWorkers((prevWorkers) =>
      prevWorkers.filter((worker) => worker.id !== workerToRemove.id)
    );
    setFetchedUsers((prevUsers: any) => [...prevUsers, workerToRemove]);
  };

  const handleKeyDownPartner = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const partner = fetchedPartners?.find(
        (partner: any) =>
          partner.name === (event.target as HTMLInputElement).value
      );
      if (partner) {
        appendPartner(partner);
        setSelectedPartners((prevPartners) => [...prevPartners, partner]);
        setFetchedPartners((prevPartners: any) =>
          prevPartners.filter((p: any) => p.id !== partner.id)
        );
        setPartnerInputKey((prevKey) => prevKey + 1);
      }
    }
  };

  const handleRemovePartner = (index: number) => {
    const partnerToRemove = partners[index];
    removePartner(index);
    setSelectedPartners((prevPartners) =>
      prevPartners.filter((partner) => partner.id !== partnerToRemove.id)
    );
    setFetchedPartners((prevPartners: any) => [
      ...prevPartners,
      partnerToRemove,
    ]);
  };
  const renderTabs = () => {
    switch (selectedTab) {
      case 0:
        return (
          <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Name Of Request *"
              {...register("request_name", { required: "Name is required" })}
              className="mb-5"
              classes={{errorClassName: '!bottom-[-1px]'}}
              error={errors.request_name}
            />
            <div className="mb-[35px]">
              <div className="grid grid-cols-2 gap-5 ">
                <Controller
                  control={control}
                  name="from"
                  rules={{ required: "From date is required" }}
                  render={({ field: { onChange, value } }) => (
                    <PrimaryDatePicker
                      label="From *"
                      startDate={value}
                      wrapperClassName="!left-[5px]"
                      error={errors.from}
                      setStartDate={(date: string) => onChange(date)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="to"
                  rules={{ required: "To date is required" }}
                  render={({ field: { onChange, value } }) => (
                    <PrimaryDatePicker
                      label="To *"
                      startDate={value}
                      setStartDate={(date: string) => onChange(date)}
                      error={errors.to}
                    />
                  )}
                />
              </div>
              <div className="flex items-center gap-2 mt-2 w-full mt-5">
                <Icon
                  iconType={IconType.ALERT_ICON}
                  className="w-[16px]"
                  color={themeColors.primary}
                />
                <span className="font-inter-medium text-[11px] text-primaryTextColor">
                  The data will be filtered based on the customer creation
                  date..
                </span>
              </div>
            </div>
            <div className="flex flex-col mb-[40px] gap-2">
              <Label className="flex flex-col gap-2">
                Consultant
                <AutoCompletInput
                  suggestions={fetchedUsers?.map((user: any) => user.name)}
                  onKeyDown={handleKeyDown}
                  className="block"
                  placeholder="Consultant"
                  name="worker"
                  key={inputKey}
                />
              </Label>
              <ul className="flex flex-wrap gap-2">
                {workers?.map((item, index) => (
                  <li
                    key={`keyword-key-${index}`}
                    className="font-inter-bold pr-[25px] capitalize bg-[#F4F9FD] text-[#7D8592] relative text-center rounded-[8px] text-[14px] w-auto p-1"
                  >
                    {(item as any).name}
                    <Button
                      type="button"
                      className="absolute top-[7px] right-[5px]"
                      onClick={() => handleRemoveWorker(index)}
                    >
                      <Icon
                        iconType={IconType.CLOSE_ICON}
                        className="p-1"
                        color="#7D8592"
                      />
                    </Button>
                  </li>
                ))}
              </ul>
              <Label className="flex flex-col gap-2">
                Partners
                <AutoCompletInput
                  suggestions={fetchedPartners?.map((item: any) => item.name)}
                  onKeyDown={handleKeyDownPartner}
                  className="block"
                  placeholder="Partners"
                  name="partner"
                  key={partnerInputKey}
                />
              </Label>
              <ul className="flex flex-wrap gap-2">
                {partners?.map((item, index) => (
                  <li
                    key={`partner-key-${index}`}
                    className="font-inter-bold pr-[25px] capitalize bg-[#F4F9FD] text-[#7D8592] relative text-center rounded-[8px] text-[14px] w-auto p-1"
                  >
                    {(item as any).name}
                    <Button
                      type="button"
                      className="absolute top-[7px] right-[5px]"
                      onClick={() => handleRemovePartner(index)}
                    >
                      <Icon
                        iconType={IconType.CLOSE_ICON}
                        className="p-1"
                        color="#7D8592"
                      />
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="relative grid grid-cols-2 gap-2">
              <Label className="absolute top-[-12px]">Fields To Export</Label>
              <Label className="flex items-center gap-2 mt-5">
                <Controller
                  control={control}
                  name="first_name"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "first_name"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "first_name" : false)
                      }
                    />
                  )}
                />
                First Name
              </Label>
              <Label className="flex items-center gap-2  mt-5">
                <Controller
                  control={control}
                  name="last_name"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "last_name"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "last_name" : false)
                      }
                    />
                  )}
                />
                Last Name
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="birthdate"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "birthdate"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "birthdate" : false)
                      }
                    />
                  )}
                />
                Birthdate
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "phone"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "phone" : false)
                      }
                    />
                  )}
                />
                Phone
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="address"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "address"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "address" : false)
                      }
                    />
                  )}
                />
                Address
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "city"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "city" : false)
                      }
                    />
                  )}
                />
                City
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="nr"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "nr"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "nr" : false)
                      }
                    />
                  )}
                />
                Nr
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="postal_code"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "postal_code"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "postal_code" : false)
                      }
                    />
                  )}
                />
                Postal Code
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="nationality"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "nationality"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "nationality" : false)
                      }
                    />
                  )}
                />
                Nationality
              </Label>
            </div>
            <div className="relative grid grid-cols-2 gap-2 mt-4">
              <Label className="absolute top-[-12px]">Product Fields</Label>
              <Label className="flex items-center gap-2 mt-5">
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "name"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "name" : false)
                      }
                    />
                  )}
                />
                Name
              </Label>
              <Label className="flex items-center gap-2 mt-5">
                <Controller
                  control={control}
                  name="creation_date"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "creation_date"}
                      onChange={(e) =>
                        field.onChange(
                          e.target.checked ? "creation_date" : false
                        )
                      }
                    />
                  )}
                />
                Creation Date
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="company_name"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "company_name"}
                      onChange={(e) =>
                        field.onChange(
                          e.target.checked ? "company_name" : false
                        )
                      }
                    />
                  )}
                />
                Company Name
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "status"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "status" : false)
                      }
                    />
                  )}
                />
                Status
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="commission"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "commission"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "commission" : false)
                      }
                    />
                  )}
                />
                Commission
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="commission_date"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "commission_date"}
                      onChange={(e) =>
                        field.onChange(
                          e.target.checked ? "commission_date" : false
                        )
                      }
                    />
                  )}
                />
                Commission Date
              </Label>
            </div>
            <div className="relative grid grid-cols-2 gap-2 mt-[40px]">
              <Label className="absolute top-[-12px]">Status Fields</Label>
              <Label className="flex items-center gap-2 mt-5">
                <Controller
                  control={control}
                  name="submitted"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "submitted"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "submitted" : false)
                      }
                    />
                  )}
                />
                Submitted
              </Label>
              <Label className="flex items-center gap-2 mt-5">
                <Controller
                  control={control}
                  name="quality"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "quality"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "quality" : false)
                      }
                    />
                  )}
                />
                Quality
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="recorded"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "recorded"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "recorded" : false)
                      }
                    />
                  )}
                />
                Recorded
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="rejected"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "rejected"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "rejected" : false)
                      }
                    />
                  )}
                />
                Rejected
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="retreat"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "retreat"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "retreat" : false)
                      }
                    />
                  )}
                />
                Retreat
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="provisioned"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "provisioned"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "provisioned" : false)
                      }
                    />
                  )}
                />
                Provisioned
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="canceled"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "canceled"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "canceled" : false)
                      }
                    />
                  )}
                />
                Canceled
              </Label>
            </div>
            <div className="flex justify-end gap-4 mt-[38px]">
              <IconButton
                secondary={true}
                type="button"
                onClick={() =>
                  reset({
                    first_name: false,
                    last_name: false,
                    birthdate: false,
                    language: false,
                    phone: false,
                    persons: false,
                    email: false,
                    address: false,
                    city: false,
                    postal_code: false,
                    nr: false,
                    notes: false,
                    from: "",
                    to: "",
                    request_name: "",
                    submitted: false,
                    quality: false,
                    recorded: false,
                    rejected: false,
                    retreat: false,
                    provisioned: false,
                    canceled: false,
                    workers: [],
                    partners: [],
                    name: false,
                    creation_date: false,
                    company_name: false,
                    status: false,
                    commission: false,
                    commission_date: false,
                  })
                }
                className="w-full max-w-[150px]"
              >
                Reset
              </IconButton>
              <IconButton
                type="submit"
                disabled={isLoading ? true : false}
                className="w-full max-w-[150px] text-white"
              >
                {isLoading ? "Exporting..." : "Export"}
              </IconButton>
            </div>
          </Form>
        );
      case 1:
        return (
          <div className="mt-5 flex flex-col gap-5">
            <NoDataBoundary
              condition={data?.pages[0].data.data.length > 0}
              fallback={<EmptyEntity name="Request" />}
            >
              {data?.pages[0].data.data?.map((item: any) => {
                return (
                  <div
                    key={`files-key-${item.id}`}
                    className="flex items-center justify-between py-5 px-3 border rounded-[8px]"
                  >
                    <div className="flex items-center gap-2">
                      <Icon iconType={IconType.UPLOADED_CSV_ICON} />
                      <div className="flex flex-col gap-1">
                        <h1 className="font-inter-medium text-[14px]">
                          {item?.name}
                        </h1>
                        <p className="font-inter-medium text-[#282D46] text-[14px]">
                          From: {item.data[0].from} - To {item.data[0].to}
                        </p>
                      </div>
                    </div>
                    {item.status === "accept" ? (
                      <Button
                        onClick={() =>
                          downloadFeedback({
                            request_id: item.id,
                          })
                        }
                        className={`text-right font-inter-bold  w-[100px] text-[--theme] text-[14px]`}
                      >
                        Download
                      </Button>
                    ) : (
                      <p className="text-right font-inter-bold w-[100px] text-red-600 text-[14px]">
                        Decline
                      </p>
                    )}
                  </div>
                );
              })}
            </NoDataBoundary>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="bg-white rounded-[8px] ">
        <h1 className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-[5px] text-[#282D46] font-inter-semibold text-[22px]">
          Export Customers
        </h1>
        <Button
          onClick={() => setSelectedTab(0)}
          className={`text-[--theme] w-[50%] p-2 font-inter-medium min-w-[138.34px] cursor-pointer ${
            selectedTab === 0
              ? `bg-transparent border-[--theme] border-b-[3px]`
              : "bg-transparent !text-primaryTextColor border-b-[3px] border-b-[#F2F4F7]"
          }`}
        >
          New Request
        </Button>
        <Button
          onClick={() => setSelectedTab(1)}
          className={`text-[--theme] w-[50%] p-2 font-inter-medium min-w-[138.34px] cursor-pointer ${
            selectedTab === 1
              ? `bg-transparent border-[--theme] border-b-[3px]`
              : "bg-transparent !text-primaryTextColor border-b-[3px] border-b-[#F2F4F7]"
          }`}
        >
          Requests
        </Button>
      </div>
      {renderTabs()}
    </>
  );
};

export default ExportCustomers;
