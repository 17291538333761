import { memo } from "react";
import { IconType } from "lib/helpers/constants/iconTypes";
import {
  LogoIcon,
  LeftArrowIcon,
  RightArrowIcon,
  HiddenPasswordIcon,
  ColorfulLogoIcon,
  CongratsIcon,
  IncraseIcon,
  DecraseIcon,
  LanguageIcon,
  AlertIcon,
  LocationIcon,
  UploadImageIcon,
  SupportIcon,
  SuccesfulLoginIcon,
  CheckEmailIcon,
  DeleteIcon,
  ShowPasswordIcon,
  CricleIcon,
  VerticalArrowIcon,
  VerticalArrowColorfulIcon,
  VerifyIcon,
  CricleColorfulIcon,
  DatePickerIcon,
  DraftIcon,
  CloseIcon,
  EditPenIcon,
  VisaIcon,
  StripeIcon,
  MasterCardIcon,
  ApplePayIcon,
  PaypalIcon,
  LockIcon,
  SentEmailIcon,
  PaymentSuccessfulIcon,
  ResetPasswordCompleteIcon,
  DownArrowIcon,
  UpArrowIcon,
  DutchFlagIcon,
  OptionsIcon,
  UpgradePlanIcon,
  SearchIcon,
  NotificationsIcon,
  HomeIcon,
  HrIcon,
  ProjectIcon,
  TeamIcon,
  EventsIcon,
  CustomersIcon,
  CalendarIcon,
  StatisticIcon,
  InsuranceIcon,
  ChatIcon,
  SettingsIcon,
  LogoutIcon,
  PendingIcon,
  ContratsIcon,
  TotalEventsIcon,
  ClockIcon,
  UploadFileIcon,
  DownpercentageIcon,
  UppercentageIcon,
  ViewIcon,
  CricleCloseIcon,
  FilterIcon,
  ExportIcon,
  PlusIcon,
  TooltipThreeDotsIcon,
  AttachmentIcon,
  FilterBarsIcon,
  GridViewIcon,
  ListViewIcon,
  CirclePlusIcon,
  DragHandleDotsIcon,
  CheckBoxTickIcon,
  MinusIcon,
  ThinPlusIcon,
  FilePDFIcon,
  FileJPGIcon,
  ProfileIcon,
  DocumentIcon,
  ActivityLogIcon,
  AddNewIcon,
  CostumerAccesIcon,
  DeleteSourceIcon,
  EditSourceIcon,
  TutorialCamIcon,
  ZapierToolIcon,
  CopyIcon,
  CSVIcon,
  CheckIcon,
  UploadCSV,
  UploadedCSVIcon,
  DeleteCampaignFieldsIcon,
  BackButtonModal,
  Check2Icon,
  Check3Icon,
  LeadsIcon,
  CloseXsmIcon,
  LostLeadsIcon,
  ConversionRateIcon,
  KrankenkasseIcon,
  RechtsschutzIcon,
  AutoIcon,
  VorsorgeIcon,
  SachenIcon,
  EmailIcon,
  PhoneIcon,
  AssignedIcon,
  CongratsAppointmentIcon,
  UploadImageColorfulIcon,
  AppointmentCalendarIcon,
  TransferIcon,
  ConverteIcon,
  CameraIcon,
  ApprovedIcon,
  ProductsIcon,
  KVGIcon,
  LawProtectionIcon,
  CarInsuranceIcon,
  PendingCustomerIcon,
  PrintIcon,
  LostCustomerIcon,
  LeaveIcon,
  AttendanceIcon,
  AccountAccesIcon,
  ProfessionalInformationIcon,
  PartnersIcon,
  TotalPartnersIcon,
  NewPartnersIcon,
  ArchivedPartnersIcon,
  DeletePartnersIcon,
  RelatedCustomerIcon,
  DotsIcon,
  MarkAllAsReadIcon,
  FlagIcon,
  OpenPendingIcon,
  ProcessingPendingIcon,
  DonePendingsIcon,
  AutoGeneratePasswordIcon,
  CommentIcon,
  EmojiIcon,
  DownloadPDFIcon,
} from "components/atoms/icon/export";
import EmployeeEmailIcon from "./icon-type/EmployeeEmailIcon";
import EmployeeJobIcon from "./icon-type/EmployeeJobIcon";
import DownloadIcon from "./icon-type/DownloadIcon";
import RadioButtonIcon from "./icon-type/RadioButtonIcon";
import RateStarIcon from "./icon-type/RateStarIcon";
import WonAppointmentIcon from "./icon-type/WonAppointmentIcon";
import LostAppointmentIcon from "./icon-type/LostAppointmentIcon";
import PendingAppointmentIcon from "./icon-type/PendingAppointmentIcon";
import FollowupAppointmentIcon from "./icon-type/FollowupAppointmentIcon";
import SignedContract from "./icon-type/SignedContract";
import PdfUploadIcon from "./icon-type/PdfUploadIcon";
import ProfileDownloadIcon from "./icon-type/ProfileDownloadIcon";
import PendingsIcon from "./icon-type/PendingsIcon";
import CHFICON from "./icon-type/CHFICON";

interface IIconProps {
  iconType: IconType;
  className?: string;
  color?: string;
}

interface IconComponentMap {
  [key: string]: React.MemoExoticComponent<
    ({
      className,
      color,
    }: {
      className?: string;
      color?: string;
      width?: string;
      height?: string;
    }) => JSX.Element
  >;
}

const iconComponents: IconComponentMap = {
  [IconType.LOGO_ICON]: LogoIcon,
  [IconType.LEFT_ARROW_ICON]: LeftArrowIcon,
  [IconType.HIDDEN_PASSWORD_ICON]: HiddenPasswordIcon,
  [IconType.COLORFUL_LOGO_ICON]: ColorfulLogoIcon,
  [IconType.CONGRATS_ICON]: CongratsIcon,
  [IconType.INCRASE_ICON]: IncraseIcon,
  [IconType.DECRASE_ICON]: DecraseIcon,
  [IconType.LANGUAGE_ICON]: LanguageIcon,
  [IconType.ALERT_ICON]: AlertIcon,
  [IconType.LOCATION_ICON]: LocationIcon,
  [IconType.UPLOAD_ICON]: UploadImageIcon,
  [IconType.SUPPORT_ICON]: SupportIcon,
  [IconType.SUCCESSFUL_LOGIN_ICON]: SuccesfulLoginIcon,
  [IconType.CHECK_EMAIL_ICON]: CheckEmailIcon,
  [IconType.DELETE_ICON]: DeleteIcon,
  [IconType.SHOW_PASSWORD_ICON]: ShowPasswordIcon,
  [IconType.CRICLE_ICON]: CricleIcon,
  [IconType.VERTICAL_ARROW_ICON]: VerticalArrowIcon,
  [IconType.VERTICAL_COLORFUL_ICON]: VerticalArrowColorfulIcon,
  [IconType.VERIFY_ICON]: VerifyIcon,
  [IconType.CRICLE_COLORFUL_ICON]: CricleColorfulIcon,
  [IconType.DATE_PICKER_ICON]: DatePickerIcon,
  [IconType.DRAFT_ICON]: DraftIcon,
  [IconType.CLOSE_ICON]: CloseIcon,
  [IconType.EDIT_ICON]: EditPenIcon,
  [IconType.VISA_ICON]: VisaIcon,
  [IconType.STRIPE_ICON]: StripeIcon,
  [IconType.MASTER_CARD_ICON]: MasterCardIcon,
  [IconType.APPLE_PAY_ICON]: ApplePayIcon,
  [IconType.PAYPAL_ICON]: PaypalIcon,
  [IconType.LOCK_ICON]: LockIcon,
  [IconType.SENT_EMAIL_ICON]: SentEmailIcon,
  [IconType.PAYMENT_SUCCESSFUL_ICON]: PaymentSuccessfulIcon,
  [IconType.RESET_PASSWORD_COMPLETE_ICON]: ResetPasswordCompleteIcon,
  [IconType.DOWN_ARROW_ICON]: DownArrowIcon,
  [IconType.UP_ARROW_ICON]: UpArrowIcon,
  [IconType.DUTCH_FLAG_ICON]: DutchFlagIcon,
  [IconType.OPTIONS_ICON]: OptionsIcon,
  [IconType.UPGRADE_ICON]: UpgradePlanIcon,
  [IconType.SEARCH_ICON]: SearchIcon,
  [IconType.NOTIFICATIONS_ICON]: NotificationsIcon,
  [IconType.HOME_ICON]: HomeIcon,
  [IconType.HR_ICON]: HrIcon,
  [IconType.PROJECT_ICON]: ProjectIcon,
  [IconType.TEAM_ICON]: TeamIcon,
  [IconType.EVENTS_ICON]: EventsIcon,
  [IconType.CUSTOMERS_ICON]: CustomersIcon,
  [IconType.CALENDAR_ICON]: CalendarIcon,
  [IconType.STATISTIC_ICON]: StatisticIcon,
  [IconType.INSURANCE_ICON]: InsuranceIcon,
  [IconType.CHAT_ICON]: ChatIcon,
  [IconType.SETTINGS_ICON]: SettingsIcon,
  [IconType.LOGOUT_ICON]: LogoutIcon,
  [IconType.TOTAL_CUSTOMERS_ICON]: CustomersIcon,
  [IconType.PENDING_ICON]: PendingIcon,
  [IconType.TOTAL_CONTRATS_ICON]: ContratsIcon,
  [IconType.TOTAL_EVENTS_ICON]: TotalEventsIcon,
  [IconType.CLOCK_ICON]: ClockIcon,
  [IconType.UPLOAD_FILE_ICON]: UploadFileIcon,
  [IconType.DOWN_PERCENTAGE_ICON]: DownpercentageIcon,
  [IconType.UP_PERCENTAGE_ICON]: UppercentageIcon,
  [IconType.VIEW_ICON]: ViewIcon,
  [IconType.CRICLE_CLOSE_ICON]: CricleCloseIcon,
  [IconType.FILTER_ICON]: FilterIcon,
  [IconType.EXPORT_ICON]: ExportIcon,
  [IconType.PLUS_ICON]: PlusIcon,
  [IconType.TOOLTIP_THREE_DOTS]: TooltipThreeDotsIcon,
  [IconType.ATTACHMENT_ICON]: AttachmentIcon,
  [IconType.FILTER_BARS_ICON]: FilterBarsIcon,
  [IconType.GRID_VIEW_ICON]: GridViewIcon,
  [IconType.LIST_VIEW_ICON]: ListViewIcon,
  [IconType.CIRCLE_PLUS_ICON]: CirclePlusIcon,
  [IconType.DRAG_HANDLE_DOTS_ICON]: DragHandleDotsIcon,
  [IconType.CHECKBOX_TICK_ICON]: CheckBoxTickIcon,
  [IconType.MINUS_ICON]: MinusIcon,
  [IconType.THIN_PLUS_ICON]: ThinPlusIcon,
  [IconType.FILE_PDF_ICON]: FilePDFIcon,
  [IconType.FILE_JPG_ICON]: FileJPGIcon,
  [IconType.PROFILE_ICON]: ProfileIcon,
  [IconType.DOCUMENT_ICON]: DocumentIcon,
  [IconType.ACTIVITY_LOG_ICON]: ActivityLogIcon,
  [IconType.ADD_NEW_ICON]: AddNewIcon,
  [IconType.CUSTOMER_ACCES_ICON]: CostumerAccesIcon,
  [IconType.DELETE_SOURCE_ICON]: DeleteSourceIcon,
  [IconType.EDIT_SOURCE_ICON]: EditSourceIcon,
  [IconType.RIGHT_ARROW_ICON]: RightArrowIcon,
  [IconType.TUTORIAL_CAM_ICON]: TutorialCamIcon,
  [IconType.ZAPIER_TOOL_ICON]: ZapierToolIcon,
  [IconType.COPY_ICON]: CopyIcon,
  [IconType.CSV_ICON]: CSVIcon,
  [IconType.CHECK_ICON]: CheckIcon,
  [IconType.UPLOAD_CSV]: UploadCSV,
  [IconType.UPLOADED_CSV_ICON]: UploadedCSVIcon,
  [IconType.DELETE_CAMPAIGN_FIELDS_ICON]: DeleteCampaignFieldsIcon,
  [IconType.BACK_BUTTON_MODAL]: BackButtonModal,
  [IconType.CHECK_2_ICON]: Check2Icon,
  [IconType.CHECK_3_ICON]: Check3Icon,
  [IconType.LEADS_ICON]: LeadsIcon,
  [IconType.CLOSE_X_SM_ICON]: CloseXsmIcon,
  [IconType.LOST_LEADS_ICON]: LostLeadsIcon,
  [IconType.CONVERSION_RATE_ICON]: ConversionRateIcon,
  [IconType.KRANKENKASSE_ICON]: KrankenkasseIcon,
  [IconType.RECHTSSCHUTZ_ICON]: RechtsschutzIcon,
  [IconType.AUTO_ICON]: AutoIcon,
  [IconType.VORSORGE_ICON]: VorsorgeIcon,
  [IconType.SACHEN_ICON]: SachenIcon,
  [IconType.EMAIL_ICON]: EmailIcon,
  [IconType.PHONE_ICON]: PhoneIcon,
  [IconType.ASSIGNED_ICON]: AssignedIcon,
  [IconType.EMPLOYEE_EMAIL_ICON]: EmployeeEmailIcon,
  [IconType.EMPLOYEE_JOB_ICON]: EmployeeJobIcon,
  [IconType.DOWNLOAD_ICON]: DownloadIcon,
  [IconType.RADIO_BUTTON_ICON]: RadioButtonIcon,
  [IconType.RATE_STAR_ICON]: RateStarIcon,
  [IconType.WON_APPOINTMENT_ICON]: WonAppointmentIcon,
  [IconType.LOST_APPOINTMENT_ICON]: LostAppointmentIcon,
  [IconType.PENDING_APPOINTMENT_ICON]: PendingAppointmentIcon,
  [IconType.FOLLOW_UP_APPOINTMENT_ICON]: FollowupAppointmentIcon,
  [IconType.CONGRATS_APPOINTMET_ICON]: CongratsAppointmentIcon,
  [IconType.UPLOAD_IMAGE_COLORFUL_ICON]: UploadImageColorfulIcon,
  [IconType.APPOINTMENT_CALENDAR_ICON]: AppointmentCalendarIcon,
  [IconType.TRANSFER_ICON]: TransferIcon,
  [IconType.CONVERT_ICON]: ConverteIcon,
  [IconType.CAMERA_ICON]: CameraIcon,
  [IconType.APPROVED_ICON]: ApprovedIcon,
  [IconType.SIGNED_CONTRACT]: SignedContract,
  [IconType.PRODUCTS_ICON]: ProductsIcon,
  [IconType.PDFUPLOAD_ICON]: PdfUploadIcon,
  [IconType.KVG_ICON]: KVGIcon,
  [IconType.LAW_PROTECTION_ICON]: LawProtectionIcon,
  [IconType.CAR_INSURANCE_ICON]: CarInsuranceIcon,
  [IconType.CUSTOMER_PENDING_ICON]: PendingCustomerIcon,
  [IconType.PRINT_ICON]: PrintIcon,
  [IconType.LOST_CUSTOMER_ICON]: LostCustomerIcon,
  [IconType.LEAVE_ICON]: LeaveIcon,
  [IconType.ATTENDANCE_ICON]: AttendanceIcon,
  [IconType.PROFILE_DOWNLOAD_ICON]: ProfileDownloadIcon,
  [IconType.ACCOUNT_ACCESS_ICON]: AccountAccesIcon,
  [IconType.PROFESIONAL_INFORMATION_ICON]: ProfessionalInformationIcon,
  [IconType.PARTNERS_ICON]: PartnersIcon,
  [IconType.TOTAL_PARTNERS_ICON]: TotalPartnersIcon,
  [IconType.NEW_PARTNER_ICON]: NewPartnersIcon,
  [IconType.ARCHIVED_PARTNERS_ICON]: ArchivedPartnersIcon,
  [IconType.DELETE_PARTNERS_ICON]: DeletePartnersIcon,
  [IconType.RELATED_CUSTOMER_ICON]: RelatedCustomerIcon,
  [IconType.DOTS_ICON]: DotsIcon,
  [IconType.MARK_AS_READ_ICON]: MarkAllAsReadIcon,
  [IconType.FLAG_ICON]: FlagIcon,
  [IconType.PENDINGS_ICON]: PendingsIcon,
  [IconType.OPEN_PENDINGS_ICON]: OpenPendingIcon,
  [IconType.PROCESSING_PENDINGS_ICON]: ProcessingPendingIcon,
  [IconType.DONE_PENDING_ICON]: DonePendingsIcon,
  [IconType.AUTO_GENERATE_PASSWORD_ICON]: AutoGeneratePasswordIcon,
  [IconType.CHF_CURRENCY_ICON]: CHFICON,
  [IconType.COMMENT_ICON]: CommentIcon,
  [IconType.EMOJI_ICON]: EmojiIcon,
  [IconType.DOWNLOAD_PDF_ICON]: DownloadPDFIcon,
};

function Icon(props: IIconProps) {
  const IconComponent = iconComponents[props.iconType];
  if (!IconComponent) {
    return null;
  }
  return <IconComponent {...props} />;
}

export default memo(Icon);
