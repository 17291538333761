import { FC, useEffect, useLayoutEffect } from "react";
import Icon from "../../../atoms/icon/Icon";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import { useMultiStepFormContext } from "lib/context/MultiStepFormContext/MultiStepFormContext";
import axios from "axios";

export interface ICongratulationsStepProps {}

const PaymentSuccessful: FC<ICongratulationsStepProps> = () => {
  const {clearFormData,currentStep,formData} = useMultiStepFormContext<any>();
  const navigate = useNavigate();
  const { setIsAuthenticated  , isAuthenticated } = useAuthContext();


  useEffect(() => {
    if (isAuthenticated) {
      localStorage.removeItem("multi_step_form_state");
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);
  
  const authenticated = () => {
    const token = formData.token;
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem("token", token);
      setIsAuthenticated(true);
      navigate("/dashboard");
      clearFormData();
    }
  }

  return (
    <div className="flex justify-between h-full flex-col">
      <div className="text-center flex flex-col gap-[30px] 2xl:gap-[60px]">
        <div className="mb-[50px]">
          <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
            STEP {currentStep}/6
          </h1>
          <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
            Payment successful
          </span>
        </div>
        <Icon iconType={IconType.PAYMENT_SUCCESSFUL_ICON} className="mx-auto" />
        <p className="text-[14px] text-[#282D46] font-inter-bold">
          Your payment has been successfully processed. You now have access to
          your CRM.
        </p>
        <p className="font-inter-light text-primaryTextColor text-[14px]">
          You need help? <span className="text-bluebutton font-inter-semibold">Contact us.</span>
        </p>
      </div>
      <FormStepNavigationButtons onNextStep={() => authenticated()}  onPrevStep={false}/>
    </div>
  );
};

export default PaymentSuccessful;
