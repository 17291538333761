import { useQuery } from "react-query";
import { getRelatedCustomersForFilter } from "api/Customers";
import axios from "axios";

export const useRelatedCustomersForFilter = (search_input?: any) => {
  return useQuery<Array<any> | any>(
    ["customers", search_input],
    () => getRelatedCustomersForFilter(search_input),
    {
      onError(error: any) {
        console.error(error);
      }
    }
  );
};
