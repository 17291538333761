import { deletePartner } from "api/Partners";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useDeletePartner = () => {
    const queryClient = useQueryClient();
    return useMutation(deletePartner, {
        onSuccess: () => {
            queryClient.invalidateQueries('partners');
            toast.success("Partner Deleted Successfully");
        },
        onError: () => {
            toast.error(`Something went wrong`);
        },
    });
};
