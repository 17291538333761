import IconButton from "components/atoms/button/IconButton";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import React, { Dispatch, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";

export interface IFilterProps {
  source?: number;
  campaign?: number;
  status?: string;
  date_of_creation?: any;
}

export interface IFilterTableDropdownProps {
  setFilterData?: (args: any) => void;
  FilterData?: IFilterProps;
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
  themeColors: {
    primary: string;
  };
}

const FilterSourceTable: React.FC<IFilterTableDropdownProps> = ({
  setFilterData,
  FilterData,
  setIsOpen,
  themeColors,
}) => {
  const { control, reset } = useForm({
    defaultValues: {
      status: FilterData?.status || "",
      date_of_creation: FilterData?.date_of_creation || null,
    },
  });
  const [key, setKey] = useState(0); // Key state to force re-render

  const [DateOfCreation, setDateOfCreation] = useState<Date | null>(
    FilterData?.date_of_creation
  );

  const [InsideData, setInsideData] = useState<IFilterProps>(
    FilterData
      ? FilterData
      : {
          status: "",
          date_of_creation: undefined,
        }
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setInsideData((prevFormData: any) => ({
      ...prevFormData,
      date_of_creation: DateOfCreation,
    }));
  }, [DateOfCreation]);

  const handleButtonClick = () => {
    setIsLoading(true);
    if (setFilterData) {
      setFilterData(InsideData);
    }
    setIsOpen && setIsOpen(false);
    setIsLoading(false);
  };

  const ResetData = () => {
    reset({
      status: "",
      date_of_creation: null,
    });
    setInsideData({ status: "", date_of_creation: undefined });
    setDateOfCreation(null);
    setKey((prevKey) => prevKey + 1); // Change key to force re-render
  };

  return (
    <div className="flex flex-col gap-3">
      {isLoading && <LoadingScreen />}
      <div key={key}>
        <Label text="Status" />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Creatable<any, false>
              {...field}
              placeholder="Choose Status"
              options={[
                { label: "Inactive", value: "0" },
                { label: "Active", value: "1" },
              ]}
              value={
                field.value
                  ? {
                      value: field.value,
                      label: field.value === "1" ? "Active" : "Inactive",
                    }
                  : null
              }
              className="cursor-pointer font-inter-regular react-select-container"
              classNamePrefix="react-select"
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                field.onChange(selectedValue);
                setInsideData((prevState) => ({
                  ...prevState,
                  status: selectedValue,
                }));
              }}
              styles={customStyles}
              isValidNewOption={() => false}
              menuPosition={"fixed"}
              menuPortalTarget={document.body}
            />
          )}
        />
      </div>

      <PrimaryDatePicker
        label="Date of creation"
        setStartDate={setDateOfCreation}
        startDate={DateOfCreation}
        wrapperClassName="!left-[2px]"
      />

      <div className="pt-5">
        <div className="flex flex-row justify-end gap-3">
          <IconButton
            secondary={true}
            className="w-[110px]"
            onClick={ResetData}
          >
            Reset
          </IconButton>
          <IconButton className="w-[110px]" onClick={handleButtonClick}>
            Confirm
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default FilterSourceTable;
