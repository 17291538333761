import Headline from "components/atoms/headline/Headline";
import { useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import Button from "components/atoms/button/Button";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import AppointementManagmentList from "./appointment-managment-list/AppointementManagmentList";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import CreateAppointment from "./create-appointment/CreateAppointment";
import { useAppointments } from "lib/hooks/queries/Appointment/useAppointments";
import AppointmentCards from "./appointment-cards/AppointmentCards";
import FilterAppointments from "./dropdowns/FilterAppointments";
import TransferAppointmentModal from "./transfer-appointment-modal/TransferAppointmentModal";
import AppointmentGridView from "./appointment-grid-view/AppointmentGridView";
import UploadAppointment from "./upload-appointment/UploadAppointment";
import { getStatusColor } from "lib/hooks/shared/getStatusColorAppointment";
import UpdateAppointmentProcess from "./update-appointment/UpdateAppointmentProcess";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";

export interface IAppointmentManagementViewProps {}

export interface IFilterAppointmentsProps {
  search?: string;
  primary_status?: string;
  from_date?: string;
  to_date?: string;
  typeof?: string;
  number_of_persons?: number;
  status?: string;
}

const AppointmentManagementView: React.FC<
  IAppointmentManagementViewProps
> = () => {
  const { confirm } = useModalContext();
  const hasAdminPermission = usePermissions([P["Appointment Admin"]]);
  const hasMenagerPermission = usePermissions([P["Appointment Menager"]]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isUploadModalOpen, setisUploadModalOpen] = useState<boolean>(false);
  const [updateModalData, setUpdateModalData] = useState();
  const { themeColors } = useThemeContext();
  const [filterData, setFilterData] = useState<IFilterAppointmentsProps>({
    search: "",
    primary_status: undefined,
    from_date: undefined,
    to_date: undefined,
    typeof: undefined,
    number_of_persons: undefined,
    status: ""
  });
  const [activeView, setActiveView] = useState<string>("listView");
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const [uploadCurrentStep, setUploadCurrentStep] = useState<number>(1);

  const {
    data,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    itemsPerPage
  } = useAppointments(filterData);

  const handleCellClick = (original: any) => {
    setUpdateModalData(original);
    setIsUpdateModalOpen(true);
  };
  const columns = [
    {
      id: 1,
      Header: "Appointment Date",
      accessor: "Appointment Date",
      Cell: ({ row: { original }}: any) => (
        <div onClick={() => handleCellClick(original)} className="cursor-pointer">
          {`${original?.date}`}
        </div>
      ),
      isChecked: true
    },
    {
      id: 2,
      Header: "Type",
      accessor: "type",
      Cell: ({ row: { original } }: any) => (
        <div onClick={() => handleCellClick(original)} className="cursor-pointer">{original?.is_digital === 0 ? "Physical" : "Digital"}</div>
      ),
      isChecked: true
    },
    {
      id: 3,
      Header: "Appointment Title",
      accessor: '',
      Cell: ({ row: { original } }: any) => (
        <div 
          className="cursor-pointer" 
          onClick={() => handleCellClick(original)}
        >
          {original?.info?.first_name + " " + original?.info?.last_name || "Not available"}
        </div>
      ),
      isChecked: true
    },
    {
      id: 4,
      Header: "Persons",
      accessor: 'persons',
      Cell: ({ row: { original }}: any) => (
        <div onClick={() => handleCellClick(original)} className="cursor-pointer">
          {`${original?.info?.persons || "Not available" }`}
        </div>
      ),
      isChecked: true
    },
    {
      id: 5,
      Header: "Status",
      accessor: "status",
      Cell: ({ row: { original } }: any) => (
        <div onClick={() => handleCellClick(original)}
          className={`cursor-pointer rounded-[4px] max-w-[60px] text-center py-[3px] font-inter-medium text-[12px]  ${getStatusColor(
            original?.feedback_status
          )}`}
        >
          {original?.feedback_status}
        </div>
      ),
      isChecked: true
    },
    {
      id: 6,
      Header: "Reasons",
      accessor: "other_reason",
      Cell: ({ row: { original }}: any) => (
        <div onClick={() => handleCellClick(original)} className="cursor-pointer">
          {`${original?.last_feedback?.other_reason || "Not available"}`}
        </div>
      ),
      isChecked: true
    },
    {
      id: 7,
      Header: "Notes",
      accessor: "note",
      Cell: ({ row: { original }}: any) => (
        <div onClick={() => handleCellClick(original)} className="cursor-pointer">
          {`${original?.info?.note || "Not available"}`}
        </div>
      ),
      isChecked: true
    },
    {
      id: 8,
      Header: "",
      accessor: "actions",
      Cell: ({ row: { original } }: any) => {
        return (
          <Button
            className="cursor-pointer"
            onClick={() => {
              setIsUpdateModalOpen(true);
              setUpdateModalData(original);
            }}
          >
            <Icon iconType={IconType.OPTIONS_ICON} />
          </Button>
        );
      },
      isChecked: true
    }
  ];

  const [cardsOrder, setCardsOrder] = useState(columns);

  // const updateAppointment = async (original: any) => {
  //   try {
  //     await confirm({
  //       title: ``,
  //       classes: {
  //         modal: "md:min-w-[750px] md-max-w-full lg:max-w-[1300px]  !w-auto",
  //       },
  //       showCloseButton: true,
  //       customComponent: (props) => {
  //         return (
  //           <UpdateAppointmentProcess
  //             onCancel={props.onCancel}
  //             onConfirm={props.onConfirm}
  //             data={original}
  //             showStatusButton={true}
  //             showDigitalButton={false}
  //           />
  //         );
  //       },
  //     });
  //   } catch (err) {
  //     return null;
  //   }
  // };

  const createAppointment = async () => {
    try {
      await confirm({
        title: "New appointment",
        classes: {
          modal: "md:min-w-[750px] md-max-w-full lg:max-w-[900px] w-full"
        },
        showCloseButton: true,
        customComponent: (props) => {
          return (
            <CreateAppointment
              onConfirm={props.onConfirm}
              themeColors={themeColors}
            />
          );
        }
      });
    } catch (err) {
      return null;
    }
  };
  const transferAppointment = async () => {
    try {
      await confirm({
        title: "Transfer Appointments",
        classes: {
          modal: "md:min-w-[750px] md-max-w-full lg:max-w-[1000px] w-full",
          title: "ml-[35px]"
        },
        showCloseButton: true,
        showBackButton: true,
        customComponent: (props: { onConfirm: () => void }) => {
          return (
            <TransferAppointmentModal
              onConfirm={props.onConfirm}
              themeColors={themeColors}
            />
          );
        }
      });
    } catch (err) {
      return null;
    }
  };

  // const uploadNewAppointment = async () => {
  //   try {
  //     await confirm({
  //       title: "",
  //       classes: {
  //         modal: "md:min-w-[750px] md-max-w-full lg:max-w-[1000px] w-full",
  //       },
  //       showCloseButton: true,
  //       showBackButton: true,
  //       clearData: true,
  //       customComponent: (props) => {
  //         return (
  //           <UploadAppointment
  //             themeColors={themeColors}
  //             onConfirm={props.onConfirm}
  //           />
  //         );
  //       },
  //     });
  //   } catch (err) {
  //     return null;
  //   }
  // };

  return (
    <>
      <div className="flex flex-col pb-[15px] items-center justify-between md:flex-row pb-none ">
        <Headline title="Appointments" />
        <ConditionalRender
          condition={hasAdminPermission || hasMenagerPermission}
        >
          <IconButton
            onClick={transferAppointment}
            icon={<Icon color="#fff" iconType={IconType.TRANSFER_ICON} />}
          >
            Transfer
          </IconButton>
        </ConditionalRender>
      </div>
      <AppointmentCards />
      <DashboardBoxLayout className="mt-[26px] sm:w-full ">
        <div className="flex flex-col md:flex-row gap-3 items-center justify-between mb-5">
          <div className="flex-1">
            <DashboardBoxTitle
              title={`Appointment History (${
                data?.pages?.[0]?.data?.data?.length ?? []
              })`}
            />
          </div>

          <ConditionalRender
            condition={hasAdminPermission || hasMenagerPermission}
          >
            <IconButton
              onClick={() => setisUploadModalOpen(true)}
              icon={<Icon color="#fff" iconType={IconType.UPLOAD_FILE_ICON} />}
            >
              Upload Appointment
            </IconButton>
          </ConditionalRender>
          <IconButton
            icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
            onClick={createAppointment}
          >
            New Appointment
          </IconButton>
        </div>
        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder="Search"
              value={filterData?.search ?? ""}
              onChange={(e) =>
                setFilterData((prevFormData: any) => ({
                  ...prevFormData,
                  search: e.target.value
                }))
              }
              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex-none">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px] "
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={configureTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title="Configure Table"
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title="Filter"
                  >
                    <FilterAppointments
                      setFilterData={setFilterData}
                      FilterData={filterData}
                      setIsOpen={setFilterTableOpen}
                      themeColors={themeColors}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex-none">
              <ViewChangeButtonGroup
                setSelectedTab={setActiveView}
                ActiveTab={activeView}
              />
            </div>
          </div>
        </div>
        <ConditionalRender condition={activeView === "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Leads" />}
            >
              <AppointmentGridView data={data?.pages?.[0]?.data?.data ?? []} />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
        <ConditionalRender condition={activeView !== "kundenView"}>
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Appointments" />}
            >
              <AppointementManagmentList
                key={`appointment-management-list-${currentPage}`}
                data={data?.pages?.[0]?.data?.data ?? []}
                columns={cardsOrder}
              />
            </NoDataBoundary>
          </div>
        </ConditionalRender>
      </DashboardBoxLayout>
      <ConditionalRender condition={!!data && activeView !== "kundenView"}>
        <PaginationControls
          itemsPerPage={itemsPerPage}
          handleItemsPerPageChange={(value: number) =>
            handleItemsPerPageChange(value)
          }
          from={data?.pages[0]?.data?.from}
          to={data?.pages[0]?.data?.to}
          total={data?.pages[0]?.data?.total}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </ConditionalRender>
      <ConditionalRender condition={isUpdateModalOpen}>
        <UpdateAppointmentProcess
         clearData={true}
          themeColors={themeColors}
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${
              currentStep === 1
                ? "md:min-w-[750px] lg:min-w-[1000px]  xl:min-w-[1200px] 2xl:min-w-[1290px]"
                : currentStep === 2
                ? "md:min-w-[770px]  md:w-[993px] lg:w-[993px] xl:w-[993px] 2xl:min-w-[993px]"
                : currentStep === 3
                ? "md:min-w-[572px] md:w-[572px] lg:w-[572px] xl:w-[572px] 2xl:min-w-[572px]"
                : currentStep === 4
                ? "md:min-w-[751px] md:w-[751px] lg:w-[751px] xl:w-[751px] 2xl:min-w-[751px]"
                : currentStep === 5
                ? "md:min-w-[750px] md:w-[903px] lg:w-[903px] xl:w-[903px] 2xl:min-w-[903px]"
                : currentStep === 6
                ? "md:min-w-[750px] md:w-[903px] lg:w-[903px] xl:w-[903px] 2xl:min-w-[903px]"
                : currentStep === 7
                ? "md:min-w-[750px] md:w-[903px] lg:w-[903px] xl:w-[903px] 2xl:min-w-[903px]"
                : currentStep === 8
                ? "md:min-w-[676px] md:w-[676px] lg:w-[676px] xl:w-[676px] 2xl:min-w-[676px]"
                : currentStep === 9
                ? "md:min-w-[632px] md:w-[632px] lg:w-[632px] xl:w-[632px] 2xl:min-w-[632px]"
                : currentStep === 10
                ? "md:min-w-[563px] md:w-[563px] lg:w-[563px] xl:w-[563px] 2xl:min-w-[563px]"
                : currentStep === 11
                ? "md:min-w-[563px] md:w-[563px] lg:w-[563px] xl:w-[563px] 2xl:min-w-[563px]"
                : currentStep === 12
                ? "sm:w-[484px] md:w-[484px] lg:w-[484px] xl:w-[484px] 2xl:w-[484px] w-full"
                : currentStep === 13
                ? "md:min-w-[750px] lg:w-[973px]"
                : "md:min-w-[750px] md:w-[900px] lg:w-[900px] xl:w-[900px] 2xl:min-w-[900px]"
            }`}`
          }}
          data={updateModalData}
          currentStep={currentStep}
          hocCurrentStep={currentStep}
          onConfirm={() => setIsUpdateModalOpen(false)}
          hocToggleModal={() => {
            setIsUpdateModalOpen(false);
          }}
          hocisOpen={isUpdateModalOpen}
          onCancel={() => setIsUpdateModalOpen(false)}
          setHocCurrentStep={setCurrentStep}
          showStatusButton={true}
          showDigitalButton={false}
          setCurrentFormStep={setCurrentStep}
          setIsUpdateModalOpen={setIsUpdateModalOpen}
        />
      </ConditionalRender>
      <UploadAppointment
        clearData={true}
        currentStep={uploadCurrentStep}
        setCurrentStep={setUploadCurrentStep}
        hocClasses={{
          modalClassName:
            uploadCurrentStep === 1
              ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
              : uploadCurrentStep === 2
              ? "w-full md:max-w-[700px] lg:min-w-[973px] max-w-[973px]"
              : uploadCurrentStep === 4
              ? "w-full md:max-w-[700px]  lg:min-w-[762px] max-w-[762px]"
              : "w-full md:md:max-w-[700px] lg:min-w-[1008px] max-w-[1008px]",
          titleClassName: uploadCurrentStep === 1 ? "" : "ml-[35px]"
        }}
        onConfirm={() => {
          setisUploadModalOpen(false);
        }}
        themeColors={themeColors}
        hocCurrentStep={uploadCurrentStep}
        hocToggleModal={
          isUploadModalOpen
            ? () => {
                setisUploadModalOpen(false);
              }
            : () => {
                setisUploadModalOpen(true);
              }
        }
        hocisOpen={isUploadModalOpen}
        hocTitle="Upload Appointment"
        setHocCurrentStep={setUploadCurrentStep}
        hocHasChanges={false}
      />
    </>
  );
};
export default AppointmentManagementView;
