import { updatePartner } from "api/Partners";
import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useUpdatePartner = (
    options?: UseMutationOptions<any, any, any, unknown>
) => {
    const queryClient = useQueryClient();
    return useMutation(updatePartner, {
        onSuccess: () => {
            toast.success("Partner updated successfully!");
            queryClient.invalidateQueries('partners')
        },
        onError: () => {
            toast.error("Source update failed! Please try again later.");
        },
        ...options
    })
}