import { FC, useState } from "react";
import Checkbox from "components/atoms/checkbox/Checkbox";
import Form from "components/atoms/form/Form";
import Label from "components/atoms/input/input-label/InputLabel";
import { Controller, useForm } from "react-hook-form";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import IconButton from "components/atoms/button/IconButton";
import { useExportLeads } from "lib/hooks/mutations/Leads/useExportLeads";
import { useRequestLeads } from "lib/hooks/queries/Leads/useRequestLeads";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useDownloadFeedback } from "lib/hooks/queries/Leads/useDownloadFeedback";
import Button from "components/atoms/button/Button";
import Input from "components/atoms/input/Input";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";

export interface IExportLeadsProps {
  onConfirm: () => void;
}

const ExportLeads: FC<IExportLeadsProps> = ({ onConfirm }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { mutateAsync: exportLead } = useExportLeads();
  const { data } = useRequestLeads(
    "",
    "export",
    ["accept", "decline"],
    undefined,
    undefined
  );
  const { mutate: downloadFeedback } = useDownloadFeedback();

  const onSubmit = (data: any) => {
    const selectedFields = Object.keys(data).filter((key) => data[key] === key);
    const formattedData = {
      type: "export",
      name: data.name,
      data: [
        {
          fields: selectedFields,
          from: data.from,
          to: data.to,
        },
      ],
    };
    exportLead(formattedData).then(() => {
      onConfirm();
    }).catch(() => {});
  };

  const renderTabs = () => {
    switch (selectedTab) {
      case 0:
        return (
          <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label="Name Of Request"
              {...register("name",{required: "Name is required"})}
              className="mb-5"
              error={errors.name}
              classes={{errorClassName: '!bottom-[-1px]'}}
            />
            <div className="grid grid-cols-2 gap-5 mb-[35px]">
              <Controller
                control={control}
                name="from"
                rules={{ required: "From date is required" }}
                render={({ field: { onChange, value } }) => (
                  <PrimaryDatePicker
                    label="From"
                    startDate={value}
                    setStartDate={(date: string) => onChange(date)}
                    wrapperClassName="!left-[5px]"
                    error={errors.from}
                  />
                )}
              />
              <Controller
                control={control}
                name="to"
                rules={{ required: "To date is required" }}
                render={({ field: { onChange, value } }) => (
                  <PrimaryDatePicker
                    label="To"
                    startDate={value}
                    setStartDate={(date: string) => onChange(date)}
                    error={errors.to}
                  />
                )}
              />
            </div>
            <div className="relative grid grid-cols-2 gap-5">
              <Label className="absolute top-[-12px]">Fields To Export</Label>
              <Label className="flex items-center gap-2 mt-5">
                <Controller
                  control={control}
                  name="first_name"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "first_name"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "first_name" : false)
                      }
                    />
                  )}
                />
                First Name
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="last_name"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "last_name"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "last_name" : false)
                      }
                    />
                  )}
                />
                Last Name
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="birthdate"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "birthdate"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "birthdate" : false)
                      }
                    />
                  )}
                />
                Birthdate
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="language"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "language"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "language" : false)
                      }
                    />
                  )}
                />
                Language
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "phone"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "phone" : false)
                      }
                    />
                  )}
                />
                Phone
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="persons"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "persons"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "persons" : false)
                      }
                    />
                  )}
                />
                Persons
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "email"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "email" : false)
                      }
                    />
                  )}
                />
                Email
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="address"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "address"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "address" : false)
                      }
                    />
                  )}
                />
                Address
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "city"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "city" : false)
                      }
                    />
                  )}
                />
                City
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="postal_code"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "postal_code"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "postal_code" : false)
                      }
                    />
                  )}
                />
                Postal Code
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="nr"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "nr"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "nr" : false)
                      }
                    />
                  )}
                />
                Nr
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="feedback_status"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "feedback_status"}
                      onChange={(e) =>
                        field.onChange(
                          e.target.checked ? "feedback_status" : false
                        )
                      }
                    />
                  )}
                />
                Status
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="health_insurance"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "health_insurance"}
                      onChange={(e) =>
                        field.onChange(
                          e.target.checked ? "health_insurance" : false
                        )
                      }
                    />
                  )}
                />
                Health Insurance
              </Label>
              <Label className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      isChecked={field.value === "notes"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "notes" : false)
                      }
                    />
                  )}
                />
                Notes
              </Label>
            </div>
            <div className="flex justify-end gap-4 mt-[38px]">
              <IconButton
                secondary={true}
                type="button"
                onClick={() =>
                  reset({
                    name: "",
                    first_name: false,
                    last_name: false,
                    birthdate: false,
                    language: false,
                    phone: false,
                    persons: false,
                    email: false,
                    address: false,
                    city: false,
                    postal_code: false,
                    nr: false,
                    feedback_status: false,
                    health_insurance: false,
                    notes: false,
                    from: "",
                    to: "",
                  })
                }
                className={`w-full max-w-[150px]`}
              >
                Reset
              </IconButton>
              <IconButton
                type="submit"
                className={`w-full max-w-[150px] text-white`}
              >
                Submit
              </IconButton>
            </div>
          </Form>
        );
      case 1:
        return (
          <div className="mt-5 flex flex-col gap-5">
            <NoDataBoundary
              condition={data?.pages[0].data.data.length > 0}
              fallback={<EmptyEntity name="Request" />}
            >
              {data?.pages[0].data.data?.map((item: any) => {
                return (
                  <div className="flex items-center justify-between py-5 px-3 border rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <Icon iconType={IconType.UPLOADED_CSV_ICON} />
                      <div className="flex flex-col gap-1">
                        <h1 className="font-inter-medium text-[14px]">
                          {item?.name}
                        </h1>
                        <p className="font-inter-medium text-[#282D46] text-[14px]">
                          From: {item.data[0].from} - To {item.data[0].to}
                        </p>
                      </div>
                    </div>
                    {item.status === "accept" ? (
                      <Button
                        onClick={() =>
                          downloadFeedback({
                            request_id: item.id,
                          })
                        }
                        className={`text-right font-inter-bold  w-[100px] text-[--theme] text-[14px]`}
                      >
                        Download
                      </Button>
                    ) : (
                      <p className="text-right font-inter-bold w-[100px] text-red-600 text-[14px]">
                        Decline
                      </p>
                    )}
                  </div>
                );
              })}
            </NoDataBoundary>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="bg-white rounded-[8px] ">
        <h1 className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-[5px] text-[#282D46] font-inter-semibold text-[22px]">
          Export Customers
        </h1>
        <Button
          onClick={() => setSelectedTab(0)}
          className={`text-[--theme] w-[50%] p-2 font-inter-medium min-w-[138.34px] cursor-pointer ${
            selectedTab === 0
              ? `bg-transparent border-[--theme] border-b-[3px]`
              : "bg-transparent !text-primaryTextColor border-b-[3px] border-b-[#F2F4F7]"
          }`}
        >
          New Request
        </Button>
        <Button
          onClick={() => setSelectedTab(1)}
          className={`text-[--theme] w-[50%] p-2 font-inter-medium min-w-[138.34px] cursor-pointer ${
            selectedTab === 1
              ? `bg-transparent border-[--theme] border-b-[3px]`
              : "bg-transparent !text-primaryTextColor border-b-[3px] border-b-[#F2F4F7]"
          }`}
        >
          Requests
        </Button>
      </div>
      {renderTabs()}
    </>
  );
};

export default ExportLeads;
