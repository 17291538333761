import { getDataById } from "api/Pendings";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

export const usePending = (params?: any) => {
  return useQuery<any, any>(
    ["customerPending", params],
    () => getDataById(params),
    {
      enabled: !!params,
      onError: () => {
        // toast.error(`Something went wrong`);
      },
    }
  );
};