import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";

export interface ICustomersCardProps {
  label: string;
  varname: string;
  nachname: string;
  geburtstag: string;
  telefon: string;
}

const CustomersCard: FC<ICustomersCardProps> = ({
  geburtstag,
  label,
  nachname,
  telefon,
  varname
}) => {
  return (
    <div className="w-full border-[1px] border-[#A2A1A8] border-opacity-[20%] p-5 rounded-[20px]">
      <div className="flex items-center justify-between border-b-[#F2F4F7] border-b-[1px] pb-3">
        <h1 className="text-primaryTextColor font-inter-medium">
          {label}
        </h1>
        <Icon iconType={IconType.EDIT_ICON} color="#000" />
      </div>
      <div className="mt-[21px] flex flex-col gap-[12px]">
        <h1 className="text-[#6C737F] text-[14px] font-inter-regular">
          Varname: <span className="text-primaryTextColor">{varname}</span>
        </h1>
        <h1 className="text-[#6C737F] text-[14px] font-inter-regular">
          Nachname: <span className="text-primaryTextColor">{nachname}</span>
        </h1>
        <h1 className="text-[#6C737F] text-[14px] font-inter-regular">
          Geburtstag <span className="text-primaryTextColor">{geburtstag}</span>
        </h1>
        <h1 className="text-[#6C737F] text-[14px] font-inter-regular">
          Telefon <span className="text-primaryTextColor">{telefon}</span>
        </h1>
      </div>
    </div>
  );
};

export default CustomersCard;
