import Card from "components/molecules/card/Card";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import { CardType } from "lib/helpers/constants/cardTypes";
import { FC, useState } from "react";
import TypeBoard from "components/molecules/type-board/TypeBoard";
import CreateAppointment from "../create-appointment/CreateAppointment";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import UpdateAppointmentProcess from "../update-appointment/UpdateAppointmentProcess";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";

export interface IAppointmentGridViewProps<T> {
  data: T;
}

const AppointmentGridView: FC<IAppointmentGridViewProps<any>> = ({ data }) => {
  const { confirm } = useModalContext();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [updateModalData, setUpdateModalData] = useState();
  const { themeColors } = useThemeContext();

  const createAppointment = async () => {
    try {
      await confirm({
        title: "New appointment",
        classes: {
          modal: "md:min-w-[750px] md-max-w-full lg:max-w-[900px] w-full",
        },
        showCloseButton: true,
        customComponent: (props) => {
          return <CreateAppointment onConfirm={props.onConfirm} themeColors={themeColors}/>;
        },
      });
    } catch (err) {
      return null;
    }
  };

  const renderLeadCards = (
    status: string,
    borderColor: string,
    bgColor: string,
    title: string
  ) => (
    <div className="flex-1">
      <TypeBoard
        openCreateModal={createAppointment}
        borderColor={borderColor}
        bgColor={bgColor}
        data={data
          ?.filter((item: any) => item.feedback_status === status)
          .map((item: any) => (
            <div
              onClick={() => {
                setUpdateModalData(item);
                setIsUpdateModalOpen(true);
              }}
              className="cursor-pointer"
              key={`card-key-${item.id}`}
            >
              <Card<IInfoCardProps>
                cardType={CardType.LEAD_CARD}
                {...(item?.info ?? [])}
                {...(item ?? [])}
              />
            </div>
          ))}
        title={title}
      />
    </div>
  );

  return (
    <>
      <div className="overflow-x-auto md:w-[calc(98vw-_360px)] horizontalOverflowScrollStyle">
        <div className="flex gap-5 flex-row flex-nowrap">
          {renderLeadCards("Open", "#DE92EB", "#DE92EB08", "Open")}
          {renderLeadCards("Pending", "#EFBE12", "#EFBE1208", "Pending")}
          {renderLeadCards("Follow Up", "#70DDE4", "#70DDE408", "Follow Up")}
          {renderLeadCards("Lost", "#CC7272", "#CC727208", "Lost")}
          {renderLeadCards("Won", "#2DE28B", "#2DE28B08", "Won")}
        </div>
      </div>
      <ConditionalRender condition={isUpdateModalOpen}>
        <UpdateAppointmentProcess
          themeColors={themeColors}
          hocClasses={{
            modalClassName: `${`sm:max-w-[570px] sm:w-full w-full ${
              currentStep === 1
                ? "md:min-w-[750px] lg:min-w-[1000px]  xl:min-w-[1200px] 2xl:min-w-[1290px]"
                : currentStep === 2
                ? "md:min-w-[770px]  md:w-[993px] lg:w-[993px] xl:w-[993px] 2xl:min-w-[993px]"
                : currentStep === 3
                ? "md:min-w-[572px] md:w-[572px] lg:w-[572px] xl:w-[572px] 2xl:min-w-[572px]"
                : currentStep === 4
                ? "md:min-w-[751px] md:w-[751px] lg:w-[751px] xl:w-[751px] 2xl:min-w-[751px]"
                : currentStep === 5
                ? "md:min-w-[750px] md:w-[903px] lg:w-[903px] xl:w-[903px] 2xl:min-w-[903px]"
                : currentStep === 6
                ? "md:min-w-[750px] md:w-[903px] lg:w-[903px] xl:w-[903px] 2xl:min-w-[903px]"
                : currentStep === 7
                ? "md:min-w-[750px] md:w-[903px] lg:w-[903px] xl:w-[903px] 2xl:min-w-[903px]"
                : currentStep === 8
                ? "md:min-w-[676px] md:w-[676px] lg:w-[676px] xl:w-[676px] 2xl:min-w-[676px]"
                : currentStep === 9
                ? "md:min-w-[632px] md:w-[632px] lg:w-[632px] xl:w-[632px] 2xl:min-w-[632px]"
                : currentStep === 10
                ? "md:min-w-[563px] md:w-[563px] lg:w-[563px] xl:w-[563px] 2xl:min-w-[563px]"
                : currentStep === 11
                ? "md:min-w-[563px] md:w-[563px] lg:w-[563px] xl:w-[563px] 2xl:min-w-[563px]"
                : currentStep === 12
                ? "sm:w-[484px] md:w-[484px] lg:w-[484px] xl:w-[484px] 2xl:w-[484px] w-full"
                : currentStep === 13
                ? "md:min-w-[750px] lg:w-[973px]"
                : "md:min-w-[750px] md:w-[900px] lg:w-[900px] xl:w-[900px] 2xl:min-w-[900px]"
            }`}`
          }}
          data={updateModalData}
          currentStep={currentStep}
          hocCurrentStep={currentStep}
          onConfirm={() => setIsUpdateModalOpen(false)}
          hocToggleModal={() => {
            setIsUpdateModalOpen(false);
          }}
          hocisOpen={isUpdateModalOpen}
          onCancel={() => setIsUpdateModalOpen(false)}
          setHocCurrentStep={setCurrentStep}
          showStatusButton={true}
          showDigitalButton={false}
          setCurrentFormStep={setCurrentStep}
          setIsUpdateModalOpen={setIsUpdateModalOpen}
        />
      </ConditionalRender>
    </>
  );
};

export default AppointmentGridView;
