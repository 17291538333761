import Checkbox from 'components/atoms/checkbox/Checkbox'
import Icon from 'components/atoms/icon/Icon'
import { useThemeContext } from 'lib/context/ThemeContext/ThemeContext'
import { IconType } from 'lib/helpers/constants/iconTypes'
import type { FC } from 'react'
import { memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'


const ItemTypes = {
    CARD: 'card',
}

export interface DraggableItemProps {
    id: string
    text: string
    isChecked: boolean
    moveCard: (id: string, to: number) => void
    findCard: (id: string) => { index: number }
    HandleCheckboxChange: (arg1: any) => void
}

interface Item {
    id: string
    originalIndex: number
}

export const DraggableItem: FC<DraggableItemProps> = memo(function Card({
    id,
    text,
    isChecked,
    moveCard,
    findCard,
    HandleCheckboxChange
}) {
    const originalIndex = findCard(id).index
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.CARD,
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item
                const didDrop = monitor.didDrop()
                if (!didDrop) {
                    moveCard(droppedId, originalIndex)
                }
            },
        }),
        [id, originalIndex, moveCard],
    )

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.CARD,
            hover({ id: draggedId }: Item) {
                if (draggedId !== id) {
                    const { index: overIndex } = findCard(id)
                    moveCard(draggedId, overIndex)
                }
            },
        }),
        [findCard, moveCard],
    )

    const opacity = isDragging ? 0.5 : 1




    return (
        <div ref={(node) => drag(drop(node))} style={{ opacity }} className='flex flex-row items-center gap-4 select-none'>

            <Icon iconType={IconType.DRAG_HANDLE_DOTS_ICON} className='cursor-pointer' />
            

            <Checkbox
                isChecked={isChecked}
                id={id}
                setIsChecked={HandleCheckboxChange}
            />
            <span className='capitalize text-sm font-inter-medium text-primaryTextColor'>{text}</span>
        </div>
    )
})
