import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import FileDropZone from "components/molecules/file-dropzone/FileDropZone";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import UploadedFile from "components/molecules/uploaded-file/UploadedFile";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useDeleteAppointmentDocument } from "lib/hooks/mutations/Appointments/useDeleteAppointmentDocument";
import { useUploadAppointmentFile } from "lib/hooks/mutations/Appointments/useUploadAppointmentFile";
import { useState } from "react";
import { useForm } from "react-hook-form";

export interface IUploadLeadFileProps<T> {
  onConfirm: () => void;
  appointmentData: T;
  isLoadingDocument: boolean;
  data: T;
  themeColors: {
    primary: string;
  };
}

const UploadAppointmentFile = ({
  onConfirm,
  appointmentData,
  data,
  isLoadingDocument,
  themeColors,
}: IUploadLeadFileProps<any>) => {
  const { control, reset } = useForm();
  const { mutateAsync: uploadLeadFiles, isLoading } = useUploadAppointmentFile(
    data.id ?? ""
  );

  const [file, setFile] = useState<File | any>();
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const handleFileUpload = (e: any) => {
    e.preventDefault();
    const formData = new FormData();

    if (!file) {
      return onConfirm();
    }

    formData.append("appointment_id", data.id);
    formData.append("document", file[0]);

    uploadLeadFiles(formData).then(() => {
      setFile(undefined);
      // if (res) {
      //   onConfirm();
      // }
    });
  };

  const { mutateAsync: deleteAppointmentDocument } =
    useDeleteAppointmentDocument();

  const [resetCount, setresetCount] = useState<number>(0);

  const deleteDocument = (document_id: string) => {
    const formData = { document_id, appointment_id: data.id };

    setIsDeleteLoading(true);
    deleteAppointmentDocument(formData)
      .then(() => {
        setIsDeleteLoading(false);
        // onConfirm();
      })
      .catch(() => {});
  };

  if (isLoadingDocument) {
    return <LoadingScreen />;
  }

  return (
    <Form
      className="flex flex-col gap-5 mb-0 w-full"
      onSubmit={handleFileUpload}
    >
      <InputsFolder title="Upload new file">
        <FileDropZone
          key={`file-dropzone-${resetCount}`}
          file={file}
          setFile={setFile}
          name="files"
          label=""
          control={control}
          classes={{ label: "bg-white" }}
          themesColor={themeColors}
        />
      </InputsFolder>

      <ConditionalRender condition={appointmentData?.data?.length > 0}>
        {isDeleteLoading ? (
          <LoadingScreen />
        ) : (
          <InputsFolder title={`Files (${appointmentData?.data?.length})`}>
            <div className="flex flex-col gap-4">
              {appointmentData?.data?.map?.((item: any) => (
                <UploadedFile
                  key={item.id}
                  icon={
                    item.name.split(".")[1] === "pdf"
                      ? IconType.FILE_PDF_ICON
                      : item.name.split(".")[1] === "csv" ||
                        item.name.split(".")[1] === "xlsx" ||
                        item.name.split(".")[1] === "xls"
                      ? IconType.UPLOADED_CSV_ICON
                      : IconType.FILE_JPG_ICON
                  }
                  title={item.name}
                  size=""
                  linkName="appointment"
                  updatedate={item.created_at.substring(0, 10)}
                  link={item.file}
                  deleteIcon={
                    <IconButton
                      onClick={() => {
                        reset();
                        deleteDocument(item.id);
                        setresetCount((prevCount) => prevCount + 1);
                      }}
                      type="button"
                      className="w-full bg-transparent border-none p-0"
                    >
                      <Icon iconType={IconType.DELETE_ICON} color="#979797" />
                    </IconButton>
                  }
                />
              ))}
            </div>
          </InputsFolder>
        )}
      </ConditionalRender>

      <div className="flex justify-end pt-5">
        <IconButton disabled={isLoading ? true : false}>
          {isLoading ? "Updating..." : "Save changes"}
        </IconButton>
      </div>
    </Form>
  );
};

export default UploadAppointmentFile;
