import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import ErrorMessage from "components/atoms/error-message/ErrorMessage";
import ErrorState from "components/atoms/error-state/ErrorState";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import RadioButton from "components/atoms/radio-button/RadioButton";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useCreateNewAddress } from "lib/hooks/mutations/Appointments/useCreateNewAddress";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";

export interface IAddAppointmentAddressProps<T> {
  onConfirm?: () => void;
  onCancel?: () => void;
  setCurrentFormStep?: (step: number) => void;
  setIsUpdateModalOpen: (value: boolean) => void;
  data: T;
  onAddressAdded: any;
}

const AddAppointmentAddress: FC<IAddAppointmentAddressProps<any>> = ({
  onConfirm,
  onCancel,
  setCurrentFormStep,
  setIsUpdateModalOpen,
  data,
  onAddressAdded,
}) => {
  const {
    mutateAsync: createNewAddress,
    isLoading,
    error,
  } = useCreateNewAddress();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm();

  const [selectedType, setSelectedType] = useState<
    "Home" | "Appointment" | null
  >(null);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const handleRadioChange = (type: "Home" | "Appointment") => {
    setSelectedType(type);
    setErrorMessage(false);
  };

  const [MapAddress, setMapAddress] = useState({
    zip_code: "",
    address: "",
    nr: "",
    city: "",
  });

  const onSubmit = (formData: any) => {
    if (!selectedType) {
      setErrorMessage(true);
      return;
    }

    const type = selectedType;
    createNewAddress({
      ...formData,
      type: type,
      appointment_id: data?.id,
    })
      .then((newAddress) => {
        setCurrentFormStep && setCurrentFormStep(1);
        reset();
        onAddressAdded(newAddress);
      })
      .catch(() => {});
  };

  return (
    <Form className="mb-0 flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <Button
        type="button"
        className="absolute top-[26px] sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
      >
        Add New Address{" "}
      </Button>
      <div className="grid grid-cols-2 relative gap-[20px] mb-[20px] mt-[30px]">
        <RadioButton
          label="Home"
          isChecked={selectedType === "Home"}
          onChange={() => handleRadioChange("Home")}
          labelClassName="h-[44px] items-center flex"
          iconWrapperClassName="!top-[21px] !right-[6px]"
          className="right-[8px]"
        />
        <RadioButton
          label="Appointment"
          isChecked={selectedType === "Appointment"}
          onChange={() => handleRadioChange("Appointment")}
          labelClassName="h-[44px] items-center flex"
          iconWrapperClassName="!top-[21px] !right-[6px]"
          className="right-[8px]"
        />
        {errorMessage && (
          <pre
            className={`font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]`}
          >
            Field is required*
          </pre>
        )}
      </div>
      <div className="grid grid-cols-2 gap-[20px] ">
        <Input
          placeholder="Enter street"
          label="Street"
          classes={{ icon: "!bg-white right-[8px]" }}
          icon={IconType.LOCATION_ICON}
          {...register("address", { required: "Location field is required" })}
          error={errors?.address}
          onChange={(e) => {
            setMapAddress({ ...MapAddress, address: e.target.value });
          }}
          className="h-[44px]"
        />
        <Input
          placeholder="Enter Nr"
          label="No"
          {...register("nr", { required: "Nr field is required" })}
          error={errors?.nr}
          onChange={(e) => {
            setMapAddress({ ...MapAddress, nr: e.target.value });
          }}
          className="h-[44px]"
        />
        <Input
          placeholder="ZIP"
          label="PLZ"
          {...register("zip_code", {
            required: "PostalCode field is required",
          })}
          error={errors?.zip_code}
          onChange={(e) => {
            setMapAddress({ ...MapAddress, zip_code: e.target.value });
          }}
          className="h-[44px]"
        />
        <Input
          placeholder="City"
          label="City"
          {...register("city", { required: "City field is required" })}
          error={errors?.city}
          onChange={(e) => {
            setMapAddress({ ...MapAddress, city: e.target.value });
          }}
          className="h-[44px]"
        />
      </div>
      {selectedType ? (
        <iframe
          className="border-inputborder border rounded-[8px] mt-[34px]"
          title="map"
          src={`https://maps.google.com/maps?q='+${MapAddress.zip_code} ${MapAddress.address} ${MapAddress.city}  ${MapAddress.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
          style={{ width: "100%", height: "100%" }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      ) : null}
      <div className="flex gap-5 justify-end mt-[42px]">
        <div>
          <IconButton
            secondary={true}
            type="button"
            onClick={() => setCurrentFormStep && setCurrentFormStep(1)}
          >
            Cancel
          </IconButton>
        </div>
        <div>
          <IconButton
            icon={<Icon iconType={IconType.INCRASE_ICON} color="white" />}
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? "Creating..." : "Create"}
          </IconButton>
        </div>
      </div>
      <ErrorState error={error}>
        Something went wrong please try again!
      </ErrorState>
    </Form>
  );
};

export default AddAppointmentAddress;
