import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import { useEffect, useState } from "react";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { PermissionsTypes as P } from "lib/helpers/constants/permissions";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import { useAllSources } from "lib/hooks/queries/Source/useAllSources";
import Button from "components/atoms/button/Button";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";

export interface IStep1Props {
  setStep: (args: number) => void;
  handleFormValuesChange: (step: number, values: any) => void;
  FormValues: Array<string>;
  themeColors: {
    primary: string;
  };
}

const Step1: FC<IStep1Props> = ({
  setStep,
  handleFormValuesChange,
  FormValues,
  themeColors
}) => {
  const { mutateAsync: getUsersByPermission } = useUsersByPermission();
  const sources = useAllSources();
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control
  } = useForm();
  const hasSalesmanPermission = usePermissions([P["Leads Salesman"]]);
  const hasAdminOrManagerPermissions = usePermissions([
    P["Leads Admin"],
    P["Leads Menager"]
  ]);

  const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);
  const { user } = useAuthContext();

  useEffect(() => {
    if (hasSalesmanPermission && !hasAdminOrManagerPermissions) {
      setFetchedUsers([user]);
    } else {
      getUsersByPermission({
        permissions: [P["Leads Salesman"]]
      })
        .then((res) => {
          setFetchedUsers(res.users);
        })
        .catch(() => {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const onSubmit = (data: Record<string, string[]>) => {
    setStep(2);
    handleFormValuesChange(1, data);  
    };

  const options = fetchedUsers?.map?.((user: any) => ({
    value: user?.id,
    label: user?.name
  }));

  return (
    <Form
      className="mb-0 flex flex-col gap-[13px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <div>
          <Label>Campaign Name*</Label>
        </div>
        <div>
          <Input
            {...register("name", { required: "Name is required" })}
            error={errors.name}
            placeholder="Enter campaign name"
            defaultValue={(FormValues as any).name}
            classes={{errorClassName: '!bottom-[-14px]'}}
          />{" "}
        </div>
      </div>
      <div className="relative">
        <div>
          <Label>Assigned to*</Label>
        </div>
        <div>
          <Controller
            name="assigned_to"
            control={control}
            rules={{ required: "Assigned to is required" }}
            render={({ field }) => (
              <Creatable<any, false>
                {...field}
                placeholder="
                Choose a user
                "
                options={options}
                value={
                  options.find((option) => option.value === field.value) || ""
                }
                isValidNewOption={() => false}
                defaultValue={options[0]}
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            )}
          />
          {errors.assigned_to?.message && (
            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-14px] 2xl:bottom-[-14px]  left-[2px]">
              <>{errors.assigned_to?.message}*</>
            </div>
          )}
          {/* <CustomSelect
            customOptions={
              (
                fetchedUsers as unknown as Array<{ name: string; id: string }>
              )?.map?.((user) => ({ label: user?.name, value: user?.id })) ?? []
            }
            {...register("assigned_to", {
              required: "Assigned to is required",
            })}
            error={errors.assigned_to}
            defaultValue={(FormValues as any).assigned_to}
          /> */}
        </div>
        <div className="flex flex-col gap-[15px] 2xl:gap-[24px]"></div>
      </div>
      <div>
        <div className="relative">
          <Label text="Source" />
          <Controller
            name="source_id"
            control={control}
            render={({ field }) => (
              <Creatable<any, false>
                {...field}
                placeholder="Choose source"
                options={sources?.data?.sources?.map?.((source: any) => ({
                  label: source.name ?? "",
                  value: source.id
                }))}
                value={
                  field?.value
                    ? {
                        value: field?.value,
                        label:
                          (
                            sources?.data?.sources?.find?.(
                              (item: any) => item.id === field.value
                            ) as { name: string } | undefined
                          )?.name || field.value
                      }
                    : null
                }
                className="cursor-pointer font-inter-regular react-select-container"
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                isValidNewOption={() => false}
                menuPosition={"fixed"}
                menuPortalTarget={document.body}
              />
            )}
          />
        </div>
        <Button type="button" onClick={() => setStep(7)} className="text-[--theme] flex items-center gap-1 justify-end ml-auto font-inter-medium text-[13px] mt-2">
          <Icon iconType={IconType.INCRASE_ICON} color={themeColors.primary} />
          Add Source
        </Button>
      </div>
    
      <div className="flex gap-5 justify-end">
        <div>
          <IconButton
            secondary={true}
            type="button"
            onClick={() =>
              reset({
                name: "",
                assigned_to: ""
              })
            }
            className={`w-full min-w-[120px] `}
          >
            Reset
          </IconButton>
        </div>
        <div>
          <IconButton
            type="submit"
            className={`w-full min-w-[120px]  bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          >
            Continue
          </IconButton>
        </div>
      </div>
    </Form>
  );
};

export default Step1;
