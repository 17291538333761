import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC } from "react";

export interface IProps {
  onClick?: () => void;
  title?: string;
  descripiton?: string;
  themeColors: {
    primary: string;
  };
  removeCard: (id: number, source: string) => void;
  id: number;
  source: string;
}

const OverviewCard: FC<IProps> = ({
  onClick,
  title,
  descripiton,
  themeColors,
  removeCard,
  id,
  source,
}) => {
  return (
    <div className="relative">
      <Button
        type="button"
        onClick={() => removeCard(id, source)}
        className="absolute right-[15px] top-[20px] md:right-[20px] "
      >
        <Icon iconType={IconType.CLOSE_ICON} color="#0A1629" />
      </Button>
      <div
        className={`w-full min-w-[-webkit-fill-available] flex  pt-[68px] pb-[68px] flex-col gap-[15px] justify-center items-center bg-white max-w-screen-sm border border-dashed border-[--theme] rounded-2xl h-[320px] cursor-pointer`}
        // onClick={onClick}
      >
        <div
          className="w-[60px] p-5 flex justify-center items-center rounded-full"
          style={{
            backgroundColor: `${themeColors.primary}0D`,
          }}
        >
          <Icon
            iconType={IconType.INCRASE_ICON}
            color={themeColors.primary}
            className="w-[18px] h-[18px]"
          />
        </div>
        <div className="text-center  text-sm/[16px] font-inter-medium p-2 leading-[19.2px] text-primaryTextColor">
          {title ?? "No components created yet"}
        </div>
        <div className="w-full max-w-[280px] text-center text-sm/[12px] leading-[16.8px] p-2 font-inter-regular text-secondaryTextColor">
          {descripiton ??
            "Simply create your first component. Just click on the button"}
        </div>
        <IconButton
          type="button"
          icon={<Icon iconType={IconType.INCRASE_ICON} color="white" />}
        >
          Add Component
        </IconButton>
      </div>
    </div>
  );
};

export default OverviewCard;
