import React, { FC } from "react";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "../../../lib/helpers/constants/iconTypes";
import Button from "../../atoms/button/Button";
import StepIndicator from "../../molecules/step-indicator/StepIndicator";
import CustomSelect, {
  OptionsType,
} from "components/atoms/custom-select/CustomSelect";

export interface ICredentialManagementLayoutProps {
  children: React.ReactNode;
}
const CredentialManagementLayout: FC<ICredentialManagementLayoutProps> = ({
  children,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen gap-[34px] p-[20px] md:p-[44px] bg-signupbg relative md:grid-cols-[346px,auto]">
      <div>
        <div
          className={`bg-[#50B8E7] h-full rounded-xl p-[55px] max-h-[690px] 2xl:max-h-[860px] flex flex-col justify-between fixed md:w-[346px]`}
        >
          <div>
            <Icon iconType={IconType.LOGO_ICON} />
            <h1 className="text-white font-inter-bold text-[34px] mt-[34px]">
             Let’s start
            </h1>
            <StepIndicator />
          </div>
          <Button className="font-inter-bold text-primary mx-auto flex justify-center items-center gap-[8px] rounded-[14px] bg-white w-[130px] p-[10px]">
            <Icon iconType={IconType.SUPPORT_ICON} />
            Support
          </Button>
        </div>
      </div>
      <main className="bg-white w-full h-full rounded-xl relative">
        <div className="absolute right-5 top-5 gap-0 flex items-center">
          <Icon iconType={IconType.DUTCH_FLAG_ICON} />
          <CustomSelect
            optionsType={OptionsType.LANGUAGE_OPTIONS}
            placeholder=""
            className="border-none text-[#50B8E7] text-[14px] font-inter-bold !indent-0"
          />
        </div>
        {children}
      </main>
    </div>
  );
};

export default CredentialManagementLayout;
