import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from "react";
import Icon from "../../atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import DynamicDropdown from "../dynamic-dropdown/DynamicDropdown";
import { useShowCustomerFile } from "lib/hooks/queries/Coustomer/useShowCustomerFile";
import Button from "components/atoms/button/Button";

export interface IUploadedFileProps {
  title: string;
  icon: IconType;
  size: string;
  updatedate?: string;
  link?: string;
  isEditable?: boolean;
  dropdown?: React.ReactElement;
  linkName?: string;
  setCurrentFormStep: any;
  currentStep: any;
  setFileLink: any;
  setHasDoucmentName: Dispatch<SetStateAction<any>>;
  deleteIcon?: React.ReactElement;
}

const UploadedCustomerFile: FC<IUploadedFileProps> = ({
  title,
  linkName,
  icon,
  size,
  updatedate,
  link,
  isEditable = true,
  dropdown,
  setCurrentFormStep,
  currentStep,
  setFileLink,
  setHasDoucmentName,
  deleteIcon
}) => {
  const { refetch } = useShowCustomerFile(link ?? "");
  const [IsDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  useEffect(() => {
    if (currentStep === 5) {
      refetch();
    }
  }, [link]);

  return (
    <div className="bg-white border borber-border px-4 lg:px-6 py-4 rounded-[8px]">
      <div className="flex flex-row gap-5 lg:gap-10 items-center flex-wrap justify-between">
        <div className="flex-none">
          {link ? (
            <Button
              className="cursor-pointer"
              onClick={() => {
                setCurrentFormStep(5);
                setFileLink(link);
                setHasDoucmentName(title ?? "");
              }}
            >
              <Icon iconType={icon} color="#50B8E7" />
            </Button>
          ) : (
            <div>
              <Icon iconType={icon} color="#50B8E7" />
            </div>
          )}
        </div>
        <div className="flex-1">
          {link ? (
            <div className="cursor-pointer">
              <div className="font-inter-medium text-sm text-primaryTextColor">
                <span>{title}</span>
              </div>
            </div>
          ) : (
            <div className="font-inter-medium text-sm text-primaryTextColor">
              <span>{title}</span>
            </div>
          )}
          <div className="text-sm font-inter-regular text-secondaryTextColor">
            <span>{size}</span>
          </div>
        </div>
        {updatedate && (
          <div className="flex-none">
            <span className="text-xs font-inter-medium text-secondaryTextColor">
              Created at {updatedate}
            </span>
          </div>
        )}

        {isEditable && deleteIcon && (
          <div className="flex-none relative">{deleteIcon}</div>
        )}

        {/* {isEditable && dropdown && (
          <div className="flex-none relative">
            <button
              type="button"
              className=""
              onClick={() => {
                setIsDropdownOpen(true);
              }}
            >
              <Icon
                iconType={IconType.TOOLTIP_THREE_DOTS}
                className="!w[5px] !h-[15px]"
                color="#6C737F"
              />
            </button>
            <ConditionalRender condition={IsDropdownOpen}>
              <DynamicDropdown
                setIsOpen={setIsDropdownOpen}
                showCloseButton
                title="Delete File"
              >
                {dropdown}
              </DynamicDropdown>
            </ConditionalRender>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default UploadedCustomerFile;
